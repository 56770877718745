import imgVoiture2 from '../../../ASSETS/tourisme/Image/Voiture.jpg'
import imgVoiture3 from '../../../ASSETS/tourisme/Image/voiture3.jpg'
import imgVoiture from '../../../ASSETS/tourisme/Image/voiture2.jpg'
import * as React from 'react';
import { GiMoneyStack } from "react-icons/gi";
import {Link} from 'react-router-dom'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BarniereLvoiture from './BarniereLvoiture';
import BackImage from './BackImage';



function Lvoiture() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);
   
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>
          
            {/* filtre */}
            <div className='grid md:grid-cols-5 xs:grid-cols-3 s:grid-cols-1 gap-6 place-items-center pt-10'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              {/* type de vehicule */}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>Type de véhicule</option>
              </select>
              {/* Marque du vehicule */}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>Marque du véhicule</option>
              </select>
              {/* Lieu ou la voiture se trouve */}
              <input type='text' name='lieu_voiture' placeholder='Lieu' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'/>
              {/* prix de la voiture */}
              <input type='text' name='lieu_voiture' placeholder='Prix' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg' />
            </div>

          {/* container 1 */}
          <div className='grid xs:grid-cols-2 md:grid-cols-4  md:gap-2 xs:gap-8 s:gap-y-8 s:grid-cols-1 py-20 place-items-center '>
            {/* filtre */}
            
            
             {/* voiture 1 */}
             <Link to="/Locationdevehicule/Voiture">
                    <div className='flex flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300  '>
                        {/* image de voiture */}
                        <img className='shadow-xl  xs:w-72  sm:w-64  xs:h-52 lg:h-60  sm:h-52' src={imgVoiture} alt='hotel'/>
                        {/* bloc blanc description de la voiture */}
                        <div className=' xs:w-72  sm:w-64  h-full pb-6 bg-white shadow-xl border-l-4 border-yellow-400 md:space-y-2 sm:space-y-0 px-4  '>
                          <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6  s:left-[146px] xs:left-[178px]'>Disponible</div>
                              <div className='flex items-center space-x-2 sm:text-xs'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                  <g id="localisation" transform="translate(-85.333)">
                                      <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                  </g>
                                  </svg>
                                  <div className=' font-thin text-base'>Bouaké</div>
                              </div>
                              

                            <div className=' font-medium text-orange-400 md:text-lg  lg:text-lg sm:text-sm'>Nom du véhcule</div>
                            <div className='grid xs:grid-cols-3 s:grid-cols-2 gap-3'>
                              {/* essence */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="essence" xmlns="http://www.w3.org/2000/svg" width="11.337" height="13.604" viewBox="0 0 11.337 13.604">
                                  <path id="Tracé_45" data-name="Tracé 45" d="M53.92,1.784,52.786.65a.283.283,0,0,0-.4.4l.933.933-.933.933a.283.283,0,0,0-.083.2v.85A1.135,1.135,0,0,0,53.436,5.1V9.92a.283.283,0,1,1-.567,0V9.353a.851.851,0,0,0-.85-.85h-.283V1.134A1.135,1.135,0,0,0,50.6,0H44.933A1.135,1.135,0,0,0,43.8,1.134v10.2a1.135,1.135,0,0,0-1.134,1.134v.85a.283.283,0,0,0,.283.283h9.636a.283.283,0,0,0,.283-.283v-.85a1.135,1.135,0,0,0-1.134-1.134V9.07h.283a.284.284,0,0,1,.283.283V9.92a.85.85,0,1,0,1.7,0V1.984a.283.283,0,0,0-.083-.2ZM50.6,4.823a.283.283,0,0,1-.283.283h-5.1a.283.283,0,0,1-.283-.283V1.417a.283.283,0,0,1,.283-.283h5.1a.283.283,0,0,1,.283.283Z" transform="translate(-42.666)" fill="#4e4e4e" />
                                </svg>
                                <p>Essence</p>
                              </div>
                              {/* place */}
                              <div className='flex space-x-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.426" height="13.426" viewBox="0 0 13.426 13.426">
                                  <g id="user" transform="translate(0 0)">
                                    <path id="Tracé_46" data-name="Tracé 46" d="M3.635,3.078A3.078,3.078,0,1,1,6.713,6.156,3.081,3.081,0,0,1,3.635,3.078ZM6.713,6.651A6.733,6.733,0,0,1,13.426,12.7a30.493,30.493,0,0,1-6.713.726A30.491,30.491,0,0,1,0,12.7,6.733,6.733,0,0,1,6.713,6.651Z" fill="#4e4e4e" fillRule="evenodd" />
                                  </g>
                                </svg>
                                <p>5</p>
                              </div>
                              {/* climatiseur */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="clim" xmlns="http://www.w3.org/2000/svg" width="19.217" height="21.439" viewBox="0 0 19.217 21.439">
                                <path id="Tracé_47" data-name="Tracé 47" d="M33.656,9.512v2.415l1.925.962,1.925-.962V9.512L35.58,8.549Zm1.283-2.076.642-.321.642.321V5.852L38.6,3.474l-.907-.907L36.222,4.038V2l1.1-1.1L36.41,0l-.83.83L34.751,0l-.907.907,1.1,1.1V4.038L33.468,2.566l-.907.907,2.378,2.378Zm-8.692-.594L27.76,6.51l1.713,1.1-2.032.445.274,1.254L31,8.587l1.372.879V8.719l.681-.34-1.36-.871-.719-3.286L29.72,4.5l.445,2.032-1.713-1.1-.331-1.513-1.253.274.251,1.146-1.146.251ZM38.788,8.719v.745l1.368-.888,3.29.7.267-1.255-2.035-.433,1.707-1.108L44.9,6.8l.267-1.255L44.02,5.3l.244-1.147-1.255-.267L42.687,5.4,40.98,6.511l.433-2.035-1.255-.267-.7,3.29-1.353.878Zm6.4,7.132L44.914,14.6l-1.513.331-1.713-1.1,2.032-.445-.274-1.254-3.286.719-1.372-.879v.747l-.681.34,1.36.871.719,3.286,1.254-.274L41,14.911l1.713,1.1.331,1.513,1.254-.274L44.043,16.1ZM36.222,14l-.642.321L34.939,14v1.583L32.56,17.965l.907.907L34.939,17.4v2.035l-1.1,1.1.907.907.83-.83.83.83.907-.907-1.1-1.1V17.4l1.471,1.471.907-.907-2.379-2.378Zm-3.85-1.283v-.745L31,12.863l-3.29-.7-.267,1.255,2.035.433-1.707,1.108-1.515-.322-.267,1.255,1.147.244L26.9,17.283l1.255.267.322-1.515,1.707-1.108-.433,2.035L31,17.229l.7-3.29,1.353-.878Z" transform="translate(-25.972)" fill="#4e4e4e"/>
                                </svg>
                                <p>Climatiseur</p>
                              </div>
                            </div>
                            <hr className='sm:pb-1 lg:pb-0'/>
                            {/* prix */}
                            <div className='flex items-center space-x-2  py-2 '>
                              <GiMoneyStack className='text-lg' />
                              <p className='sm:text-xs md:text-base'> Prix : 120.000 FCFA / jour</p>
                            </div>
                            <Link to='/Reservation_Vehicule'>
                              <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                          justify-center cursor-pointer transition ease-in-out delay-150 
                                          hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                Faire une reservation
                              </button>
                            </Link>
                        </div>
                    </div>
              </Link>
              {/* voiture 2 */}
              <Link to="/Locationdevehicule/Voiture">
                <div className='flex flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                  {/* image de voiture */}
                  <img className='shadow-xl xs:w-72  sm:w-64  xs:h-52 lg:h-60  sm:h-52' src={imgVoiture2} alt='hotel' />
                  {/* bloc blanc description de la voiture */}
                  <div className=' xs:w-72  sm:w-64  h-full pb-6   bg-white shadow-xl border-l-4 border-yellow-400 md:space-y-2 sm:space-y-0 px-4  '>
                    <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6  s:left-[146px] xs:left-[178px]'>Réservé</div>
                                <div className='flex items-center space-x-2 sm:text-xs'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                    <g id="localisation" transform="translate(-85.333)">
                                        <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                    </g>
                                    </svg>
                                    <div className=' font-thin text-base'>Bouaké</div>
                                </div>
                    <div className=' font-medium text-orange-400 md:text-lg  lg:text-lg sm:text-sm'>Nom du véhcule</div>
                    <div className='grid xs:grid-cols-3 s:grid-cols-2 gap-3'>
                              {/* essence */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="essence" xmlns="http://www.w3.org/2000/svg" width="11.337" height="13.604" viewBox="0 0 11.337 13.604">
                                  <path id="Tracé_45" data-name="Tracé 45" d="M53.92,1.784,52.786.65a.283.283,0,0,0-.4.4l.933.933-.933.933a.283.283,0,0,0-.083.2v.85A1.135,1.135,0,0,0,53.436,5.1V9.92a.283.283,0,1,1-.567,0V9.353a.851.851,0,0,0-.85-.85h-.283V1.134A1.135,1.135,0,0,0,50.6,0H44.933A1.135,1.135,0,0,0,43.8,1.134v10.2a1.135,1.135,0,0,0-1.134,1.134v.85a.283.283,0,0,0,.283.283h9.636a.283.283,0,0,0,.283-.283v-.85a1.135,1.135,0,0,0-1.134-1.134V9.07h.283a.284.284,0,0,1,.283.283V9.92a.85.85,0,1,0,1.7,0V1.984a.283.283,0,0,0-.083-.2ZM50.6,4.823a.283.283,0,0,1-.283.283h-5.1a.283.283,0,0,1-.283-.283V1.417a.283.283,0,0,1,.283-.283h5.1a.283.283,0,0,1,.283.283Z" transform="translate(-42.666)" fill="#4e4e4e" />
                                </svg>
                                <p>Essence</p>
                              </div>
                              {/* place */}
                              <div className='flex space-x-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.426" height="13.426" viewBox="0 0 13.426 13.426">
                                  <g id="user" transform="translate(0 0)">
                                    <path id="Tracé_46" data-name="Tracé 46" d="M3.635,3.078A3.078,3.078,0,1,1,6.713,6.156,3.081,3.081,0,0,1,3.635,3.078ZM6.713,6.651A6.733,6.733,0,0,1,13.426,12.7a30.493,30.493,0,0,1-6.713.726A30.491,30.491,0,0,1,0,12.7,6.733,6.733,0,0,1,6.713,6.651Z" fill="#4e4e4e" fillRule="evenodd" />
                                  </g>
                                </svg>
                                <p>5</p>
                              </div>
                              {/* climatiseur */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="clim" xmlns="http://www.w3.org/2000/svg" width="19.217" height="21.439" viewBox="0 0 19.217 21.439">
                                <path id="Tracé_47" data-name="Tracé 47" d="M33.656,9.512v2.415l1.925.962,1.925-.962V9.512L35.58,8.549Zm1.283-2.076.642-.321.642.321V5.852L38.6,3.474l-.907-.907L36.222,4.038V2l1.1-1.1L36.41,0l-.83.83L34.751,0l-.907.907,1.1,1.1V4.038L33.468,2.566l-.907.907,2.378,2.378Zm-8.692-.594L27.76,6.51l1.713,1.1-2.032.445.274,1.254L31,8.587l1.372.879V8.719l.681-.34-1.36-.871-.719-3.286L29.72,4.5l.445,2.032-1.713-1.1-.331-1.513-1.253.274.251,1.146-1.146.251ZM38.788,8.719v.745l1.368-.888,3.29.7.267-1.255-2.035-.433,1.707-1.108L44.9,6.8l.267-1.255L44.02,5.3l.244-1.147-1.255-.267L42.687,5.4,40.98,6.511l.433-2.035-1.255-.267-.7,3.29-1.353.878Zm6.4,7.132L44.914,14.6l-1.513.331-1.713-1.1,2.032-.445-.274-1.254-3.286.719-1.372-.879v.747l-.681.34,1.36.871.719,3.286,1.254-.274L41,14.911l1.713,1.1.331,1.513,1.254-.274L44.043,16.1ZM36.222,14l-.642.321L34.939,14v1.583L32.56,17.965l.907.907L34.939,17.4v2.035l-1.1,1.1.907.907.83-.83.83.83.907-.907-1.1-1.1V17.4l1.471,1.471.907-.907-2.379-2.378Zm-3.85-1.283v-.745L31,12.863l-3.29-.7-.267,1.255,2.035.433-1.707,1.108-1.515-.322-.267,1.255,1.147.244L26.9,17.283l1.255.267.322-1.515,1.707-1.108-.433,2.035L31,17.229l.7-3.29,1.353-.878Z" transform="translate(-25.972)" fill="#4e4e4e"/>
                                </svg>
                                <p>Climatiseur</p>
                              </div>
                            </div>
                    <hr className='sm:pb-1 lg:pb-0' />
                    {/* prix */}
                    <div className='flex items-center space-x-2  py-2 '>
                      <GiMoneyStack className='text-lg' />
                      <p className='sm:text-xs md:text-base'> Prix : 120.000 FCFA / jour</p>
                    </div>
                    <Link to='/Reservation_Vehicule'>
                          <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                      justify-center cursor-pointer transition ease-in-out delay-150 
                                      hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                            Faire une reservation
                          </button>
                      </Link>
                  </div>
                </div>
              </Link>
              {/* voiture 3 */}
              <Link to="/Locationdevehicule/Voiture">
                <div className='flex flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300  '>
                  {/* image de voiture */}
                  <img className='shadow-xl xs:w-72  sm:w-64  xs:h-52 lg:h-60  sm:h-52' src={imgVoiture3} alt='hotel' />
                  {/* bloc blanc description de la voiture */}
                  <div className=' xs:w-72  sm:w-64  h-full pb-6   bg-white shadow-xl border-l-4 border-yellow-400 md:space-y-2 sm:space-y-0 px-4  '>
                   <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6  s:left-[146px] xs:left-[178px]'>Disponible</div>
                  
                                <div className='flex items-center space-x-2 sm:text-xs'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                    <g id="localisation" transform="translate(-85.333)">
                                        <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                    </g>
                                    </svg>
                                    <div className=' font-thin text-base'>Bouaké</div>
                                </div>
                          
                    <div className=' font-medium text-orange-400 md:text-lg  lg:text-lg sm:text-sm'>Nom du véhcule</div>
                    <div className='grid xs:grid-cols-3 s:grid-cols-2 gap-3'>
                              {/* essence */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="essence" xmlns="http://www.w3.org/2000/svg" width="11.337" height="13.604" viewBox="0 0 11.337 13.604">
                                  <path id="Tracé_45" data-name="Tracé 45" d="M53.92,1.784,52.786.65a.283.283,0,0,0-.4.4l.933.933-.933.933a.283.283,0,0,0-.083.2v.85A1.135,1.135,0,0,0,53.436,5.1V9.92a.283.283,0,1,1-.567,0V9.353a.851.851,0,0,0-.85-.85h-.283V1.134A1.135,1.135,0,0,0,50.6,0H44.933A1.135,1.135,0,0,0,43.8,1.134v10.2a1.135,1.135,0,0,0-1.134,1.134v.85a.283.283,0,0,0,.283.283h9.636a.283.283,0,0,0,.283-.283v-.85a1.135,1.135,0,0,0-1.134-1.134V9.07h.283a.284.284,0,0,1,.283.283V9.92a.85.85,0,1,0,1.7,0V1.984a.283.283,0,0,0-.083-.2ZM50.6,4.823a.283.283,0,0,1-.283.283h-5.1a.283.283,0,0,1-.283-.283V1.417a.283.283,0,0,1,.283-.283h5.1a.283.283,0,0,1,.283.283Z" transform="translate(-42.666)" fill="#4e4e4e" />
                                </svg>
                                <p>Essence</p>
                              </div>
                              {/* place */}
                              <div className='flex space-x-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.426" height="13.426" viewBox="0 0 13.426 13.426">
                                  <g id="user" transform="translate(0 0)">
                                    <path id="Tracé_46" data-name="Tracé 46" d="M3.635,3.078A3.078,3.078,0,1,1,6.713,6.156,3.081,3.081,0,0,1,3.635,3.078ZM6.713,6.651A6.733,6.733,0,0,1,13.426,12.7a30.493,30.493,0,0,1-6.713.726A30.491,30.491,0,0,1,0,12.7,6.733,6.733,0,0,1,6.713,6.651Z" fill="#4e4e4e" fillRule="evenodd" />
                                  </g>
                                </svg>
                                <p>5</p>
                              </div>
                              {/* climatiseur */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="clim" xmlns="http://www.w3.org/2000/svg" width="19.217" height="21.439" viewBox="0 0 19.217 21.439">
                                <path id="Tracé_47" data-name="Tracé 47" d="M33.656,9.512v2.415l1.925.962,1.925-.962V9.512L35.58,8.549Zm1.283-2.076.642-.321.642.321V5.852L38.6,3.474l-.907-.907L36.222,4.038V2l1.1-1.1L36.41,0l-.83.83L34.751,0l-.907.907,1.1,1.1V4.038L33.468,2.566l-.907.907,2.378,2.378Zm-8.692-.594L27.76,6.51l1.713,1.1-2.032.445.274,1.254L31,8.587l1.372.879V8.719l.681-.34-1.36-.871-.719-3.286L29.72,4.5l.445,2.032-1.713-1.1-.331-1.513-1.253.274.251,1.146-1.146.251ZM38.788,8.719v.745l1.368-.888,3.29.7.267-1.255-2.035-.433,1.707-1.108L44.9,6.8l.267-1.255L44.02,5.3l.244-1.147-1.255-.267L42.687,5.4,40.98,6.511l.433-2.035-1.255-.267-.7,3.29-1.353.878Zm6.4,7.132L44.914,14.6l-1.513.331-1.713-1.1,2.032-.445-.274-1.254-3.286.719-1.372-.879v.747l-.681.34,1.36.871.719,3.286,1.254-.274L41,14.911l1.713,1.1.331,1.513,1.254-.274L44.043,16.1ZM36.222,14l-.642.321L34.939,14v1.583L32.56,17.965l.907.907L34.939,17.4v2.035l-1.1,1.1.907.907.83-.83.83.83.907-.907-1.1-1.1V17.4l1.471,1.471.907-.907-2.379-2.378Zm-3.85-1.283v-.745L31,12.863l-3.29-.7-.267,1.255,2.035.433-1.707,1.108-1.515-.322-.267,1.255,1.147.244L26.9,17.283l1.255.267.322-1.515,1.707-1.108-.433,2.035L31,17.229l.7-3.29,1.353-.878Z" transform="translate(-25.972)" fill="#4e4e4e"/>
                                </svg>
                                <p>Climatiseur</p>
                              </div>
                      </div>
                    <hr className='sm:pb-1 lg:pb-0' />
                    {/* prix */}
                    <div className='flex items-center space-x-2  py-2 '>
                      <GiMoneyStack className='text-lg' />
                      <p className='sm:text-xs md:text-base'> Prix : 120.000 FCFA / jour</p>
                    </div>
                    <Link to='/Reservation_Vehicule'>
                          <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                      justify-center cursor-pointer transition ease-in-out delay-150 
                                      hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                            Faire une reservation
                          </button>
                        </Link>
                  </div>
                </div>
              </Link>
               {/* voiture 4 */}
              <Link to="/Locationdevehicule/Voiture">
                <div className='flex flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                  {/* image de voiture */}
                  <img className='shadow-xl xs:w-72  sm:w-64  xs:h-52 lg:h-60  sm:h-52' src={imgVoiture3} alt='hotel' />
                  {/* bloc blanc description de la voiture */}
                  <div className=' xs:w-72  sm:w-64  h-full pb-6  bg-white shadow-xl border-l-4 border-yellow-400 md:space-y-2 sm:space-y-0 px-4  '>
                  <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6  s:left-[146px] xs:left-[178px]'>Réservé</div>
                                <div className='flex items-center space-x-2 sm:text-xs'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.52" height="40.28" viewBox="0 0 67.52 101.28">
                                    <g id="localisation" transform="translate(-85.333)">
                                        <path id="Tracé_269" data-name="Tracé 269" d="M119.093,0A33.764,33.764,0,0,0,89.386,49.8l27.861,50.388a2.11,2.11,0,0,0,3.692,0L148.81,49.787A33.766,33.766,0,0,0,119.093,0Zm0,50.64a16.88,16.88,0,1,1,16.88-16.88A16.9,16.9,0,0,1,119.093,50.64Z" fill="#233d4d"/>
                                    </g>
                                    </svg>
                                    <div className=' font-thin text-base'>Bouaké</div>
                                </div>
                          

                    <div className=' font-medium text-orange-400 md:text-lg  lg:text-lg sm:text-sm'>Nom du véhcule</div>
                    <div className='grid xs:grid-cols-3 s:grid-cols-2 gap-3'>
                              {/* essence */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="essence" xmlns="http://www.w3.org/2000/svg" width="11.337" height="13.604" viewBox="0 0 11.337 13.604">
                                  <path id="Tracé_45" data-name="Tracé 45" d="M53.92,1.784,52.786.65a.283.283,0,0,0-.4.4l.933.933-.933.933a.283.283,0,0,0-.083.2v.85A1.135,1.135,0,0,0,53.436,5.1V9.92a.283.283,0,1,1-.567,0V9.353a.851.851,0,0,0-.85-.85h-.283V1.134A1.135,1.135,0,0,0,50.6,0H44.933A1.135,1.135,0,0,0,43.8,1.134v10.2a1.135,1.135,0,0,0-1.134,1.134v.85a.283.283,0,0,0,.283.283h9.636a.283.283,0,0,0,.283-.283v-.85a1.135,1.135,0,0,0-1.134-1.134V9.07h.283a.284.284,0,0,1,.283.283V9.92a.85.85,0,1,0,1.7,0V1.984a.283.283,0,0,0-.083-.2ZM50.6,4.823a.283.283,0,0,1-.283.283h-5.1a.283.283,0,0,1-.283-.283V1.417a.283.283,0,0,1,.283-.283h5.1a.283.283,0,0,1,.283.283Z" transform="translate(-42.666)" fill="#4e4e4e" />
                                </svg>
                                <p>Essence</p>
                              </div>
                              {/* place */}
                              <div className='flex space-x-3 items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.426" height="13.426" viewBox="0 0 13.426 13.426">
                                  <g id="user" transform="translate(0 0)">
                                    <path id="Tracé_46" data-name="Tracé 46" d="M3.635,3.078A3.078,3.078,0,1,1,6.713,6.156,3.081,3.081,0,0,1,3.635,3.078ZM6.713,6.651A6.733,6.733,0,0,1,13.426,12.7a30.493,30.493,0,0,1-6.713.726A30.491,30.491,0,0,1,0,12.7,6.733,6.733,0,0,1,6.713,6.651Z" fill="#4e4e4e" fillRule="evenodd" />
                                  </g>
                                </svg>
                                <p>5</p>
                              </div>
                              {/* climatiseur */}
                              <div className='flex space-x-3 items-center'>
                                <svg id="clim" xmlns="http://www.w3.org/2000/svg" width="19.217" height="21.439" viewBox="0 0 19.217 21.439">
                                <path id="Tracé_47" data-name="Tracé 47" d="M33.656,9.512v2.415l1.925.962,1.925-.962V9.512L35.58,8.549Zm1.283-2.076.642-.321.642.321V5.852L38.6,3.474l-.907-.907L36.222,4.038V2l1.1-1.1L36.41,0l-.83.83L34.751,0l-.907.907,1.1,1.1V4.038L33.468,2.566l-.907.907,2.378,2.378Zm-8.692-.594L27.76,6.51l1.713,1.1-2.032.445.274,1.254L31,8.587l1.372.879V8.719l.681-.34-1.36-.871-.719-3.286L29.72,4.5l.445,2.032-1.713-1.1-.331-1.513-1.253.274.251,1.146-1.146.251ZM38.788,8.719v.745l1.368-.888,3.29.7.267-1.255-2.035-.433,1.707-1.108L44.9,6.8l.267-1.255L44.02,5.3l.244-1.147-1.255-.267L42.687,5.4,40.98,6.511l.433-2.035-1.255-.267-.7,3.29-1.353.878Zm6.4,7.132L44.914,14.6l-1.513.331-1.713-1.1,2.032-.445-.274-1.254-3.286.719-1.372-.879v.747l-.681.34,1.36.871.719,3.286,1.254-.274L41,14.911l1.713,1.1.331,1.513,1.254-.274L44.043,16.1ZM36.222,14l-.642.321L34.939,14v1.583L32.56,17.965l.907.907L34.939,17.4v2.035l-1.1,1.1.907.907.83-.83.83.83.907-.907-1.1-1.1V17.4l1.471,1.471.907-.907-2.379-2.378Zm-3.85-1.283v-.745L31,12.863l-3.29-.7-.267,1.255,2.035.433-1.707,1.108-1.515-.322-.267,1.255,1.147.244L26.9,17.283l1.255.267.322-1.515,1.707-1.108-.433,2.035L31,17.229l.7-3.29,1.353-.878Z" transform="translate(-25.972)" fill="#4e4e4e"/>
                                </svg>
                                <p>Climatiseur</p>
                              </div>
                      </div>
                    <hr className='sm:pb-1 lg:pb-0' />
                    {/* prix */}
                    <div className='flex items-center space-x-2  py-2 '>
                      <GiMoneyStack className='text-lg' />
                      <p className='sm:text-xs md:text-base'> Prix : 120.000 FCFA / jour</p>
                    </div>
                      <Link to='/Reservation_Vehicule'>
                        <button className='bg-[#233D4D] text-white w-[200px] relative left-6 h-8  rounded  
                                    justify-center cursor-pointer transition ease-in-out delay-150 
                                    hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                          Faire une reservation
                        </button>
                      </Link>
                    
                  </div>
                </div>
              </Link>
          </div>

          {/* Container 2 */}
          <BarniereLvoiture/>
       
          <Footer/>
        </>
      )}
    </div>
  )
}

export default Lvoiture