import paradis from '../../../ASSETS/tourisme/Image/accueilHebergement.png'
import paradis2 from '../../../ASSETS/tourisme/Image/living-room-mid-century-style-with-warm-colors-ai-generative.jpg'
import paradis3 from '../../../ASSETS/tourisme/Image/luxury-bedroom-hotel.jpg'
import paradis4 from '../../../ASSETS/tourisme/Image/3d-rendering-white-wood-living-room-near-bedroom-upstair.jpg'
import pin from '../../../ASSETS/CaImmigration/icones/pin.png'
import price from '../../../ASSETS/CaImmigration/icones/banknotes.png'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import {Link} from 'react-router-dom'
import BackImage from './BackImage'
import React, { ChangeEvent } from 'react';
import Barniere from './Barniere'


interface PhonebookProps { }

const Phonebook: React.FC<PhonebookProps> = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const myFunction = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target;
        const filter = input.value.toUpperCase();
        const div = document.getElementById("myUL");
        const divElements = div?.getElementsByTagName("div");

        // Vérifiez si divElements est défini
        if (divElements) {
            for (let i = 0; i < divElements.length; i++) {
                const currentDiv = divElements[i];

                // Vérifiez si le texte est présent dans l'élément
                const txtValue = currentDiv?.textContent || currentDiv?.innerText;

                if (txtValue) {
                    // Comparez le texte filtré
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        currentDiv.style.display = "";
                    } else {
                        currentDiv.style.display = "none";
                    }
                }
            }
        }
    };


    return (
        <div>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
           
            <BackImage/>
            {/* Container1 */}

            <div className='my-20 flex flex-col items-center'>
                {/* Barre de recherche mot clef */}
                <div className='flex items-center border-2 border-[#ebebeb] space-x-2 pl-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32.52" height="32" viewBox="0 0 42.52 42">
                    <g id="loupe" transform="translate(0.001)">
                    <path id="Tracé_134" data-name="Tracé 134" d="M27.408,22.321l13.8,12.354a4.227,4.227,0,0,1,.047,6.076A4.354,4.354,0,0,1,35.1,40.7L22.6,27.081a16.3,16.3,0,0,0,4.806-4.76ZM4.029,3.981a13.881,13.881,0,0,1,19.459,0,13.474,13.474,0,0,1,0,19.222,13.88,13.88,0,0,1-19.459,0A13.475,13.475,0,0,1,4.029,3.981ZM8.036,19.245a8.165,8.165,0,0,0,11.446,0,7.926,7.926,0,0,0,0-11.307,8.165,8.165,0,0,0-11.446,0,7.926,7.926,0,0,0,0,11.307Zm0,0" transform="translate(0)" fill="#cecece"/>
                    </g>
                    </svg>
                    <input
                        type="text"
                        id="myInput"
                        onChange={myFunction}
                        placeholder="Veuillez entrer un mot clé, lieu, prix ..."
                        title="Type in a name"
                        className='xs:w-[600px] s:w-[200px] sm:w-[300px] border-none'
                    />
                </div>

                <div id="myUL" className='grid md:grid-cols-4 xs:grid-cols-3 s:grid-cols-1 gap-7 place-items-center pl mt-20'>

                    {/* Hebergement 1 */}
                    <Link to="/hebergement/Voir_plus">
                        <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                            {/* image de la residence */}
                            <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis} alt='hotel' />
                            
                            {/* description */}
                            <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                                <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px] '>Disponible</div>
                                {/* lieu */}
                                <p className='flex items-center space-x-2  py-2'>
                                    <img src={pin} className='w-4' alt='localisation'/>
                                    <p className=' font-thin '>Abidjan, Cocody</p>
                                </p>

                                <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                                <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                                <hr className='s:pb-1 lg:pb-0' />
                                {/* Prix */}
                                <p className='flex items-center space-x-2  py-4'>
                                <img src={price} className='w-6' alt='prix'/>
                                    <p className='font-semibold text-lg sm:text-sm s:text-xs'>20000 FCFA / Nuit</p>
                                </p>

                                <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                                </Link>
                               
                            </div>
                        </div>
                    </Link>

                     {/* Hebergement 2 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis2} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                            <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px]'>Réservé</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation'/>
                                <p className=' font-thin '>Abidjan,Yopougon</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                               <img src={price} className='w-6' alt='prix'/>
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>50000 FCFA / Nuit</p>
                            </p>

                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                                </Link>
                            
                        </div>
                    </div>

                     {/* Hebergement 3 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis3} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                           <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px] '>Disponible</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation'/>
                                <p className=' font-thin '>Yamoussokro</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                               <img src={price} className='w-6' alt='prix'/>
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>40000 FCFA / Nuit</p>
                            </p>
                            
                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                        </div>
                    </div>

                     {/* Hebergement 4 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis4} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                           <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px]'>Réservé</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation'/>
                                <p className=' font-thin '>Bouaké</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                               <img src={price} className='w-6' alt='prix'/>
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>40000 FCFA / Nuit</p>
                            </p>

                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                           
                        </div>
                    </div>

                    {/* Hebergement 5 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis4} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                             <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px] '>Disponible</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation' />
                                <p className=' font-thin '>Bouaké</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                                <img src={price} className='w-6' alt='prix' />
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>40000 FCFA / Nuit</p>
                            </p>
                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                           
                        </div>
                    </div>

                    {/* Hebergement 6 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis2} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                           <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px]'>Réservé</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation' />
                                <p className=' font-thin '>Abidjan,Yopougon</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                                <img src={price} className='w-6' alt='prix' />
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>50000 FCFA / Nuit</p>
                            </p>
                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                        </div>
                    </div>

                    {/* Hebergement 7 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis3} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                          <div className='bg-[#3ec53e] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px] '>Disponible</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation' />
                                <p className=' font-thin '>Yamoussokro</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                                <img src={price} className='w-6' alt='prix' />
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>40000 FCFA / Nuit</p>
                            </p>
                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                           
                        </div>
                    </div>

                    {/* Hebergement 8 */}
                    <div className='flex w-full  flex-col cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 '>
                        {/* image de la residence */}
                        <img className='shadow-xl md:w-72  s:w-64 md:h-52  lg:h-60 xs:w-56 s:h-40 xs:h-40' src={paradis} alt='hotel' />
                        {/* description */}
                        <div className='lg:w-72 md:w-72  sm:w-64 s:w-72  xs:w-56 h-full pb-5 bg-white shadow-xl border-l-4 border-yellow-400 lg:space-y-4 s:space-y-0 px-3  '>
                          <div className='bg-[#fc5151] text-white w-[90px] h-6 text-center relative bottom-6 md:left-[182px] xs:left-[119px] s:left-[150px]'>Réservé</div>
                            {/* lieu */}
                            <p className='flex items-center space-x-2  py-2'>
                                <img src={pin} className='w-4' alt='localisation' />
                                <p className=' font-thin '>Abidjan, Cocody</p>
                            </p>

                            <p className=' font-medium text-orange-400 md:text-sm lg:text-lg sm:text-sm s:text-xs'>Résidence Vanestelle</p>
                            <p className=' font-light md:pb-3 sm:pb-2 '>Hôtel particulier en bord de mer à Jacqueville… </p>
                            <hr className='s:pb-1 lg:pb-0' />
                            {/* Prix */}
                            <p className='flex items-center space-x-2  py-4'>
                                <img src={price} className='w-6' alt='prix' />
                                <p className='font-semibold text-lg sm:text-sm s:text-xs'>20000 FCFA / Nuit</p>
                            </p>
                            <Link to="/Reservation_Hebergement" >
                                    <button className='bg-[#233D4D] text-white md:w-[200px] s:w-[170px] relative left-6 h-8  rounded  
                                        justify-center cursor-pointer transition ease-in-out delay-150 
                                        hover:-translate-y-1 hover:scale-110 duration-300 flex items-center space-x-2 '>
                                        Faire une reservation
                                    </button>
                            </Link>
                        </div>
                    </div>

                    
                   
                   
                </div>
                
            </div>

            {/* Container2 */}
            <Barniere/>
            <Footer />
                </>
            )}
        </div>
    );
};

export default Phonebook;



