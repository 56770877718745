import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaGasPump } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { RxDimensions } from "react-icons/rx";
import { WiSnowflakeCold } from "react-icons/wi";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { FaUser } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineRoomPreferences } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import { IoMdPin } from "react-icons/io";
import SidebarTop from '../../SidebarTop';


function AjoutVehicule() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules" className="opacity-60 md:text-base s:text-xs">
                                    Location de vehicule
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Ajouter
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                        {/* container formulaire */}
                        <div className='flex justify-center py-20 '>
                            <form className='flex flex-col space-y-3   '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images du vehicule</label>
                                        </div>
                                        <input type="file" accept=".jpg" multiple name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaCar className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du vehicule</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder='Ex : Toyota gris ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Marque du vehicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiSteeringWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Marque du vehicule</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder='Ex : Toyota RAV4, Toyota Rush, Citroen C1  ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Type de consommation */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaGasPump className='text-xl text-[#fe7f2d]' />
                                            <label>Type de consommation</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder='Ex : Essence, Electrique ...' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                        
                                    </div>
                                    {/* Transmission automobile */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiCarWheel className='text-xl text-[#fe7f2d]' />
                                            <label>Transmission automobile</label>
                                        </div>
                                        <select className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                                            <option>Automatique</option>
                                            <option>Manuel</option>
                                            <option>Hybride</option>
                                        </select>
                                    </div>
                                     {/* Lieu */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Lieu</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* caractéristique de la voiture */}
                                    <p className='font-semibold text-lg'>Dans votre vehicule</p>
                                    <div className='grid s:grid-cols-2 xs:grid-cols-3 gap-y-8'>
                                        {/* Climatiseur*/}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center '>
                                                <WiSnowflakeCold className='text-2xl text-[#fe7f2d]' />
                                                <label>Cliamtiseur</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='climatiseur' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='climatiseur' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Wifi  */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                                                <label>Wifi </label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='wifi' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='wifi' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Mini TV */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                                <label>Mini TV</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='TV' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='TV' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* Kilométrage du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <RxDimensions className=' text-[#fe7f2d]' />
                                            <label>Kilométrage du véhicule</label>
                                        </div>
                                        <input type='number' name='name_hotel' placeholder='Ex : 10 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Nombre de place */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <FaUser className=' text-[#fe7f2d]' />
                                            <label>Nombre de place</label>
                                        </div>
                                        <input type='number' name='name_hotel' placeholder='Ex : 5' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Puissance du véhicule */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
                                            <label>Puissance du véhicule</label>
                                        </div>
                                        <input type='tel' name='name_hotel'  className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Tarif */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif</label>
                                        </div>
                                        <input type='number' name='name_hotel' placeholder='Ex : 70000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                        <small>Le tarif doit être par nuitée</small>
                                    </div>
                                    {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description du véhicule</label>
                                        </div>
                                        <textarea className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                </div>

                                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Ajouter</button>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AjoutVehicule