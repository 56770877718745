import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { LiaCocktailSolid } from "react-icons/lia"
import { WiSnowflakeCold } from "react-icons/wi";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import { IoMdPin } from "react-icons/io";


function EditRestaurant() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants" className="opacity-60 md:text-base s:text-xs">
                                    Restaurant
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Modifier
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précdente</p>
                            </div>
                        </Link>

                        {/* container formulaire */}
                        <div className='flex  justify-center py-20 '>

                            <form className='flex flex-col space-y-3   '>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                                    {/* Ajout des images */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                                            <label> Ajouter des images du restaurant</label>
                                        </div>
                                        <input type="file" accept=".jpg" multiple name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 ' />
                                    </div>
                                    {/* Nom du restaurant */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                                            <label>Nom du restaurant</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder='Ex : Lorenzo' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>

                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* Service du restaurant */}
                                    <p className='font-semibold text-lg'>Service du restaurant</p>
                                    <div className='grid sm:grid-cols-3 s:grid-cols-2 xs:grid-cols-4 gap-y-8'>
                                        {/* piscine */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                                <label>Piscine</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='piscine' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='piscine' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Wifi  */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                                                <label>Wifi </label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='wifi' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='wifi' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* TV */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                                <label>TV</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='TV' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='TV' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Mini bar */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <LiaCocktailSolid className='text-xl text-[#fe7f2d]' />
                                                <label>Mini bar</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='dejeuner' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='dejeuner' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Terasse */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <LiaUmbrellaBeachSolid className='text-xl text-[#fe7f2d]' />
                                                <label>Terasse</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='microOnde' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='microOnde' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                        {/* Climatiseur */}
                                        <div className=' flex flex-col space-y-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <WiSnowflakeCold className='text-2xl text-[#fe7f2d]' />
                                                <label>Climatiseur</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='Climatiseur' value="oui" />
                                                <label>Oui</label>
                                            </div>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <input type='radio' name='Climatiseur' value="non" />
                                                <label>Non</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                                    {/* Lieu */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <IoMdPin className=' text-[#fe7f2d]' />
                                            <label>Lieu</label>
                                        </div>
                                        <input type='text' name='name_hotel' placeholder='Ex : Cocody, Abidjan Côte d,ivoire ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                                    </div>
                                    {/* Tarif */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                                            <label>Tarif minimun</label>
                                        </div>
                                        <input type='number' name='name_hotel' placeholder='Ex : 10000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />

                                    </div>
                                    {/* Description */}
                                    <div className='flex flex-col space-y-3'>
                                        <div className='flex flex-row items-center space-x-2'>
                                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                                            <label>Description du restaurant</label>
                                        </div>
                                        <textarea className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                                    </div>
                                </div>

                                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Ajouter</button>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditRestaurant