import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import Footer from '../../COMPONENTS/Footer/Footer';
import BackImage from './BackImage';

function ReservationVehicule() {

  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


  return (
    <div>
       {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

          <BackImage/>
       {/* formulaire de prise de rdv */}
       <div className='py-20 relative md:mt-40 s:mt-80 flex justify-center border-t-2 border-gray-200 '>
            <form action=""  className='bg-white md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center items-center'>
                <p className='xs:w-[400px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir tout les champs du formulaire afin que votre reservation soit prise en compte</p>
                <input type='hidden'   required/>
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Nom & Prénom <span className='text-red-600'>*</span></label>
                    <input name='name'  type='text' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Adresse e-mail <span className='text-red-600'>*</span></label>
                    <input name='mail' type='email' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Numero de portable <span className='text-red-600'>*</span></label>
                    <input name='numero'  type='tel' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Periode de location <span className='text-red-600'>*</span></label>
                    <input name='date'  type='date' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Véhicule <span className='text-red-600'>*</span></label>
                    <input name='vehicule'  type='text' style={{backgroundColor:"#d9d9d9"}}  className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Votre lieu d'habitation <span className='text-red-600'>*</span></label>
                    <input name='Lieu'  type='text' style={{backgroundColor:"#d9d9d9"}}  className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Voulez-vous faire le paiement ? <span className='text-red-600'>*</span></label>
                    <div className='flex space-x-10'>
                      <button className='xs:w-[270px] s:w-[100px] sm:w-[120px] h-14 bg-[#233D4D] text-white text-lg rounded'>Plus tard</button>
                      <button className='xs:w-[270px] s:w-[100px] sm:w-[120px] h-14 border-2 border-[#233D4D] text-[#233D4D] rounded text-lg'>Payer</button>
                    </div>
                   
                </div>

                

                {/* <PopopPaiement/> */}
               
                 <button style={{backgroundColor:"#FCCA46", color:"white"}} className='w-40 h-12 text-lg rounded '>Reserver</button>
            </form>
          </div>
          <Footer />
                </>
            )}
    </div>
  )
}

export default ReservationVehicule