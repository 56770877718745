import React from 'react'
import Footer from '../../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import BackImage from '../BackImage'
import Carousel from './Carousel';
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { IoMdPin } from "react-icons/io";
import img1 from '../../../../ASSETS/tourisme/Image/luxury-bedroom-hotel.jpg'
import img2 from '../../../../ASSETS/tourisme/Image/living-room-mid-century-style-with-warm-colors-ai-generative.jpg'
import img3 from '../../../../ASSETS/tourisme/Image/small-bathroom-with-modern-style-ai-generated.jpg'
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { TbFridge } from "react-icons/tb";
import { BiHotel } from "react-icons/bi";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { FaUser } from "react-icons/fa6";
import Barniere from "../Barniere"
import Commentaire from './Commentaire';
import Suggestion from "../Suggestion"
import {Link} from "react-router-dom"



function VoirPlus() {

    const slides = [img1, img2, img3];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>

                 <BackImage />

                   <div className='flex xs:flex-row s:flex-col xs:justify-center s:items-center xs:items-start xs:space-x-14 s:space-x-0'>
                      <div className="md:w-[700px] xs:w-[500px] sm:w-[300px] s:w-[260px] mt-8  ">
                          <Carousel slides={slides} />
                      </div>
                   
                      <div className='my-8 flex flex-col  space-y-6'>
                        <div className=' flex flex-col space-y-3'>
                          <p className='uppercase text-[#233d4d] font-bold xs:text-xl s:text-base'>Nom de la structure</p>
                          <div className='flex items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d] ' />
                            <p className=' text-[#fe7f2d]'>Marcory, Côte d'ivoire</p>
                          </div>
                        </div>
                        <p className='text-lg font-semibold text-[#FCCA46]'>Studio meublé</p>

                        <div className='grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-y-6 gap-x-6'>
                          {/* nombre de chambre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <BiHotel className='text-xl ' />
                                                <p>Nombre de chambre</p>
                                            </div>
                                            <p className='pl-2'>4 Chambres</p>
                                        </div>
                                        {/* Perimetre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <RxDimensions className='text-xl ' />
                                                <p>Périmètre</p>
                                            </div>
                                            <p className='pl-2'>100 km<sup>2</sup></p>
                                        </div>
                                        {/* Type de lit */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <IoBedOutline className='text-xl ' />
                                                <p>Type de lit</p>
                                            </div>
                                            <p className='pl-2'>Lit double</p>
                                        </div>
                                        {/* Capacité de la salle */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <FaUser  className='' />
                                                <p>Capacité de la salle</p>
                                            </div>
                                            <p className='pl-2'>2-5 Personnes</p>
                                        </div>
                        </div>

                        <p className='uppercase text-[#233d4d] xs:w-full s:w-[260px] sm:w-[300px] font-bold xs:text-xl s:text-base '>Service et équipement de l'appartement</p>
                        <div className='grid s:grid-cols-1 md:grid-cols-3 xs:grid-cols-2 gap-y-6 text-[#4e4e4e]'>
                          <div className="flex items-center space-x-2">
                            <MdOutlinePool className='text-xl' />
                            <p>Piscine : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MdOutlineWifi className='text-xl' />
                            <p>wifi : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <SlScreenDesktop className='text-xl' />
                            <p>TV : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <IoRestaurant className='text-xl' />
                            <p>déjeuner : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <LuMicrowave className='text-xl' />
                            <p>Micro onde : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <GiBathtub className='text-xl' />
                            <p>Baignoire : Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <FaKitchenSet className='text-xl' />
                            <p>ustensile: Oui</p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <TbFridge className='text-xl' />
                            <p>Frigo : Oui</p>
                          </div>
                        </div>
                      
                      </div>

                   </div>


                   <div className='xs:ml-12 s:mx-2 sm:mx-4 flex flex-col space-y-6 mb-14'>
                      <p className='text-[#4e4e4e] text-xl pt-4 font-semibold '>Description</p>
                        <p className='md:w-[900px] xs:w-[500px] s:w-[260px] sm:w-[300px] pt-2 text-justify'>Tellus rutrum tellus pellentesque eu tincidunt tortor. Sit amet est 
                          placerat in egestas erat imperdiet sed. Nunc lobortis mattis aliquam 
                          faucibus purus in massa tempor. Dui id ornare arcu odio ut sem nulla 
                          pharetra diam. Amet nisl purus in mollis nunc sed id semper. Hac 
                          habitasse platea dictumst quisque sagittis. Pharetra et ultrices 
                          neque ornare aenean euismod elementum nisi. Aenean sed adipiscing 
                          diam donec adipiscing tristique. Curabitur gravida arcu ac tortor 
                          dignissim convallis aenean et. Nunc mi ipsum faucibus vitae aliquet 
                          nec ullamcorper sit amet. Id diam vel quam elementum pulvinar etiam 
                          non quam. Pharetra vel turpis nunc eget lorem dolor sed viverra ipsum. 
                          Ultricies mi eget mauris pharetra et ultrices. Ut morbi tincidunt augue 
                          interdum velit euismod in pellentesque.
                        </p>

                        <Link to="/Reservation_Hebergement">
                          <button className='w-[170px] h-10 bg-[#FE7F2D] text-white text-lg rounded transition 
                          ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white
                          hover:text-[#FE7F2D] '>Réserver</button>
                        </Link>

                        <Commentaire/>
                   </div>
                      
                    <Suggestion/>

                    <Barniere/>

                <Footer />
                  </>
            )}
    </div>
  )
}

export default VoirPlus