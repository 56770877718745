import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import {Link} from 'react-router-dom'
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { BiHotel } from "react-icons/bi";
import { MdOutlineHomeWork } from "react-icons/md";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineRoomPreferences } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { TbFridge } from "react-icons/tb";
import { IoMdPin } from "react-icons/io";


function AjoutHebergement() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
        {/* sidebar left */}

        <div className='w-screen  barre_scroll h-screen'>
            {/* navbar top orange */}
             <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                <Breadcrumbs>
                      <Link to="" className="opacity-60 md:text-base s:text-xs">
                          Mes publications
                      </Link>
                      <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                          Hebergements
                      </Link>
                      <Link to="" className='md:text-base s:text-xs'>
                        Ajouter
                      </Link>
                </Breadcrumbs>
            </div>

            <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                    <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                    <p>Retour à la page précedente</p>
                </div>
            </Link>
            {/* container formulaire */}
            <div className='flex justify-center py-20  '>
            <form className='flex flex-col space-y-3 items-center  '>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-6 '>
                    {/* Ajout des images */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineAddPhotoAlternate className='text-xl text-[#fe7f2d]' />
                            <label> Ajouter des images de la chambre</label>
                        </div>
                        <input type="file" accept=".jpg" multiple name="photo" className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 '/>
                    </div>
                    {/* type d'apartement */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineHomeWork className='text-xl text-[#fe7f2d]' />
                            <label>Type d'appartement</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder='Ex : Villa, Studio, Appartement meublé' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* nombre de la chambre */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <BiHotel className='text-xl text-[#fe7f2d]' />
                            <label>Nombres de chambres</label>
                        </div>
                        <input type='number' name='name_hotel' placeholder='Ex : 2 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* perimetre chambre */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <RxDimensions className='text-xl text-[#fe7f2d]' />
                            <label>Périmetre</label>
                        </div>
                        <input type='number' name='name_hotel' placeholder='Ex : 40 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                        <small>la dimession doit être en mètre carrée</small>
                    </div>
                    {/* Type de lit */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoBedOutline className='text-xl text-[#fe7f2d]' />
                            <label>Type de lit</label>
                        </div>
                        <select className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded pl-4'>
                        <option>Lit simple</option>
                        <option>Lit double</option>
                        <option>Lit triple</option>
                        </select>
                    </div>
                     {/* Lieu */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d]' />
                            <label>Lieu</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder="Ex : Cocody, Abidjan, Côte d'ivoire " className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded' />
                    </div>
                </div>

                <div className='bg-white  s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px]  rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* caractéristique de la chambre */}
                    <p className='font-semibold text-lg'>Dans votre appartement ou chambre</p>
                    <div className='grid s:grid-cols-2 sm:grid-cols-3 xs:grid-cols-4 gap-y-8'>
                        {/* piscine */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                <label>Piscine</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='piscine' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='piscine' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Wifi  */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <MdOutlineWifi className='text-xl text-[#fe7f2d]' />
                                <label>Wifi </label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='wifi' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='wifi' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* TV */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                <label>TV</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='TV' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='TV' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Pétit déjeuner */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <IoRestaurant className='text-xl text-[#fe7f2d]' />
                                <label>Pétit déjeuner</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='dejeuner' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='dejeuner' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Micro onde */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <LuMicrowave className='text-xl text-[#fe7f2d]' />
                                <label>Micro onde</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='microOnde' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='microOnde' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Baignoire */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <GiBathtub className='text-xl text-[#fe7f2d]' />
                                <label>Baignoire</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='baignoire' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='baignoire' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* Ustensile de cuisine */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <FaKitchenSet className='text-xl text-[#fe7f2d]' />
                                <label>Ustensile</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='ustensile' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='ustensile' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                        {/* frigo */}
                        <div className=' flex flex-col space-y-2'>
                            <div className='flex flex-row items-center space-x-2'>
                                <TbFridge className='text-xl text-[#fe7f2d]' />
                                <label>Frigo</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='frigo' value="oui" />
                                <label>Oui</label>
                            </div>
                            <div className='flex flex-row items-center space-x-2'>
                                <input type='radio' name='frigo' value="non" />
                                <label>Non</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bg-white s:p-4 xs:p-10 xs:w-full s:w-[260px] sm:w-[340px] rounded-md border-b-8  shadow flex flex-col space-y-3 '>
                    {/* nombre de personne */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaUser className=' text-[#fe7f2d]' />
                            <label>Capacité de la salle</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder='Ex : 2-5 personnes ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* équipement */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <MdOutlineRoomPreferences className=' text-[#fe7f2d]' />
                            <label>Équipements</label>
                        </div>
                        <input type='text' name='name_hotel' placeholder='Ex : Bureau, Machine à café, Minibar, Fer à rapasser, Netflix, Canal+ ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                    </div>
                    {/* Tarif */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-[#fe7f2d]' />
                            <label>Tarif</label>
                        </div>
                        <input type='number' name='name_hotel' placeholder='Ex : 70000 ' className='xs:w-[500px] s:w-[230px] sm:w-[300px] h-10 rounded'/>
                        <small>Le tarif doit être par nuitée</small>
                    </div>
                    {/* Description */}
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-row items-center space-x-2'>
                            <AiOutlineEdit className='text-xl text-[#fe7f2d]' />
                            <label>Description de la chambre</label>
                        </div>
                        <textarea className='xs:w-[500px] s:w-[230px] sm:w-[300px] rounded p-2 h-[200px]'></textarea>
                    </div>
                </div>

                <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Ajouter</button>
            </form>
            </div>
        </div>
              </>
          )}
    </div>
  )
}

export default AjoutHebergement