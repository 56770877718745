import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BasicPie() {
  return (
    <div className='bg-white  shadow-md xs:w-[400px] s:w-[340px] rounded-lg'>

      <div  className=" text-[#233D4D] text-xl p-4 font-semibold mb-4">
        Statistiques de réservation du mois de Janvier
      </div>

      <PieChart className='mt-20  s:w-[300px] xs:w-[400px]'
        series={[
          {
            data: [
              { id: 0, value: 10, label: 'Residence' },
              { id: 1, value: 15, label: 'Véhicule' },
              { id: 2, value: 8, label: 'Restaurant' },
              { id: 3, value: 40, label: 'Tourisme' },
              { id: 4, value: 22, label: 'Immigration' },
            ],
          },
        ]}
        
        height={200}
      />
    </div>
  );
}