import React from 'react'
import { useState, useEffect, FormEvent } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import rdv1 from '../../../ASSETS/CaImmigration/images/rdv2_barniere.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
import Navbar from './Navbar';
import {Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify"
import RDV from "../../../API/immigration/rendezvous/Rdv"
import Paie from "../../../API/immigration/paiement/paiment"
import PopopPaiement from '../../COMPONNENTS/PopopPaiement/PopopPaiement'




function Rdv() {

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
      });
    
    const votrenom = data !== null ? data.userLastname + ' ' + data.userName : "";

    const navigate = useNavigate();

    const [nom, setNom] = useState(votrenom || "")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [tel, setTel] = useState(data !== null ? data.userTel : "" || "")
  const [usermail, setUserEmail] = useState(data !== null ? data.userMail : "" || "")
  const [sujet, setSujet] = useState("")
  const [msg, setMsg] = useState("")

//   const { FedaPay, Transaction } = require('fedapay')


    const [loading, setLoading] = useState(true);
    useEffect(() => {
        //const user = localStorage.getItem('userNumber');
        //console.log(user);
        if (!data) {
            navigate("/Connexion");
           }
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    },);

    // get all search params (including ?)
const queryString = window.location.search;
// it will look like this: ?product=shirt&color=blue&newuser&size=m

// parse the query string's paramters
const urlParams = new URLSearchParams(queryString);

// To get a parameter simply write something like the follwing
const paramValue = urlParams.get('status');

if(paramValue != null && paramValue === 'approved'){
    const paramId = urlParams.get('id');
    var templateParams = {
        nom: nom,
        usermail: usermail,
        tel: tel,
        date: date,
        time: time,
        sujet: sujet,
        msg: msg,
        user: data.userId,
        reference : paramId,
    };
    RDV.submitForm(templateParams)
    .then((res: any) => {
        if(res.data.status === 201){
            toast.success(res.data.message);
            navigate("/homeCaimmigration");
            console.log(res);
      }else{
        toast.error(res.data.message);
        console.log(res);
      }
    })
    .catch((err: any) => {
        toast.error('Erreur merci de reessayer !');
        console.log(err);
    })
    .finally(() => {
      setLoading(false);
    })
}

    function submit(e: FormEvent) {
        e.preventDefault()
  
        setLoading(true)

        /* Remplacez VOTRE_CLE_API par votre véritable clé API */
// FedaPay.setApiKey("VOTRE_CLE_API_SECRETE");

/* Précisez si vous souhaitez exécuter votre requête en mode test ou live */
// FedaPay.setEnvironment('sandbox'); //ou setEnvironment('live');

// /* Créer la transaction */
// const transaction = await Transaction.create({
//   description: 'Description',
//   amount: 2000,
//   callback_url: 'https://maplateforme.com/callback',
//   currency: {
//       iso: 'XOF'
//   },
//   customer: {
//       firstname: 'John',
//       lastname: 'Doe',
//       email: 'john.doe@example.com',
//       phone_number: {
//           number: '97808080',
//           country: 'BJ'
//       }
//   }
// });
       

                
        
  
    }

  return (
     <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          <div>
            <ToastContainer />
          </div>

          {/* back image */}
          <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${rdv1})` }}>
             <Navbar/>
                <div className='text_home flex s:justify-center md:justify-end md:pl-20 s:pl-5  s:pt-20 md:pt-0 mt-20'>
                  <div className='flex pt-20  text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>PRENDRE RENDEZ-VOUS</p>
                  </div>
              </div>

          </div>

          {/* option */}
          <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* formulaire de prise de rdv */}
          <div className='py-20 flex justify-center border-t-2 border-gray-200 '>
            <form action="" onSubmit={submit} className='bg-white md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center items-center'>
                <p className='xs:w-[400px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir tout les champs du formulaire afin que votre demande de prise de rendez-vous soit prise en compte</p>
                <input type='hidden' value={data.userId}  required/>
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Nom & Prénom <span className='text-red-600'>*</span></label>
                    <input name='name' value={nom} onChange={(e) => setNom(e.currentTarget.value)} type='text' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Adresse e-mail <span className='text-red-600'>*</span></label>
                    <input name='mail' value={usermail} onChange={(e) => setUserEmail(e.currentTarget.value)} type='email' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Numero de portable <span className='text-red-600'>*</span></label>
                    <input name='numero' value={tel} onChange={(e) => setTel(e.currentTarget.value)} type='tel' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Date du rendez-vous <span className='text-red-600'>*</span></label>
                    <input name='date' value={date} onChange={(e) => setDate(e.currentTarget.value)} type='date' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Heure du rendez-vous <span className='text-red-600'>*</span></label>
                    <input value={time} onChange={(e) => setTime(e.currentTarget.value)} type='time' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Sujet <span className='text-red-600'>*</span></label>
                    <input name='sujet' value={sujet} onChange={(e) => setSujet(e.currentTarget.value)} type='text' style={{backgroundColor:"#d9d9d9"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg'>Message <span className='text-red-600'>*</span></label>
                   <textarea value={msg} onChange={(e) => setMsg(e.currentTarget.value)} style={{backgroundColor:"#d9d9d9"}} className='border-none p-2 xs:w-[600px] s:w-[240px] sm:w-[300px] h-60' required ></textarea>
                </div>

                

                {/* <PopopPaiement/> */}
               
                 <button style={{backgroundColor:"#FCCA46", color:"white"}} className='w-40 h-12 text-lg rounded '>Envoyer</button>
            </form>
          </div>

          <Footer />
              </>
          )}
      </div>
  )
}

export default Rdv