import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import { FaRegBuilding } from "react-icons/fa";
import SidebarTop from '../SidebarTop';
import { CiMapPin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoTiktok } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { CiText } from "react-icons/ci";




function EditProfil() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                Mon compte
                </Link>
                <Link to="" className="opacity-60">
                 Profil
                </Link>
                <Link to="" className="">
                 Modifier
                </Link>
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className='flex flex-row space-x-10 justify-center xs:p-10 sm:p-6 s:p-2 '>
            
              <div className='bg-white p-8 xs:w-[700px] s:w-[260px] sm:w-[340px] rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <div className='flex flex-row space-x-8  text-lg'>
                    <Link to="/Dashboard/Profil">
                    <p className='hover:border-b-2 border-[#FCCA46] cursor-pointer '>Profile</p>
                    </Link>
                  <p className='border-b-2 border-[#FCCA46] cursor-pointer hover:border-none'>Modifier</p>
                </div>
                <hr className='my-8'/>
                <form className=' flex flex-col space-y-5'>
                  {/* Nom de l'entreprise */}
                  <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                        <FaRegBuilding className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Nom de la structure :</p>
                        </div>
                        <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'> Précisez le nom de votre structure</small>
                  </div>
                  {/* Adresse de la structure */}
                  <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Adresse de la structure :</p>
                        </div>
                        <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'>Précisez le nom de l'adresse de votre structure</small>
                  </div>
                  {/* Facebook */}
                  <div>
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                        <div className='flex flex-row items-center space-x-2 w-[200px]'>
                        <FaFacebookF className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Facebook :</p>
                        </div>
                        <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                    </div>
                    <small className=' font-thin italic'>Spécifiez le nom de votre page Facebook</small>
                  </div>
                  {/* Instagram */}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <SlSocialInstagram className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Instagram :</p>
                            </div>
                            <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className=' italic'> Spécifiez le nom de votre page Instagram</small>
                    </div>
                  {/* Tiktok*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <IoLogoTiktok className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Titok :</p>
                            </div>
                            <input type='text'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='  italic'>Spécifiez le nom de votre page Tiktok</small>
                    </div>
                   {/* Site web*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                            <TbWorldWww className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                            <p className=' font-medium '>Site web :</p>
                            </div>
                            <input type="url" className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className=' italic'> Spécifiez l'URL de votre site web</small>
                    </div>
                    {/* Telephone*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <FaPhoneAlt className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Telephone :</p>
                            </div>
                        <input type='tel'className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='   italic'>Précisez le numéro de téléphone la structure</small>
                    </div>
                    {/* mail*/}
                    <div>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <MdOutlineEmail className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Adresse mail :</p>
                            </div>
                        <input type='email' className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-8 rounded-md'  />
                        </div>
                        <small className='   italic'>Précisez l'e-mail de la la structure</small>
                    </div>
                     {/* Description*/}
                    <div>
                        <div className=' flex xs:flex-row s:flex-col items-start xs:space-x-2 s:space-y-3 xs:space-y-0'>
                            <div className='flex flex-row items-center space-x-2 w-[200px]'>
                                <CiText className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                                <p className=' font-medium '>Description :</p>
                            </div>
                            <textarea className='xs:w-[400px] s:w-[200px] sm:w-[280px] h-[200px] rounded-md p-4'></textarea>
                        </div>
                        <small className='italic'>Faite la description de votre entreprise</small>
                    </div>

                    <button className='bg-[#FCCA46] w-[150px] h-10 rounded text-white'>Mettre a jour</button>

                </form>
              </div>
            </div>
            
          </div>
        </>
      )}
    </div>
  )
}

export default EditProfil