import {Routes, Route} from 'react-router-dom'
import Home from './TOURISME/PAGES/Home/Home';
import Culture from './TOURISME/PAGES/CultureDecouverte/Culture';
import PageFortDabou from './TOURISME/PAGES/CultureDecouverte/CultureDecouverteVoirplus/FortDabou/FortDabou' 
import Lvoiture from './TOURISME/PAGES/LVoiture/Lvoiture';
import LandingPage from './LandingPage/LandingPage';
import HomeCa from './CAIMMIGRATION/PAGES/HomeCa/HomeCa';
import Visa from './CAIMMIGRATION/PAGES/VisaPermis/Visa'
import VisaEtude from './CAIMMIGRATION/PAGES/VisaPermis/VisaEtude'
import ResidenceP from './CAIMMIGRATION/PAGES/ResidenceP/ResidenceP';
import CitoyenneteCa from './CAIMMIGRATION/PAGES/CitoyenneteCa/CitoyenneteCa'
import VisaTravail from './CAIMMIGRATION/PAGES/VisaPermis/VisaTravail';
import VisaTouriste from './CAIMMIGRATION/PAGES/VisaPermis/VisaTouriste';
import VisaVisiteur from './CAIMMIGRATION/PAGES/VisaPermis/VisaVisiteur';
import VisaTransit from './CAIMMIGRATION/PAGES/VisaPermis/VisaTransit';
import VisaFamiliale from './CAIMMIGRATION/PAGES/VisaPermis/VisaFamiliale';
import BienImmobilier from './CAIMMIGRATION/PAGES/BienImmobilier/BienImmobilier';
import VoirBienImmobilier from './CAIMMIGRATION/PAGES/BienImmobilier/VoirBienImmobilier';
import PrendreRendezVous from './CAIMMIGRATION/PAGES/Formulaire/Rdv'
import AcheterUnBien from './CAIMMIGRATION/PAGES/Formulaire/AcheterUnBien';
import FaqPage from './CAIMMIGRATION/PAGES/FAQ/FaqPage';
import AboutUs from './CAIMMIGRATION/PAGES/AboutUs/About'
import Brochure from './CAIMMIGRATION/PAGES/Brochure/Brochure';
import Inscription from './CAIMMIGRATION/PAGES/Inscription/Inscription';
import Connexion from './CAIMMIGRATION/PAGES/Connexion/Connexion';
import MotDePasseOublie from './CAIMMIGRATION/PAGES/MotPasseOublie/MotPasseOublie'
import Cv from './CAIMMIGRATION/PAGES/CvLettreMotivation/Cv'
import Hebergement from './TOURISME/PAGES/Hebergement/Hebergement';
import TestEligible from './CAIMMIGRATION/PAGES/Formulaire/TestEligible'
import PageBasiliqueYakro from './TOURISME/PAGES/CultureDecouverte/CultureDecouverteVoirplus/BasilqueYakro/BasiliqueYakro'
import PageForetBanco from './TOURISME/PAGES/CultureDecouverte/CultureDecouverteVoirplus/ForetBanco/ForetBanco'
import VPvoiture from './TOURISME/PAGES/LVoiture/VoirPlusVoiture/VoirPlusVoiture'
import Restaurant from './TOURISME/PAGES/Restaurants/Restaurant';
import Loisirs from './TOURISME/PAGES/LoisirsActivite/Loisirs';
import AjoutHebergement from './TOURISME/DASHBOARD/Publications/Hebergement/AjoutHebergement';
import DashboardVoirHebergement from './TOURISME/DASHBOARD/Publications/Hebergement/VoirHebergment';
import DashboardVoirPlusHebergement from './TOURISME/DASHBOARD/Publications/Hebergement/VoirPlus';
import ModifierHebergement from './TOURISME/DASHBOARD/Publications/Hebergement/EditHebergement';
import DashboardVoirRestaurant from './TOURISME/DASHBOARD/Publications/Restaurant/VoirRestaurant';
import VoirPlusRestaurant from './TOURISME/DASHBOARD/Publications/Restaurant/VoirPlus';
import ModifierRestaurant from './TOURISME/DASHBOARD/Publications/Restaurant/EditRestaurant';
import AjoutRestaurant from './TOURISME/DASHBOARD/Publications/Restaurant/AjoutRestaurant';
import VoirLVehicule from './TOURISME/DASHBOARD/Publications/LVehicule/VoirVehicule';
import VoirPlusLVehicule from './TOURISME/DASHBOARD/Publications/LVehicule/VoirPlus';
import ModifierLVehicule from './TOURISME/DASHBOARD/Publications/LVehicule/EditVehicule';
import AjoutLVehicule from './TOURISME/DASHBOARD/Publications/LVehicule/AjouterVehicule';
import VoirCulture from './TOURISME/DASHBOARD/Publications/CultureDecouverte/VoirCulture';
import VoirPlusCulture from './TOURISME/DASHBOARD/Publications/CultureDecouverte/VoirPlus';
import ModifierCulture from './TOURISME/DASHBOARD/Publications/CultureDecouverte/EditCulture';
import AjoutCulture from './TOURISME/DASHBOARD/Publications/CultureDecouverte/AjouterCulture';
import Profil from './TOURISME/DASHBOARD/Profil/Profil';
import EditProfil from './TOURISME/DASHBOARD/Profil/EditProfil';
import ListeReservationHebergement from './TOURISME/DASHBOARD/Reservations/hebergement/Liste'
import ListeReservationVehicule from './TOURISME/DASHBOARD/Reservations/vehicule/Liste'
import ListeReservationRestaurant from './TOURISME/DASHBOARD/Reservations/Restaurant/Liste'
import VoirHebergement from './TOURISME/PAGES/Hebergement/VoirPlus/VoirPlus';
import VoirLoisirs from './TOURISME/PAGES/LoisirsActivite/VoirPlus/VoirPlus'
import VoirRestaurant from './TOURISME/PAGES/Restaurants/VoirPlus/VoirPlus'
import PageSucess from './TOURISME/COMPONENTS/PageSucess/Sucess'
import PageEchec from './TOURISME/COMPONENTS/PageEchec/Echec'
import ReservationHebergement from './TOURISME/PAGES/Formulaire/ReservationHebergement';
import ReservationVehicule from './TOURISME/PAGES/Formulaire/ReservationVehicule';
import AjoutLoisir from './TOURISME/DASHBOARD/Publications/Loisirs/AjouterLoisirs';
import DashboardVoirLoisirs from './TOURISME/DASHBOARD/Publications/Loisirs/VoirLoisirs'
import DashboardVoirPlusLoisirs from './TOURISME/DASHBOARD/Publications/Loisirs/VoirPlus'
import ModifierLoisirs from './TOURISME/DASHBOARD/Publications/Loisirs/EditLoisirs'
import ReservationRestaurant from './TOURISME/PAGES/Formulaire/ReservationRestaurant';
import Graph from './TOURISME/DASHBOARD/Graph/Dashboard'
import ListeReservationLoisir from './TOURISME/DASHBOARD/Reservations/Loisirs/Loisirs'
import ListeReservationTourisme from './TOURISME/DASHBOARD/Reservations/Tourisme/Liste'
import DashboardMessagerie from './TOURISME/DASHBOARD/Messagerie/Messagerie'
import DashboardTransation from './TOURISME/DASHBOARD/Transaction/Liste'
import DashboardTransationAjout from './TOURISME/DASHBOARD/Transaction/Ajouter'
import DashboardTransationModifier from './TOURISME/DASHBOARD/Transaction/Modifier'
import ReservationTourisme from './TOURISME/PAGES/Formulaire/ReservationTourisme';
import ReservationLoisirs from './TOURISME/PAGES/Formulaire/ReservationLoisirs';
import HomeAfimmigration from './AFIMMIGRATION/PAGES/Home/Home'
import RecommandationAfimmigration from './AFIMMIGRATION/PAGES/Recomandation/Recommandation'
import ConseilAfimmigration from './AFIMMIGRATION/PAGES/Conseil/Conseil'
import AssistanceAfimmigration from './AFIMMIGRATION/PAGES/Assistance/Assistance'
import DestinationAfimmigration from './AFIMMIGRATION/PAGES/Destination/Destination'
import VoirPlusDestinationAfimmigration from './AFIMMIGRATION/PAGES/Destination/VoirPlus'
import DashboardCreditAZ from './TOURISME/DASHBOARD/CreditAZ/CreditAZ'
import DashboardCreditAZRecharge from './TOURISME/DASHBOARD/CreditAZ/Recharge/Recharge'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<LandingPage/>} />
        <Route path='/hometourisme' element={<Home/>}/>
        <Route path='/culture' element={<Culture/>}/>
        <Route path='/culture/Fort_de_Dabou' element={<PageFortDabou />}/>
        <Route path='/culture/Basilique_de_Yamoussokro' element={<PageBasiliqueYakro />} />
        <Route path='/culture/Foret_du_Banco' element={<PageForetBanco />} />
        <Route path='/Locationdevehicule' element={<Lvoiture/>}/>
        <Route path='/Locationdevehicule/Voiture' element={<VPvoiture />} />
        <Route path='/hebergement' element={<Hebergement />} />
        <Route path='/hebergement/Voir_plus' element={<VoirHebergement />} />
        <Route path='/restaurants' element={<Restaurant />} />
        <Route path='/restaurants/Voir_Plus' element={<VoirRestaurant />} />
        <Route path='/Loisirs_Activite' element={<Loisirs />} />
        <Route path='/Loisirs_Activite/Voir_Plus' element={<VoirLoisirs />} />
        <Route path='/homeCaimmigration' element={<HomeCa/>}/>
        <Route path='/visa' element={<Visa/>} />
        <Route path='/visa/visaEtude' element={<VisaEtude />} />
        <Route path='/visa/visaTravail' element={<VisaTravail />} />
        <Route path='/visa/visaTouriste' element={<VisaTouriste />} />
        <Route path='/visa/visaVisiteur' element={<VisaVisiteur />} />
        <Route path='/visa/visaTransit' element={<VisaTransit />} />
        <Route path='/visa/visaFamiliale' element={<VisaFamiliale />} />
        <Route path='/ResidencePermante' element={<ResidenceP />} />
        <Route path='/CitoyenneteCa' element={<CitoyenneteCa />} />
        <Route path='/BienImmobilier' element={<BienImmobilier />} />
        <Route path='BienImmobilier/VoirBienImmobilier' element={<VoirBienImmobilier />} />
        <Route path='/PrendreRendezVous' element={<PrendreRendezVous />} />
        <Route path='/TestEligibilite' element={<TestEligible />} />
        <Route path='BienImmobilier/VoirBienImmobilier/AcheterUnBien' element={<AcheterUnBien />} />
        <Route path='/FAQ' element={<FaqPage />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/Brochure' element={<Brochure />} />
        <Route path='/Inscription' element={<Inscription />} />
        <Route path='/Connexion' element={<Connexion />} />
        <Route path='/MotDePasseOublie' element={<MotDePasseOublie />} />
        <Route path='/Cv' element={<Cv />} />
        <Route path='/Dashboard/Publications/Hebergement/Ajouter_des_hebergements' element={<AjoutHebergement/>} />
        <Route path='/Dashboard/Publications/Hebergement/Voir_des_hebergements' element={<DashboardVoirHebergement />} />
        <Route path='/Dashboard/Publications/Hebergement/Voir_des_hebergements/Voir_Plus' element={<DashboardVoirPlusHebergement />} />
        <Route path='/Dashboard/Publications/Hebergement/Voir_des_hebergements/Modifier' element={<ModifierHebergement />} />
        <Route path='/Dashboard/Publications/Restaurant/Voir_des_restaurants' element={<DashboardVoirRestaurant />} />
        <Route path='/Dashboard/Publications/Restaurant/Voir_des_restaurants/Voir_Plus' element={<VoirPlusRestaurant />} />
        <Route path='/Dashboard/Publications/Restaurant/Voir_des_restaurants/Modifier' element={<ModifierRestaurant />} />
        <Route path='/Dashboard/Publications/Restaurant/Ajouter_des_restaurants' element={<AjoutRestaurant />} />
        <Route path='/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules' element={<VoirLVehicule />} />
        <Route path='/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules/Voir_Plus' element={<VoirPlusLVehicule />} />
        <Route path='/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules/Modifier' element={<ModifierLVehicule />} />
        <Route path='/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules' element={<AjoutLVehicule />} />
        <Route path='/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes' element={<VoirCulture />} />
        <Route path='/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes/Voir_Plus' element={<VoirPlusCulture />} />
        <Route path='/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes/Modifier' element={<ModifierCulture />} />
        <Route path='/Dashboard/Publications/Culture_Decouverte/Ajouter_les_decouvertes' element={<AjoutCulture />} />
        <Route path='/Dashboard/Publications/Loisirs_activites/Ajouter_les_activites' element={<AjoutLoisir />} />
        <Route path='/Dashboard/Publications/Loisirs_activites/Voir_des_activites' element={<DashboardVoirLoisirs />} />
        <Route path='/Dashboard/Publications/Loisirs_activites/Voir_des_activites/Voir_Plus' element={<DashboardVoirPlusLoisirs />} />
        <Route path='/Dashboard/Publications/Loisirs_activites/Voir_des_activites/Modifier' element={<ModifierLoisirs />} />
        <Route path='/Dashboard/Profil' element={<Profil />} />
        <Route path='/Dashboard/Profil/Modifier' element={<EditProfil />} />
        <Route path='/Dashboard/Reservation/Hebergement/Liste' element={<ListeReservationHebergement />} />
        <Route path='/Dashboard/Reservation/Vehicule/Liste' element={<ListeReservationVehicule />} />
        <Route path='/Dashboard/Reservation/Restaurant/Liste' element={<ListeReservationRestaurant />} />
        <Route path='/Dashboard/Reservation/Tourisme/Liste' element={<ListeReservationTourisme />} />
        <Route path='/Dashboard/Reservation/Loisir/Liste' element={<ListeReservationLoisir />} />
        <Route path='/Dashboard/Messagerie' element={<DashboardMessagerie />} />
        <Route path='/Dashboard/Transation' element={<DashboardTransation />} />
        <Route path='/Dashboard/Transation/Ajouter' element={<DashboardTransationAjout />} />
        <Route path='/Dashboard/Transation/Modifier' element={<DashboardTransationModifier />} />
        <Route path='/Dashboard/Graph' element={<Graph/>  }/>
        <Route path='/sucess' element={<PageSucess />} />
        <Route path='/echec' element={<PageEchec />} />
        <Route path='/Reservation_Hebergement' element={<ReservationHebergement />} />
        <Route path='/Reservation_Vehicule' element={<ReservationVehicule />} />
        <Route path='/Reservation_Restaurant' element={<ReservationRestaurant />} />
        <Route path='/Reservation_Tourisme' element={<ReservationTourisme />} />
        <Route path='/Reservation_Loisirs' element={<ReservationLoisirs />} />
        <Route path='/accueil_afrique_immigration' element={<HomeAfimmigration />} />
        <Route path='/accueil_afrique_immigration/Recommandation' element={<RecommandationAfimmigration />} />
        <Route path='/accueil_afrique_immigration/Conseil' element={<ConseilAfimmigration />} />
        <Route path='/accueil_afrique_immigration/Assistance' element={<AssistanceAfimmigration />} />
        <Route path='/accueil_afrique_immigration/Destination' element={<DestinationAfimmigration />} />
        <Route path='/accueil_afrique_immigration/Destination/VoirPlus' element={<VoirPlusDestinationAfimmigration />} />
        <Route path='/Dashboard/Credit_AZ' element={<DashboardCreditAZ/>} />
        <Route path='/Dashboard/Credit_AZ/Recharger' element={<DashboardCreditAZRecharge/>} />
      </Routes>
    </div>
  );
}

export default App;
