import React from 'react'
import Header from '../../COMPONNENTS/Header/Header'
import immobilier from '../../../ASSETS/CaImmigration/images/Bien_immobilier_barniere.png'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import lieu from '../../../ASSETS/CaImmigration/icones/pin.png'
import homme from '../../../ASSETS/CaImmigration/images/homme_bien.png'
import maison from '../../../ASSETS/CaImmigration/icones/home1.png'
import etage from '../../../ASSETS/CaImmigration/icones/stairs.png'
import perimetre from '../../../ASSETS/CaImmigration/icones/selection-square.png'
import bain from '../../../ASSETS/CaImmigration/icones/shower.png'
import parking from '../../../ASSETS/CaImmigration/icones/parked-car.png'
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
   
    {
        label: 'Villa',
        imgPath:
            'https://images.unsplash.com/photo-1503174971373-b1f69850bded?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGludGVyaW9yJTIwZGVzaWdufGVufDB8fDB8fHww',
    },
    {
        label: 'Entrée',
        imgPath:
            'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8aG91c2V8ZW58MHx8MHx8fDA%3D',
    },
    {
        label: 'Salle a mangé',
        imgPath:
            'https://images.unsplash.com/photo-1537726235470-8504e3beef77?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8aW50ZXJpb3IlMjBkZXNpZ258ZW58MHx8MHx8fDA%3D0',
    },
    {
        label: 'Salon',
        imgPath:
            'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?auto=format&fit=crop&q=60&w=600&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3IlMjBkZXNpZ258ZW58MHx8MHx8fDA%3D',
    },
];

function VoirBienImmobilier() {
    let [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
            <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${immobilier})` }}>
                {/* Navbar */}
                <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                                    <div>
                                    <img className='w-6' src={home} alt='' />
                                    </div>
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p> 
                                    <ul className='z-10  '>
                                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaVisiteur'> Visa visiteur </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" ,color:"#FCCA46"}} className='flex space-x-2 items-center  s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>

              <div className='text_home flex justify-end md:pl-10 s:pl-5  s:pt-20 md:pt-0 s:mt-20 xs:mt-40 lg:mt-44'>
                  <div className='flex pt-20  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>ACHAT DE BIEN IMMOBILIER</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
         <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* Bloc */}
          <div className='py-20 bg-white'>

              {/* Caroussel */}
              <div className='flex xs:flex-row s:flex-col justify-center s:items-center xs:items-start xs:space-x-10 md:space-x-40 s:space-y-10 xs:space-y-0'>
                {/* image et description du bien immobilier */}
                <div className='flex flex-col s:items-center xs:items-start'>
                    {/* image du bien immobilier */}
                    <Box className="xs:w-[580px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px] s:mb-24 xs:mb-0" sx={{ flexGrow: 1 }}>
                        <Paper
                            square
                            elevation={0}
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            pl: 2,
                            color:'#4e4e4e',
                            bgcolor: 'background.default',
                            }}
                        >
                            <Typography >{images[activeStep].label}</Typography>
                        </Paper>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {images.map((step, index) => (
                            <div  key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                <Box className="xs:w-[600px] xs:h-full s:w-[260px] s:h-[200px] sm:w-[320px]"
                                    component="img"
                                    sx={{
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                />
                                ) : null}
                            </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Next
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                                ) : (
                                <KeyboardArrowRight />
                                )}
                            </Button>
                            }
                            backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                                ) : (
                                <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                            }
                        />
                    </Box>

                    {/* Non du bien immobilier */}
                    <p style={{color:"#FE7F2D"}} className='text-2xl font-semibold pt-4 uppercase'>Villa haut starding</p>
                    <p className='text-xl pt-4'>Maison 4 pièces ou 3½</p>
                    {/* lieu */}
                    <div className='flex items-center justify-end pt-4 pr-5 space-x-3 '>
                        <img className='w-6' src={lieu} alt='icone'/>
                        <p>Quebec, Canada</p>
                    </div>
                     {/* description */}
                    <p className='md:w-[590px] xs:w-[370px] s:w-[260px] sm:w-[300px] text-justify pt-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p className='border-b border-gray-300 pt-10'></p>
                    <p style={{color:"#FE7F2D"}} className=' text-lg font-medium pt-6'>Equipement de la maison</p>
                    <p className='md:w-[590px] xs:w-[390px]s:w-[260px] sm:w-[300px] pt-3'>Air conditionné, Double vitrage, Fenêtres coulissantes, Volets roulants électriques, Abri de voiture, Éclairage extérieur, Maison de gardien, Gardien, Portail électrique, Porte blindée, Vidéo surveillance, Vidéophone, Piscine</p>
                </div>
                {/* prix et dimenssion du bien */}
                <div>
                    <p style={{color:"#FE7F2D"}} className='text-2xl   uppercase font-semibold'>Autres informations</p>
                    <div className='pt-5 flex flex-col space-y-8'>
                        <p style={{color:"#233D4D"}} className='text-lg'><span style={{color:"#4e4e4e"}} className='text-xl font-medium'>Tarif :</span> 106.000 $CAD</p>

                        {/* categorie de maison */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={maison} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>Villa</p>
                        </div>

                         {/* etages */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={etage} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>(2) Étages </p>
                        </div>

                        {/* perimetres */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={perimetre} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>40 m³ </p>
                        </div>

                        {/* salle de bain */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={bain} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>(3) Salles d'eau </p>
                        </div>

                         {/* salle de bain */}
                        <div className='flex flex-row items-center space-x-4'>
                            <img className='w-10' src={parking} alt='home'/>
                            <p style={{color:"#4e4e4e"}} className='text-lg font-thin'>Oui </p>
                        </div>
                    </div>
                    <div className='pt-10 flex flex-col space-y-8'>
                        <Link to='AcheterUnBien'><button style={{backgroundColor:"#FE7F2D"}} className='sm:w-80 s:w-56 h-12 text-lg rounded transform active:scale-75 transition-transform text-white '>J'achète</button></Link>
                        <Link to='/PrendreRendezVous'><button className='btn_detail_bien sm:w-80 s:w-56 h-12 text-lg  rounded transform active:scale-75 transition-transform '>Demander plus de details</button></Link>
                    </div>
                </div>
              </div>

           <div style={{backgroundColor:"#4e4e4e"}} className=' pt-5 flex xs:flex-row s:flex-col-reverse md:space-x-32 items-center mt-20 '>
            <div>
                <img className='w-[450px]' src={homme} alt='homme'/>
            </div>
            <div className='space-y-8 pb-5 '>
                <p className='md:text-3xl xs:text-2xl s:text-xl font-bold text-white s:w-[260px] sm:w-[300px] xs:w-[400px] md:w-[500px]'>Obtenez votre maison plus facilement avec Mon Bon Sejour</p>
                <p className='text-justify xs:w-[360px] md:w-[450px] s:w-[260px] sm:w-[300px] text-xl text-white'>Explorez une gamme exceptionnelle de biens immobiliers sur notre site. De maisons élégantes à des appartements modernes, trouvez la propriété parfaite qui correspond à vos besoins. Avec notre équipe dévouée, chaque étape de votre parcours immobilier est facilitée.</p>
                <Link to="/PrendreRendezVous"><button className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150  border-2 border-white text-white xs:w-36 xs:h-12 s:w-28 s:h-10 s:text-sm xs:text-base font-medium hover:bg-white hover:text-orange-400 mt-10'>Prendre RDV</button></Link>
            </div>
           </div>

          </div>
        
          <Footer />
              </>
          )}
    </div>
  )
}

export default VoirBienImmobilier