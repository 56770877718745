import React from 'react'
import Footer from '../../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import BackImage from '../BackImage';
import Carousel from './Carousel';
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { IoMdPin } from "react-icons/io";
import img1 from '../../../../ASSETS/tourisme/Image/mario.png'
import img2 from '../../../../ASSETS/tourisme/Image/havana.png'
import img3 from '../../../../ASSETS/tourisme/Image/laBrise.png'
import { SlScreenDesktop } from "react-icons/sl";
import { FaFacebookF } from "react-icons/fa6";
import { SlSocialInstagram } from "react-icons/sl";
import { CiMail } from "react-icons/ci";
import { BsWhatsapp } from "react-icons/bs";
import Commentaire from './Commentaire';
import Suggestion from "../Suggestion"
import { LiaCocktailSolid } from "react-icons/lia"
import { WiSnowflakeCold } from "react-icons/wi";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import Barniere from '../Barniere';
import {Link} from 'react-router-dom'


function VoirPlus() {

    const slides = [img1, img2, img3];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>

                 <BackImage />

                  {/* title */}
                    <div className=' flex flex-col md:mt-40 s:mt-80  justify-center items-center space-y-5 my-10'>
                        <p className='text-center font-semibold xs:text-2xl s:text-xl'> RESTAURATIONS</p>
                        <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                            Explorez la liberté de voyager à votre rythme avec notre
                            service de location de véhicules. Des options variées, des
                            tarifs compétitifs et un service client dévoué font de chaque
                            trajet une aventure sans souci.
                        </p>
                    </div>

                   <div className='flex md:flex-row s:flex-col md:justify-center s:items-center md:items-start md:space-x-14 s:space-x-0'>
                      <div className="md:w-[700px] xs:w-[500px] sm:w-[300px] s:w-[260px] mt-8  ">
                          <Carousel slides={slides} />
                      </div>
                   
                      <div className='my-8 flex flex-col  space-y-6'>
                        <div className=' flex flex-col space-y-3'>
                          <p className='uppercase text-[#233d4d] font-semibold xs:text-xl s:text-base'>Nom de la structure</p>
                          <div className='flex items-center space-x-2'>
                            <IoMdPin className=' text-[#fe7f2d] ' />
                            <p className=' text-[#fe7f2d]'>Marcory, Côte d'ivoire</p>
                          </div>
                        </div>
                        <p className='uppercase text-[#233d4d] xs:w-full s:w-[260px] sm:w-[300px] font-semibold xs:text-xl s:text-base '>Service et équipement du restaurant</p>
                        <div className='grid s:grid-cols-3 md:grid-cols-4 gap-y-6 text-[#4e4e4e]'>
                        <MdOutlinePool className='text-3xl text-[#fe7f2d]' />
                        <MdOutlineWifi className='text-3xl text-[#fe7f2d]' />
                        <SlScreenDesktop className='text-3xl text-[#fe7f2d]' />
                        <LiaCocktailSolid className='text-3xl text-[#fe7f2d]' />
                        <LiaUmbrellaBeachSolid className='text-3xl text-[#fe7f2d]' />
                        <WiSnowflakeCold className='text-3xl text-[#fe7f2d]' />
                        </div>

                        <div className='flex xs:flex-row  xs:space-x-10 s:flex-col s:space-y-8 xs:space-y-0'>
                            <div className='flex flex-col space-y-3'>
                                <p className='text-[#FCCA46] font-semibold text-lg'>Horaire d'ouverture</p>
                                <p>Lundi : 11h - 22h</p>
                                <p>Mardi : 11h - 22h</p>
                                <p>Mercredi : 11h - 22h</p>
                                <p>Jeudi : 11h - 22h</p>
                                <p>vendredi: 11h - 22h</p>
                                <p>Samedi: 10h - 22h</p>
                                <p>Dimanche : 14h - 20h</p>
                            </div>
                            <div>
                             <p className='text-[#FCCA46] font-semibold'>Coordonnée</p>
                             <div className='grid grid-cols-1 gap-4 text-lg pt-4 text-[#233d4d]'>
                                <div className='flex space-x-2 items-center'>
                                  <FaFacebookF className='text-xl' />
                                  <p>: La rose noire</p>
                                </div>
                                <div className='flex space-x-2 items-center'>
                                  <SlSocialInstagram  className='text-xl' />
                                  <p>: La rose noire</p>
                                </div>
                                <div className='flex space-x-2 items-center'>
                                  <CiMail  className='text-xl' />
                                  <p>: info@rosenoire.com</p>
                                </div>
                                <div className='flex space-x-2 items-center'>
                                  <BsWhatsapp  className='text-xl' />
                                  <p>: +225 05 55 45 65 76</p>
                                </div>
                             </div>
                            </div>
                        </div>
                      </div>

                   </div>


                   <div className='xs:ml-12 s:mx-2 sm:mx-4 flex flex-col space-y-6 mb-14'>
                      <p className='text-[#4e4e4e] text-xl pt-4 font-semibold '>Description</p>
                        <p className='md:w-[900px] xs:w-[700px] s:w-[260px] sm:w-[300px] pt-2 text-justify'>Tellus rutrum tellus pellentesque eu tincidunt tortor. Sit amet est 
                          placerat in egestas erat imperdiet sed. Nunc lobortis mattis aliquam 
                          faucibus purus in massa tempor. Dui id ornare arcu odio ut sem nulla 
                          pharetra diam. Amet nisl purus in mollis nunc sed id semper. Hac 
                          habitasse platea dictumst quisque sagittis. Pharetra et ultrices 
                          neque ornare aenean euismod elementum nisi. Aenean sed adipiscing 
                          diam donec adipiscing tristique. Curabitur gravida arcu ac tortor 
                          dignissim convallis aenean et. Nunc mi ipsum faucibus vitae aliquet 
                          nec ullamcorper sit amet. Id diam vel quam elementum pulvinar etiam 
                          non quam. Pharetra vel turpis nunc eget lorem dolor sed viverra ipsum. 
                          Ultricies mi eget mauris pharetra et ultrices. Ut morbi tincidunt augue 
                          interdum velit euismod in pellentesque.
                        </p>

                        <Link to="/Reservation_Restaurant">
                          <button className='w-[170px] h-10 bg-[#FE7F2D] text-white text-lg rounded transition 
                          ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white
                          hover:text-[#FE7F2D] '>Réserver</button>
                        </Link>

                        <Commentaire/>
                   </div>
                      
                    <Suggestion/>
                    <Barniere/>

                <Footer />
                  </>
            )}
    </div>
  )
}

export default VoirPlus