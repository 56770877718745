import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import SidebarTop from '../SidebarTop';
import { LuClipboardList } from "react-icons/lu";
import { FaCar } from "react-icons/fa";
import BarChat from './BarChat';
import { MdOutlineHotel } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import Calendar from './Calendar';
import PieChart  from './PieChart';
import { ImStarEmpty } from "react-icons/im";
import TransationGraph from "./TransationGraph"
import Footer from '../Footer';


function Dashboard() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
        <div className='flex flex-col'>
              <div className='w-screen  barre_scroll h-screen'>
                      {/* sidebar top  */}
                      <SidebarTop />
                      {/* navbar top gris*/}
                      <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                        <Breadcrumbs >
                          <Link to="" className="opacity-60">
                          Tableau de bord
                          </Link>
                        </Breadcrumbs>
                      </div>
                      {/* container */}
                      <div className='mt-10 '>
                          <div className='grid md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2 s:gap-y-4 md:gap-y-0  place-content-center place-items-center '>
                            <Link to="/Dashboard/Reservation/Hebergement/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <MdOutlineHotel className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>20</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Reservation/Vehicule/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <FaCar className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>12</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Reservation/Restaurant/Liste">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <IoRestaurantOutline className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>680</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              <Link to="">
                                <div className='flex flex-row items-center justify-center h-[100px] w-[280px] space-x-4 bg-white shadow-md hover:bg-gray-50  rounded-lg '>
                                    <LuClipboardList className='text-5xl text-[#fe7f2d] bg-[#fe812d31] p-2 rounded' />
                                    <div className=''>
                                        <p className='text-3xl font-semibold'>12</p>
                                        <p>Nouvelles Réservations </p>
                                    </div>
                                </div>
                              </Link>

                              

                          </div>
                          <div className='grid md:grid-cols-3 s:grid-cols-1  gap-3 mt-10 ml-6 '>
                            <PieChart/>
                            <Calendar/>
                            <div className='flex flex-col space-y-3'>
                              <Link to='/Dashboard/Publications/Hebergement/Voir_des_hebergements'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>10</span> Appartements en ligne</p>
                                </div>
                              </Link>

                              <Link to='/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>260</span> Véhicule en ligne</p>
                                </div>
                              </Link>

                              <Link to='/Dashboard/Publications/Restaurant/Voir_des_restaurants'>
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>5</span> Restaurant en ligne</p>
                                </div>
                              </Link>

                              <Link to="/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes">
                                <div className='bg-[#233D4D] w-[340px] h-20 pl-5 rounded-lg shadow-md  text-[white] text-lg flex items-center space-x-3 hover:bg-white hover:text-[#233D4D]'>
                                <ImStarEmpty className='text-4xl bg-white rounded-md text-[#233D4D] p-2' />
                                  <p><span className='text-2xl font-semibold'>37</span> Site touristique en ligne</p>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className='mt-10 my-20 ml-6 grid md:grid-cols-2 s:grid-cols-1  md:gap-40 s:gap-8'>
                          <BarChat/>
                          <TransationGraph/>
                          </div>
                          
                          
                      </div>
              </div>
              <Footer/>
        </div>
          
        </>
      )}
     
    </div>
  )
}

export default Dashboard