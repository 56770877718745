import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { FcCancel } from "react-icons/fc";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import {
    ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { RxDotsVertical } from "react-icons/rx";
import {
    Card,
    CardHeader,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import SidebarTop from '../../SidebarTop';






const TABLE_HEAD = ["Client", "Vehicule", "Periode de location", "Lieu",  "Paiement", "Action",];

const TABLE_ROWS = [
    {
        name: "Koukou marie",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        vehicule: "BMW C1",
        temps: "22/04/2024-30/04/2024",
        Lieu:"Abijan",
        Paiement: "Payé",
    },
    {
        name: "Adjagbe christia",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        vehicule: "4X4",
        temps: "22/04/2024-30/04/2024",
        Lieu: "Abijan",
        Paiement: "À l'arrivé",
    },
    {
        name: "Touré pricille",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        vehicule: "Toyota",
        temps: "22/04/2024-30/04/2024",
        Lieu: "Abijan",
        Paiement: "À l'arrivé",
    },
    {
        name: "Murphy micheal",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        vehicule: "Suzuki",
        temps: "22/04/2024-30/04/2024",
        Lieu: "Abijan",
        Paiement: "Payé",
    },
    {
        name: "Coulibaly Lucas",
        email: "info@gmail.com",
        portable: "+225 01 00 00 00 00",
        vehicule: "Citroen",
        temps: "22/04/2024-30/04/2024",
        Lieu: "Abijan",
        Paiement: "Payé",
    },
];


function Liste() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Les reservations
                                </Link>
                                <Link to="" className="md:text-base s:text-xs">
                                    Vehicule
                                </Link>
                            </Breadcrumbs>
                        </div>

                        {/* container formulaire */}
                        <div className='flex justify-center md:p-10 s:p-4'>

                            <Card className="h-full w-full border-t-8 border-[#fe7f2d] ">
                                <CardHeader floated={false} shadow={false} className="rounded-none">
                                    <Typography className='md:text-xl s:text-lg font-semibold' color="blue-gray">
                                        Liste des personnes qui ont fait une reservation
                                    </Typography>
                                </CardHeader>
                                <CardBody className="overflow-scroll px-0">
                                    <table className="mt-4 w-full min-w-max overflow-scroll  table-auto text-left">
                                        <thead>
                                            <tr>
                                                {TABLE_HEAD.map((head, index) => (
                                                    <th
                                                        key={head}
                                                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                                    >
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                                        >
                                                            {head}{" "}
                                                            {index !== TABLE_HEAD.length - 1 && (
                                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                                                            )}
                                                        </Typography>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {TABLE_ROWS.map(
                                                ({ name, email, portable, vehicule, temps, Lieu, Paiement }, index) => {
                                                    const isLast = index === TABLE_ROWS.length - 1;
                                                    const classes = isLast
                                                        ? "p-4"
                                                        : "p-4 border-b border-blue-gray-50";

                                                    return (
                                                        <tr key={name} className='hover:bg-slate-100'>

                                                            <td className={classes}>
                                                                <div className="flex items-center space-x-2 ">
                                                                    <div className="flex flex-col">
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal"
                                                                        >
                                                                            {name}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {email}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="small"
                                                                            color="blue-gray"
                                                                            className="font-normal opacity-70"
                                                                        >
                                                                            {portable}
                                                                        </Typography>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {vehicule}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {temps}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <div className="flex flex-col">
                                                                    <Typography
                                                                        variant="small"
                                                                        color="blue-gray"
                                                                        className="font-normal"
                                                                    >
                                                                        {Lieu}
                                                                    </Typography>
                                                                </div>
                                                            </td>
                                                            <td className={classes}>
                                                                <Typography
                                                                    variant="small"
                                                                    color="blue-gray"
                                                                    className="font-normal"
                                                                >
                                                                    {Paiement}
                                                                </Typography>
                                                            </td>
                                                            <td className={classes}>
                                                                <Tooltip content="Action sur la publication">
                                                                    <IconButton variant="text">
                                                                        <Menu>
                                                                            <MenuHandler>
                                                                                <IconButton>
                                                                                    <RxDotsVertical />
                                                                                </IconButton>
                                                                            </MenuHandler>
                                                                            <MenuList className="flex flex-col gap-2">

                                                                                {/* Refuser */}
                                                                                <Link to="">
                                                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                                        <FcCancel className='text-lg' />
                                                                                        <div className="flex flex-col gap-1">
                                                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                                                Refuser
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                </Link>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                        Page 1 of 10
                                    </Typography>
                                    <div className="flex gap-2">
                                        <Button variant="outlined" size="sm">
                                            Previous
                                        </Button>
                                        <Button variant="outlined" size="sm">
                                            Next
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default Liste