import axios from 'axios';

let Test : any = {};
Test.submitForm = async (data:any) =>{
    //return await axios.post(`http://localhost:8000/api/test/eligi`, data
    return await axios.post(`https://monbonsejour.com/backend/api/test/eligi`, data
    // , {
    //     headers: { 
    //           'Content-Type' : 'application/json'
    //         }
    //     }
      )
    // return await axios.post(`https://monbonsejour.com/backend/api/test/eligi`, data, {
    //     headers: {
    //         "content-type": "multipart/form-data",
    //       },
    //     })
}

export default Test;