import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { IoMdPin } from "react-icons/io";
import { useState, useEffect } from 'react';
import { GoArrowLeft } from "react-icons/go";
import Loading from '../../../COMPONENTS/Loading/Loading'
import {
    Card,
    CardHeader,
    CardBody,
} from "@material-tailwind/react";
import { BiHotel } from "react-icons/bi";
import { TbFridge } from "react-icons/tb";
import { IoBedOutline } from "react-icons/io5";
import { RxDimensions } from "react-icons/rx";
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { IoRestaurant } from "react-icons/io5";
import { LuMicrowave } from "react-icons/lu";
import { GiBathtub } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEyeOff } from "react-icons/io";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

import SidebarTop from '../../SidebarTop';
import img1 from "../../../../ASSETS/tourisme/Image/voiture2.jpg";
import img2 from "../../../../ASSETS/tourisme/Image/voiture3.jpg";
import img3 from "../../../../ASSETS/tourisme/Image/Voiture.jpg";
import Carousel from '../Carousel';



function VoirPlus() {

    const slides = [img1,img2,img3];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements" className="opacity-60 md:text-base s:text-xs">
                                    Hebergements
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                            <Link to="/Dashboard/Publications/Hebergement/Voir_des_hebergements">
                                <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                    <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                    <p>Retour à la page précedente</p>
                                </div>
                            </Link>

                        {/* container */}
                        <div className='flex justify-center md:p-10 s:px-4 s:pt-14 xs:pt-0'>

                            <Card className="h-full xs:w-[700px] s:w-[260px] sm:w-[340px]">
                                <CardHeader floated={false} shadow={false} className="rounded-none pl-4 flex items-center justify-between ">
                                    <Typography className='sm:text-lg s:text-base font-semibold text-[#fe7f2d]' color="blue-gray">
                                        Nom de l'hotel ou la residence
                                    </Typography>
                                    <Tooltip  content="Action sur la publication">
                                    <IconButton variant="text">
                                        <Menu>
                                        <MenuHandler>
                                            <IconButton variant="text">
                                            <RxDotsVertical className='text-lg' />
                                            </IconButton>
                                        </MenuHandler>
                                        <MenuList className="flex flex-col gap-2">

                                            {/* modifier */}
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <FiEdit className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Modifier
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                            {/* Desactiver */}
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <IoIosEyeOff className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Désactiver
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                        </MenuList>
                                        </Menu>
                                    </IconButton>
                                </Tooltip>

                                </CardHeader>
                                <CardBody className=" px-8 pb-20 ">
                                   <div className='flex justify-center'>
                                        <div className="md:w-[500px]  xs:w-[400px] sm:w-[300px] s:w-[260px] ">
                                            <Carousel slides={slides}  />
                                        </div>
                                   </div>
                                    <div className='flex sm:flex-row s:flex-col sm:justify-between py-3'>
                                        <p className='font-semibold text-[#233D4D]  xs:text-lg s:text-base '>Villa Suite Home</p>
                                        <div className='flex items-center'><p className=' font-medium text-[#233D4D] xs:text-xl s:text-base'>50.000 XOF</p> <p className=' font-thin text-gray-400'>/ Nuitée</p></div>
                                    </div>
                                    <div className='flex sm:flex-row s:flex-col sm:justify-between pt-2'>
                                        <div className='flex space-x-2 items-center '>
                                        <IoMdPin className=' text-[#fe7f2d] ' />
                                        <p className='s:text-xs xs:text-base'>Cocody, Abidjan Côte d'ivoire</p>
                                        </div>
                                        <div className='bg-[#56b256] h-8 xs:w-[100px] s:w-[80px] s:text-sm xs:text-base text-white rounded font-thin justify-center items-center flex'>
                                            Disponible
                                        </div>
                                    </div>
                                    <div className='grid xs:grid-cols-3 s:grid-cols-1 gap-y-6  pt-6'>
                                        {/* nombre de chambre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <BiHotel className='text-xl ' />
                                                <p>Nombre de chambre</p>
                                            </div>
                                            <p className='pl-2'>4 Chambres</p>
                                        </div>
                                        {/* Perimetre */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <RxDimensions className='text-xl ' />
                                                <p>Périmètre</p>
                                            </div>
                                            <p className='pl-2'>100 km<sup>2</sup></p>
                                        </div>
                                        {/* Type de lit */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <IoBedOutline className='text-xl ' />
                                                <p>Type de lit</p>
                                            </div>
                                            <p className='pl-2'>Lit double</p>
                                        </div>
                                        {/* Capacité de la salle */}
                                        <div className='flex flex-col space-y-2' >
                                            <div className='flex text-sm font-thin items-center space-x-2 text-gray-400'>
                                                <FaUser  className='' />
                                                <p>Capacité de la salle</p>
                                            </div>
                                            <p className='pl-2'>2-5 Personnes</p>
                                        </div>
                                    </div>
                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Les équipements</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'> Baignoire, douche, Serviettes, Linge de maison, 
                                        Prise près du lit, Entrée privée, Réfrigérateur Plateau, bouilloire, Chauffage,
                                        Ustensiles de cuisine, Bouilloire électrique, Mobilier d’extérieur, Chaînes du câble,
                                        Étendoir, Papier toilette, Purificateurs d'air, Climatiseur indépendant.</p>
                                    </div>

                                    <div className='pt-6 grid xs:grid-cols-3 s:grid-cols-1  gap-y-6'>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlineWifi className='text-xl text-[#fe7f2d] ' />
                                            <p>Wifi : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                            <p>Piscine privée : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                            <p>TV : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <IoRestaurant className='text-xl text-[#fe7f2d]' />
                                            <p>Pétit déjeuner : Non</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <FaKitchenSet className='text-xl text-[#fe7f2d]' />
                                            <p>Ustensile : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <TbFridge className='text-xl text-[#fe7f2d]' />
                                            <p>Frigo : Non</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <LuMicrowave className='text-xl text-[#fe7f2d]' />
                                            <p>Micro onde : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <GiBathtub  className='text-xl text-[#fe7f2d]' />
                                            <p>Baignoire : Oui</p>
                                        </div>
                                         
                                    </div>

                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Description</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'> 
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae 
                                        pulvinar tellus, a consectetur enim. Suspendisse non metus tempus, 
                                        cursus ante semper, viverra leo. Praesent eget urna et erat volutpat 
                                        finibus. Curabitur ut convallis ex, pretium mattis orci. Ut nec lorem 
                                        vitae turpis posuere fringilla. Nunc maximus vitae libero in porttitor. 
                                        Nullam interdum diam sit amet ipsum sagittis dignissim. Vestibulum feugiat 
                                        cursus mi, et consectetur risus sollicitudin eu. Sed porttitor turpis ut erat 
                                        aliquam, congue auctor nibh congue. Praesent et feugiat massa. Pellentesque 
                                        habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                        </p>
                                    </div>
                                </CardBody>
                               
                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirPlus