import React from 'react'
import { MdOutlineSupportAgent } from "react-icons/md";
import { SiYourtraveldottv } from "react-icons/si";
import { GiDiscussion } from "react-icons/gi";
import { IoIosSend } from "react-icons/io";
import barniere from '../../../ASSETS/AfImmigration/barniere.png'
import {Link} from 'react-router-dom'


function BarniereService() {
  return (
    <div className='grid s:grid-cols-1 xs:grid-cols-2 py-14 xs:px-10 s:px-3 '>
        <div className='flex flex-col space-y-10'>
            <div className='text-3xl font-semibold text-[#233D4D] xs:w-[400px] s:w-[260px] sm:w-[300px]'>
                Duis aute irure dolor in repre rit in voluptate velit
            </div>
            <div className='md:w-[500px] xs:w-[340px] s:w-[260px] sm:w-[300px] text-lg'>
                Proin vel cursus nunc, vitae faucibus diam. Nam sapien neque, 
                aliquet ac est et, finibus placerat dolor. Phasellus pharetra sed libero 
                We understand the importance of approaching each work integrally and 
                believe in the power of simple.
            </div>

            <div className='flex s:flex-col xs:flex-row xs:items-center s:space-y-6 xs:space-y-0 xs:space-x-6'>

                {/* assistance */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='bg-white w-12 h-12 rounded-full shadow-md flex justify-center items-center'>
                        <MdOutlineSupportAgent className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Assistance</div>
                </div>

                {/* immigration */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='bg-white w-12 h-12 rounded-full shadow-md flex justify-center items-center'>
                        <SiYourtraveldottv className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Immigration</div>
                </div>

                {/* conseil */}
                <div className='flex flex-row items-center space-x-2'>
                    <div className='bg-white w-12 h-12 rounded-full shadow-md flex justify-center items-center'>
                        <GiDiscussion className='text-3xl text-[#fe7f2d]' />
                    </div>
                    <div className='text-lg font-semibold text-[#4E4E4E]'>Conseil</div>
            </div>
        </div>

        <Link to='/PrendreRendezVous'>
            <button className=' bg-[#FCCA46] flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                <p>Prendre rendez-vous</p>
                <IoIosSend />
            </button>
        </Link>

        </div>
        <img src={barniere} className='w-[500px] s:mt-10 xs:mt-0' alt='barniere'/>
    </div>
  )
}

export default BarniereService