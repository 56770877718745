import React from 'react'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BackImage from './BackImage';
import botanique from '../../../ASSETS/tourisme/Image/image_loisirs/botanique.png'
import galerie from '../../../ASSETS/tourisme/Image/image_loisirs/galerieCecile.png'
import mall from '../../../ASSETS/tourisme/Image/image_loisirs/Mall.png'
import cosmos from '../../../ASSETS/tourisme/Image/image_loisirs/cosmos.png'
import sofitel from '../../../ASSETS/tourisme/Image/image_loisirs/sofitel-abidjan-hotel.png'
import muse from '../../../ASSETS/tourisme/Image/image_loisirs/Musée_des_civilisations1.png'
import { GiMoneyStack } from "react-icons/gi";
import { IoMdPin } from "react-icons/io";
import Barniere from './Barniere';
import {Link} from 'react-router-dom'

function Loisirs() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
          {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>

          {/* filtre */}
            <div className='flex s:flex-col xs:flex-row xs:space-x-4 s:space-y-4 xs:space-y-0  pl-10 pt-8'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white  hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              <button className='w-[200px] h-10 border-[#233D4D] border-2 text-lg text-[#233D4D]  hover:bg-[#233D4D] hover:text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Les plus populaires</button>
              <button className='w-[200px] h-10 border-[#233D4D] border-2 text-lg text-[#233D4D]  hover:bg-[#233D4D] hover:text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Recommandée</button>
            </div>

            {/* Les activitées */}
            <div className='my-10 grid md:grid-cols-3 xs:grid-cols-2 s:grid-cols-1 gap-y-14  place-items-center'>
                {/* botanique */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={botanique} alt='botanique'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>Jardin botanique de bingerville</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>Bingerville, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p > 1000 FCFA - 5000 FCFA</p>
                        </div>
                    </div>

                   <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                        <Link to='/Reservation_Loisirs'>
                            <div className='grid place-content-end'>
                                <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                            </div>
                        </Link>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                {/* galerie cecile */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={galerie} alt='galerie'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>Galerie Cécile Fakhoury</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>Cocody, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p > 500 FCFA</p>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                         <div className='grid place-content-end'>
                            <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                        </div>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                {/* Abidjan mall */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={mall} alt='mall'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>Abidjan Mall</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>Cocody, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p >5000 FCFA</p>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                         <div className='grid place-content-end'>
                            <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                        </div>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                {/* cosmos */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={cosmos} alt='cosmos'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>L'espace Cosmos</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>Yopougon, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p >15000 FCFA</p>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                         <div className='grid place-content-end'>
                            <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                        </div>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                {/* sofitel */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={sofitel} alt='sofitel'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>Bruche à Sofitel ivoire</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>Cocody, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p >15000 FCFA</p>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                         <div className='grid place-content-end'>
                            <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                        </div>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                {/* Musée */}
                <div className='xs:w-[360px] sm:w-[320px] s:w-[260px]'>
                    <img className='w-[360px] rounded' src={muse} alt='muse'/>
                    {/* description */}
                    <p className='text-lg text-[#fe7f2d] font-semibold py-3'>Musée des civilisations</p>
                    <p className='xs:w-[350px] sm:w-[320px] s:w-[270px]'>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    {/* lieu et prix */}
                    <div className='flex xs:flex-row s:flex-col xs:items-center xs:space-x-3 s:space-y-3 xs:space-y-0 py-4'>
                        <div className='font-thin flex flex-row items-center  space-x-2 '>
                        <IoMdPin className='text-xl text-[#fe7f2d]' />
                        <p>plateau, Abidjan</p>
                        </div>
                        <div className=' font-thin flex flex-row items-center space-x-2'>
                            <GiMoneyStack className='text-xl text-green-600' />
                            <p >15000 FCFA</p>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                         {/* Acheter un tiket */}
                         <div className='grid place-content-end'>
                            <button className='hover:bg-[white] w-[150px] h-10 rounded bg-[#fe7f2d]  text-[white] hover:text-[#fe7f2d] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Acheter un ticket</button>
                        </div>
                        {/* decouvrir */}
                        <Link to="/Loisirs_Activite/Voir_Plus">
                        <div className='grid place-content-end'>
                            <button className='bg-[#233D4D] w-[100px] h-10 text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>Decouvrir</button>
                        </div>
                        </Link>
                   </div>
                </div>
                
            </div>

           <Barniere/>

          <Footer/>
        </>
      )}
    </div>
  )
}

export default Loisirs