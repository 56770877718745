import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { IoMdPin } from "react-icons/io";
import { useState, useEffect } from 'react';
import { GoArrowLeft } from "react-icons/go";
import Loading from '../../../COMPONENTS/Loading/Loading'
import {
    Card,
    CardHeader,
    CardBody,
} from "@material-tailwind/react";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEyeOff } from "react-icons/io";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";

import SidebarTop from '../../SidebarTop';
import img1 from "../../../../ASSETS/tourisme/Image/voiture2.jpg";
import img2 from "../../../../ASSETS/tourisme/Image/voiture3.jpg";
import img3 from "../../../../ASSETS/tourisme/Image/Voiture.jpg";
import Carousel from '../Carousel';


function VoirPlus() {
    const slides = [img1,img2,img3];


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>
                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes" className="opacity-60 md:text-base s:text-xs">
                                    Culture & Decouverte
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                    Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <Link to="/Dashboard/Publications/Culture_Decouverte/Voir_les_decouvertes">
                            <div className='flex flex-row items-center space-x-2 relative top-8 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précdente</p>
                            </div>
                        </Link>

                        {/* container */}
                        <div className='flex justify-center md:p-10 s:px-4 s:pt-16'>

                            <Card className="h-full xs:w-[700px] s:w-[260px] sm:w-[340px] ">
                                <CardHeader floated={false} shadow={false} className="rounded-none pl-4 flex items-center justify-between ">
                                   
                                    <Tooltip content="Action sur la publication">
                                        <IconButton variant="text">
                                            <Menu>
                                                <MenuHandler>
                                                    <IconButton variant="text">
                                                        <RxDotsVertical className='text-lg' />
                                                    </IconButton>
                                                </MenuHandler>
                                                <MenuList className="flex flex-col gap-2">

                                                    {/* modifier */}
                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                        <FiEdit className='text-lg' />
                                                        <div className="flex flex-col gap-1">
                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                Modifier
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                    {/* Desactiver */}
                                                    <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                        <IoIosEyeOff className='text-lg' />
                                                        <div className="flex flex-col gap-1">
                                                            <Typography variant="small" color="gray" className="font-semibold">
                                                                Désactiver
                                                            </Typography>
                                                        </div>
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </IconButton>
                                    </Tooltip>

                                </CardHeader>
                                <CardBody className=" sm:px-4 xs:px-8 pb-20">
                                    <div className='flex justify-center'>
                                        <div className="md:w-[500px]  xs:w-[400px] sm:w-[300px] s:w-[260px] ">
                                            <Carousel slides={slides}  />
                                        </div>
                                   </div>
                                    <div className='flex sm:flex-row s:flex-col sm:justify-between py-3'>
                                        <p className='font-semibold text-[#233D4D]  text-lg '>Forêt du banco</p>
                                        <div className='flex items-center'><p className=' font-medium text-[#233D4D] xs:text-xl s:text-lg'>1.000 XOF</p> <p className=' font-thin text-gray-400'>/ persone</p></div>
                                    </div>
                                    <div className='flex space-x-2 items-center pt-2'>
                                        <IoMdPin className=' text-[#fe7f2d] ' />
                                        <p className='s:text-xs xs:text-base'>Adjamé, Abidjan Côte d'ivoire</p>
                                    </div>

                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Description</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae
                                            pulvinar tellus, a consectetur enim. Suspendisse non metus tempus,
                                            cursus ante semper, viverra leo. Praesent eget urna et erat volutpat
                                            finibus. Curabitur ut convallis ex, pretium mattis orci. Ut nec lorem
                                            vitae turpis posuere fringilla. Nunc maximus vitae libero in porttitor.
                                            Nullam interdum diam sit amet ipsum sagittis dignissim. Vestibulum feugiat
                                            cursus mi, et consectetur risus sollicitudin eu. Sed porttitor turpis ut erat
                                            aliquam, congue auctor nibh congue. Praesent et feugiat massa. Pellentesque
                                            habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                        </p>
                                    </div>

                                </CardBody>

                            </Card>

                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirPlus