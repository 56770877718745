import React from 'react'
import basilique from '../../../ASSETS/tourisme/Image/baselique.jpg'
import mosque from '../../../ASSETS/tourisme/Image/Mosque.jpg'
import cascade from '../../../ASSETS/tourisme/Image/cascade_man.jpg'
import Footer from '../../COMPONENTS/Footer/Footer'
import {Link} from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import lieu from '../../../ASSETS/tourisme/icone/pin.png'
import SaintPaul from '../../../ASSETS/tourisme/Image/cathedralePaul.jpg'
import banco from '../../../ASSETS/tourisme/Image/banco.jpg'
import fort from '../../../ASSETS/tourisme/Image/fortDabou.jpg'
import BackImage from './BackImage'
import BarniereCD from './BarniereCD'
import { VscEye } from "react-icons/vsc";



function Culture() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>

      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
        {/* Back image */}
          <BackImage/>

        {/* barre de recherche culture */}
        <div className=' flex  justify-center items-center pb-10'>
          <div className='  border-2 shadow-xl flex space-x-2 justify-center items-center px-4'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32.52" height="32" viewBox="0 0 42.52 42">
                <g id="loupe" transform="translate(0.001)">
                  <path id="Tracé_134" data-name="Tracé 134" d="M27.408,22.321l13.8,12.354a4.227,4.227,0,0,1,.047,6.076A4.354,4.354,0,0,1,35.1,40.7L22.6,27.081a16.3,16.3,0,0,0,4.806-4.76ZM4.029,3.981a13.881,13.881,0,0,1,19.459,0,13.474,13.474,0,0,1,0,19.222,13.88,13.88,0,0,1-19.459,0A13.475,13.475,0,0,1,4.029,3.981ZM8.036,19.245a8.165,8.165,0,0,0,11.446,0,7.926,7.926,0,0,0,0-11.307,8.165,8.165,0,0,0-11.446,0,7.926,7.926,0,0,0,0,11.307Zm0,0" transform="translate(0)" fill="#cecece"/>
                </g>
              </svg>
                <input className='md:w-[700px] xs:w-[400px] s:w-[230px] sm:w-[300px]  bg-transparent  border-none' type='search' placeholder='Entrer un site touristique ou une ville'/>
          </div>
        </div>

            {/* container2 */}
            <div className='container2_home px-5 w-screen flex flex-col md:space-y-6 items-center justify-center py-10 my-20 '>
              <div className='flex flex-col items-center justify-center text-white space-y-3 pb-10'>
                <p className='md:text-3xl sm:text-xl'> Culture & <span className='text-orange-400'>Découverte,</span></p>
                <p className='md:text-lg sm:text-lg '>Découvrez les différents sites touristiques à visités et diverses activités sur la plateforme </p>
              </div>

              <div className='grid gap-8 lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 sm:grid-cols-1 pb-10 '>

                {/* basilique  */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Basilique de yamoussoukro</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={basilique} alt='basilique' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      La basilique Notre-Dame-de-la-Paix, capitale politique de la Côte
                      d'Ivoire est l'une des plus grandes églises

                    </p>
                    
                    <Link to="/culture/Basilique_de_Yamoussokro">
                      <div className='grid place-items-end mt-4 '>
                      <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                        <p>Voir plus</p>
                        <VscEye className='text-lg' />
                      </button>
                    </div>
                    </Link>
                  </div>
                </div>

                {/* Mosque  */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Mosquée séculaire de Kouto</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={mosque} alt='Mosque' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      La mosquée de Kouto est une mosquée à
                      style soudanais située à Kouto dans le Nord
                    </p>
                    <div className='grid place-items-end mt-4 '>
                      <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                        <p>Voir plus</p>
                        <VscEye className='text-lg' />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Cascade  */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Cascades naturelles de Man</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={cascade} alt='Mosque' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      Les cascades de Man, site touristique situé
                      en Côte d'ivoire dans la ville de Man
                    </p>
                    <div className='grid place-items-end mt-4 '>
                      <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                        <p>Voir plus</p>
                        <VscEye className='text-lg' />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Fort   */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Le Fort de Dabou</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={fort} alt='Mosque' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      Le fort de Dabou, aussi appelé fort Faidherbe ou fort Binger, est un fort historique ...
                    </p>
                    <Link to="/culture/Fort_de_Dabou">
                    <div className='grid place-items-end mt-4 '>
                        <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                          <p>Voir plus</p>
                          <VscEye className='text-lg' />
                        </button>
                    </div>
                    </Link>
                  </div>
                </div>

                {/* Cathedrale Saint Paul   */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Cathédrale Saint-Paul d'Abidjan</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={SaintPaul} alt='Cathédrale Saint-Paul d Abidjan' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      La cathédrale Saint-Paul d’Abidjan est la cathédrale de l'archidiocèse d'Abidjan.
                    </p>
                    <div className='grid place-items-end mt-4 '>
                      <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                        <p>Voir plus</p>
                        <VscEye className='text-lg' />
                      </button>
                    </div>
                  </div>
                </div>

                {/* Banco   */}
                <div className='flex  flex-col items-center'>
                  {/* titre */}
                  <div className='flex items-center space-x-2 bg-[#FCCA46] py-2 pl-3 w-[250px] rounded-md relative top-12 md:right-9'>
                    {/* icone */}
                    <img src={lieu} alt='iconeLieu' className='w-6' />
                    {/* texte */}
                    <p className=' text-white text-sm '>Parc national du Banco</p>
                  </div>
                  {/* image du site touristique */}

                  <img src={banco} alt='Cathédrale Saint-Paul d Abidjan' className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 md:w-[350px] cursor-zoom-in  sm:w-[320px] h-[250px] s:w-[260px] rounded-md' />

                  {/* bloc blan description */}
                  <div className='md:w-[320px] rounded-md  sm:w-[300px] s:w-[260px] h-[150px]  bg-white p-5 relative bottom-12'>
                    <p className='md:w-[280px] sm:w-[260px] s:w-[230px] '>
                      Le Parc national du Banco est un parc national de la Côte d'Ivoire situé en plein cœur d'Abidjan.
                    </p>
                    <Link to="/culture/Foret_du_Banco">
                    <div className='grid place-items-end mt-4 '>
                      <button className='transform active:scale-75 transition-transform text-sm flex items-center space-x-2 font-bold bg-orange-400 px-2 h-8 rounded  text-white '>
                        <p>Voir plus</p>
                        <VscEye className='text-lg' />
                      </button>
                    </div>
                    </Link>
                  </div>
                </div>

              </div>
            </div>

          {/* Banniere culture et decouverte */}
            <BarniereCD/>


           <Footer/>
        </>
      )}
    </div>
  )
}

export default Culture
