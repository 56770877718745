import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { IoIosEyeOff } from "react-icons/io";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { IoAddSharp } from "react-icons/io5";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Typography
} from "@material-tailwind/react";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEye } from "react-icons/io";
import SidebarTop from '../../SidebarTop';
import { IoMdPin } from "react-icons/io";
import {
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import musee from '../../../../ASSETS/tourisme/Image/image_loisirs/Musée_des_civilisations1.png'




function VoirCulture() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

                    <div className='w-screen  barre_scroll h-screen'>
                        {/* sidebar top  */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs >
                                <Link to="" className="opacity-60">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites" className="opacity-60">
                                    Loisirs et Activités
                                </Link>
                                <Link to="">
                                    Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                        {/* boutton ajouter un decouverte*/}
                        <div className='p-6  w-[230px]' >
                            <Link to="/Dashboard/Publications/Loisirs_activites/Ajouter_les_activites">
                                <button className='flex flex-row items-center justify-center h-10  space-x-2 bg-[#FCCA46] text-white md:text-base s:text-sm md:w-[230px] s:w-[190px] transform active:scale-75 transition-transform'>
                                    <IoAddSharp />
                                    <p>Ajouter une activité</p>
                                </button>
                            </Link>
                        </div>
                        {/* container formulaire */}
                        <div className='flex justify-center p-10 '>
                            <div className='grid md:grid-cols-4 xs:grid-cols-2 s:grid-cols-1 gap-6 place-items-center'>
                                {/* decouverte 1 */}
                                <div className='bg-white hover:bg-slate-100 s:w-[240px] sm:w-[280px] flex flex-col  p-4 shadow rounded'>
                                    <img src={musee} className='w-[250px] h-[200px]' alt='decouverte' />
                                    <p className='text-[#fe7f2d] font-semibold text-lg pt-3 '>Musée des civilisations</p>
                                    <p className='pt-2 font-thin'>The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.</p>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className='flex flex-row items-center space-x-2 text-sm pt-2'>
                                            <IoMdPin />
                                            <p>Abidjan</p>
                                        </div>
                                        {/* Action */}
                                        <div>
                                            <Tooltip content="Action sur la publication">
                                                <IconButton variant="text">
                                                    <Menu>
                                                        <MenuHandler>
                                                            <IconButton variant='text'>
                                                                <RxDotsVertical className='text-lg' />
                                                            </IconButton>
                                                        </MenuHandler>
                                                        <MenuList className="flex flex-col gap-2">

                                                            {/* modifier */}
                                                            <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites/Modifier">
                                                                <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                    <FiEdit className='text-lg' />
                                                                    <div className="flex flex-col gap-1">
                                                                        <Typography variant="small" color="gray" className="font-semibold">
                                                                            Modifier
                                                                        </Typography>
                                                                    </div>
                                                                </MenuItem>
                                                            </Link>
                                                            {/* Voir */}
                                                            <Link to="/Dashboard/Publications/Loisirs_activites/Voir_des_activites/Voir_Plus">
                                                                <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                    <IoIosEye className='text-lg' />
                                                                    <div className="flex flex-col gap-1">
                                                                        <Typography variant="small" color="gray" className="font-semibold">
                                                                            Voir
                                                                        </Typography>
                                                                    </div>
                                                                </MenuItem>
                                                            </Link>
                                                            {/* Desactiver */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEyeOff className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Désactiver
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <p className='text-sm flex justify-end'>Activé</p>
                                </div>
                                {/* decouverte 2 */}
                                <div className='bg-white hover:bg-slate-100 s:w-[240px] sm:w-[280px] flex flex-col  p-4 shadow rounded'>
                                    <img src={musee} className='w-[250px] h-[200px]' alt='decouverte' />
                                    <p className='text-[#fe7f2d] font-semibold text-lg pt-3 '>Musée des civilisations</p>
                                    <p className='pt-2 font-thin'>The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.</p>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className='flex flex-row items-center space-x-2 text-sm pt-2'>
                                            <IoMdPin />
                                            <p>Abidjan</p>
                                        </div>
                                        {/* Action */}
                                        <div>
                                            <Tooltip content="Action sur la publication">
                                                <IconButton variant="text">
                                                    <Menu>
                                                        <MenuHandler>
                                                            <IconButton variant='text'>
                                                                <RxDotsVertical className='text-lg' />
                                                            </IconButton>
                                                        </MenuHandler>
                                                        <MenuList className="flex flex-col gap-2">

                                                            {/* modifier */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <FiEdit className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Modifier
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Voir la chambre */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEye className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Voir
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Desactiver */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEyeOff className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Désactiver
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <p className='text-sm flex justify-end'>Desactivé</p>
                                </div>
                                {/* decouverte 3 */}
                                <div className='bg-white hover:bg-slate-100 s:w-[240px] sm:w-[280px] flex flex-col  p-4 shadow rounded'>
                                    <img src={musee} className='w-[250px] h-[200px]' alt='decouverte' />
                                    <p className='text-[#fe7f2d] font-semibold text-lg pt-3 '>Musée des civilisations</p>
                                    <p className='pt-2 font-thin'>The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.</p>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className='flex flex-row items-center space-x-2 text-sm pt-2'>
                                            <IoMdPin />
                                            <p>Abidjan</p>
                                        </div>
                                        {/* Action */}
                                        <div>
                                            <Tooltip content="Action sur la publication">
                                                <IconButton variant="text">
                                                    <Menu>
                                                        <MenuHandler>
                                                            <IconButton variant='text'>
                                                                <RxDotsVertical className='text-lg' />
                                                            </IconButton>
                                                        </MenuHandler>
                                                        <MenuList className="flex flex-col gap-2">

                                                            {/* modifier */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <FiEdit className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Modifier
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Voir la chambre */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEye className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Voir
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Desactiver */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEyeOff className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Désactiver
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <p className='text-sm flex justify-end'>Activé</p>
                                </div>
                                {/* decouverte 4 */}
                                <div className='bg-white hover:bg-slate-100 s:w-[240px] sm:w-[280px] flex flex-col  p-4 shadow rounded'>
                                    <img src={musee} className='w-[250px] h-[200px]' alt='decouverte' />
                                    <p className='text-[#fe7f2d] font-semibold text-lg pt-3 '>Musée des civilisations</p>
                                    <p className='pt-2 font-thin'>The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Barcelona.</p>
                                    <div className='flex flex-row items-center justify-between'>
                                        <div className='flex flex-row items-center space-x-2 text-sm pt-2'>
                                            <IoMdPin />
                                            <p>Abidjan</p>
                                        </div>
                                        {/* Action */}
                                        <div>
                                            <Tooltip content="Action sur la publication">
                                                <IconButton variant="text">
                                                    <Menu>
                                                        <MenuHandler>
                                                            <IconButton variant='text'>
                                                                <RxDotsVertical className='text-lg' />
                                                            </IconButton>
                                                        </MenuHandler>
                                                        <MenuList className="flex flex-col gap-2">

                                                            {/* modifier */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <FiEdit className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Modifier
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Voir la chambre */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEye className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Voir
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                            {/* Desactiver */}
                                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                                                <IoIosEyeOff className='text-lg' />
                                                                <div className="flex flex-col gap-1">
                                                                    <Typography variant="small" color="gray" className="font-semibold">
                                                                        Désactiver
                                                                    </Typography>
                                                                </div>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <p className='text-sm flex justify-end'>Desactivé</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirCulture