import React from 'react'
import Footer from '../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import BackImage from './BackImage';
import cafe from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 5.png'
import resto from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 6.png'
import pizza from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 7.png'
import bar from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 8.png'
import patisserie from '../../../ASSETS/tourisme/Image/image_restaurants/Groupe de masques 10.png'
import mario from '../../../ASSETS/tourisme/Image/mario.png'
import { VscEye } from "react-icons/vsc";
import quenns from '../../../ASSETS/tourisme/Image/quenns.png'
import laBrise from '../../../ASSETS/tourisme/Image/laBrise.png'
import havana from '../../../ASSETS/tourisme/Image/havana.png'
import {Link} from 'react-router-dom'
import Barniere from './Barniere';

function Restaurant() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
         {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          
          <BackImage/>
          {/* Services */}
          <div className='relative md:mt-40 s:mt-80 pb-10 flex flex-col items-center'>
            <div className='text-[#233D4D] text-2xl font-semibold py-10'>Nos services</div>
            <div className='grid md:grid-cols-5 s:grid-cols-1 xs:grid-cols-3 gap-20 '>
                {/* cafeteria */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={cafe} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Cafétéria</p>
                    <p className='w-[150px] text-center'>l'arôme envoûtant du café fraîchement moulu</p>
                </div>
                {/* Restaurant */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={resto} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Restaurant</p>
                    <p className='w-[150px] text-center'>une expérience culinaire d'exception dans des restaurants </p>
                </div>
                {/* Pizzeria */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={pizza} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Pizzeria</p>
                    <p className='w-[150px] text-center'>déguster des pizzas exquises </p>
                </div>
                {/* Bar */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={bar} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Bar</p>
                    <p className='w-[150px] text-center'>des instants festifs au cœur de notre bar. Cheers </p>
                </div>
                {/* patisserie */}
                <div >
                    <img className='w-[150px] bg-[#cecece] p-5 ' src={patisserie} alt='café'/>
                    <p className='text-[#FCCA46] font-semibold text-lg text-center pt-3'>Patisserie</p>
                    <p className='w-[150px] text-center'>un moment sucré dans notre pâtisserie exquise </p>
                </div>
            </div>
          </div>

           {/* barre bleu */}
            <div className='border-t-4 border-slate-900 my-16 w-2/3 grid'></div>

             {/* title */}
            <div className=' flex flex-col justify-center items-center space-y-5 my-10'>
                <p className='text-center font-semibold xs:text-2xl s:text-xl'> RESTAURATIONS</p>
                <p className='text-center s:w-[260px] sm:w-[340px] md:w-[850px] xs:w-[600px] text-lg'>
                Savourez l'excellence culinaire en Côte d'Ivoire ! Découvrez une fusion de saveurs 
                authentiques, de plats traditionnels et de créations innovantes. Faites de votre repas 
                une aventure mémorable.
                </p>
            </div>

             {/* filtre */}
            <div className='grid md:grid-cols-4 xs:grid-cols-3 s:gap-6 md:gap-0 s:grid-cols-1 place-items-center pt-10'>
              <button className='w-[200px] h-10 bg-[#233D4D] text-lg text-white rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>Tout</button>
              {/* etoiles*/}
              <select className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'>
                <option>5 étoiles</option>
                <option>4 étoiles</option>
                <option>3 étoiles</option>
                <option>2 étoiles</option>
              </select>
              {/* Lieu ou la voiture se trouve */}
              <input type='text' name='lieu_voiture' placeholder='Ville ou commune' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg'/>
              {/* prix de la voiture */}
              <input type='text' name='lieu_voiture' placeholder='Prix' className='border-[#233D4D] border-2 w-[200px] px-3 h-10 rounded text-lg' />
            </div>

            {/* container restaurant */}
           <div className='flex md:flex-row s:flex-col s:items-center md:items-start justify-center md:space-x-4 s:space-y-10 md:space-y-0 my-20'>

            {/* Container de gauche */}
            <div className=' flex flex-col space-y-8 border-l-2 border-[#fe7f2d] pl-5'>

                {/* restaurant1 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={mario} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>chez mario</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                       <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
                {/* restaurant2 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={quenns} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>quenn's restaurant</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
                {/* restaurant3 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={laBrise} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>restaurant la brise</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
                {/* restaurant4  */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={havana} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>havana</p>
                        <p className='text-[#233D4D]font-medium'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>
                    </div>
                </div>
            </div>

            {/* Container de droite */}
            <div className=' flex flex-col space-y-8 border-l-2 border-[#fe7f2d] pl-5'>

                {/* restaurant1 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={mario} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>chez mario</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>
                    </div>
                </div>
                {/* restaurant2 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={quenns} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>quenn's restaurant</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
                {/* restaurant3 */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={laBrise} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>restaurant la brise</p>
                        <p className='text-[#233D4D]font-medium pb-2'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
                {/* restaurant4  */}
                <div className='flex xs:flex-row s:flex-col space-x-6  space-y-5 '>
                    <img className=' rounded-full w-[180px] h-[180px] border-4 border-yellow-400 shadow-xl' src={havana} alt=''/>
                    {/* Description */}
                    <div>
                        <p className='uppercase text-lg font-semibold text-[#FE7F2D] pb-3'>havana</p>
                        <p className='text-[#233D4D]font-medium'>Yamoussokro</p>
                        <p className='xs:w-[350px] s:w-[200px] sm:w-[300px]'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum 
                            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non 
                        </p>
                        <div className='flex xs:flex-row s:flex-col xs:space-x-4 s:space-y-3 xs:space-y-0'>
                        <Link to='/restaurants/Voir_Plus'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    <p>Visiter</p>
                                    <VscEye className='text-lg' />
                                </button>
                            </Link>
                            <Link to='/Reservation_Restaurant'>
                                <button className='bg-slate-800 text-white flex items-center space-x-2 h-8 px-5 my-3 rounded hover:bg-white hover:border-2 hover:border-slate-800 hover:text-slate-800'>
                                    Reserver
                                </button>
                            </Link>
                       </div>

                    </div>
                </div>
            </div>

           </div>

           <Barniere/>

            <Footer/>
        </>
      )}
    </div>
  )
}

export default Restaurant