import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/img.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import { FaCheck } from "react-icons/fa6";
import { IoIosSend } from "react-icons/io";
import { LiaTelegramPlane } from "react-icons/lia";
import conseil from '../../../ASSETS/AfImmigration/personnes-taille-moyenne-dans-bureau-agence-voyages.png'
import iconeConseil from '../../../ASSETS/AfImmigration/customer-service.png'
import iconeRecommandation from '../../../ASSETS/AfImmigration/reputation.png'
import iconeAssistance from '../../../ASSETS/AfImmigration/social-care.png'
import { IoIosArrowDropright } from "react-icons/io";
import { IoPin } from "react-icons/io5";
import Assistance from '../../../ASSETS/AfImmigration/equipe-collegues-travaillant-documents-entreprise-faisant-travail-equipe-pour-analyser-documents-informations-recherche-ligne-planification-presentation-entrep.png'
import recommandation from '../../../ASSETS/AfImmigration/collegues-masculins-feminins-noirs-assis-dans-bureau-regardant-ensemble-ecran-ordinateur.png'
import benin from '../../../ASSETS/AfImmigration/000_32JN7YL.png'
import drapeauBenin from '../../../ASSETS/AfImmigration/drapeau-du-benin.png'
import CI from '../../../ASSETS/AfImmigration/participation-sitho.png'
import drapeauCI from '../../../ASSETS/AfImmigration/drapeau-cote-ivoire.png'
import zambie from '../../../ASSETS/AfImmigration/b39df0e9-299f-4a28-ae24-0323c2adbc35fcfff1ad1cd191d150_IMG_0239_Fotor.png'
import drapeauzambie from '../../../ASSETS/AfImmigration/drapeau-zambie.png'
import nigeria from '../../../ASSETS/AfImmigration/VIDEO-Lagos-State-unveils-Abiola-statue-to-mark-June-12-1.png'
import drapeaunigeria from '../../../ASSETS/AfImmigration/drapeau-du-nigeria.png'
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Faq from './Faq'
import filleImg from '../../../ASSETS/AfImmigration/image.png'
import Footer from '../../COMPONNENT/Footer/Footer';


function Home() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='h-screen w-screen bg-cover bg-center bg-no-repeat'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
            <Link to='/'>
                <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                    <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                </div>
            </Link>
            <BoutonLogin/>
            </div>

            <div className=' sm:pl-10 s:pl-4 sm:pt-10'>
                <div className='xs:text-4xl s:text-lg sm:text-2xl font-semibold text-[#233D4D] xs:w-[600px]'>
                    Conseils en matière d'immigration ou retour en Afrique
                </div>
                <div className='s:pt-4 sm:pt-10 flex flex-col space-y-4'>
                        <div className='flex flex-row items-center space-x-2'>
                            <FaCheck className='text-[#fe7f2d] text-2xl' />
                            <div className='xs:text-xl s:text-base sm:text-lg text-[#233D4D]'>Recommandation de pays le mieux adapté</div>
                        </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <FaCheck className='text-[#fe7f2d] text-2xl' />
                        <div className='xs:text-xl s:text-base sm:text-lg text-[#233D4D]'>Visa et titre de séjour</div>
                    </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <FaCheck className='text-[#fe7f2d] text-2xl' />
                        <div className='xs:text-xl s:text-base sm:text-lg text-[#233D4D]'>Création d'entreprise et de comptes bancaires</div>
                    </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <FaCheck className='text-[#fe7f2d] text-2xl' />
                        <div className='xs:text-xl s:text-base sm:text-lg text-[#233D4D]'>Facilitation de contact avec les acteurs locaux</div>
                    </div>
                    <div className='flex flex-row items-center space-x-2'>
                        <FaCheck className='text-[#fe7f2d] text-2xl' />
                        <div className='xs:text-xl s:text-base sm:text-lg text-[#233D4D]'>Visite d'exploration d'opportunités de business</div>
                    </div>
                </div>
                <Link to='/PrendreRendezVous'>
                    <button className='s:mt-8 sm:mt-12 bg-[#FCCA46] flex flex-row items-center space-x-2 text-white h-10 w-[200px]  px-2 transform active:scale-75 transition-transform'>
                        <p>Prendre rendez-vous</p>
                        <IoIosSend />
                    </button>
                </Link>
            </div>

        </div>

        <div className='flex flex-col space-y-4 items-center py-14'>
            <div className='flex flex-row items-center space-x-2 '>
                <LiaTelegramPlane className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Nos services</div>
            </div>
            <div className='px-2 text-lg xs:w-[700px] text-center'>
                Vivamus pretium turpis eget elit condimentum tincidunt. 
                Cras aliquam tincidunt est, in varius lorem laoreet in. 
                Sed quis ex rutrum, vehicula enim vitae, auctor urna. 
            </div>
            <div className='pt-10 place-content-center place-items-center grid md:grid-cols-3 gap-10 s:grid-cols-1 xs:grid-cols-2'>
                {/* Conseil */}
                <div className='bg-white md:w-[360px] sm:w-[300px] s:w-[260px]  h-[470px] shadow-lg rounded-t-2xl'>
                    {/* image */}
                    <img src={conseil} alt='conseil' className='md:w-[360px] sm:w-[300px] s:w-[260px] rounded-2xl'/>
                    {/* icone cercle */}
                    <div className='bg-[#233D4D] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                        <img src={iconeConseil} alt='iconeconseil' className='w-18 h-8'/>
                    </div>
                   <div className='relative bottom-8 left-5 flex flex-col space-y-3'>
                        {/* description */}
                        <div className='text-xl uppercase font-semibold text-[#233D4D]'>conseils</div>
                        <div className='md:w-[300px] sm:w-[260px] s:w-[230px] '>
                            Proin vel cursus nunc, vitae faucibus diam. Nam sapien neque, aliquet 
                            ac est et, finibus placerat dolor. Phasellus pharetra sed libero eget 
                            fermentum. Morbi tincidunt
                        </div>
                        <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                            {/* Voir plus */}
                            <Link to='/accueil_afrique_immigration/Conseil'>
                                <button className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                    <div>En savoir plus</div>
                                    <IoIosArrowDropright />
                                </button>
                            </Link>
                            <Link to='/PrendreRendezVous'>
                                <button className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                    Prendre rendez-vous
                                </button>
                            </Link>
                        </div>
                   </div>
                </div>
                {/* RECOMMANDATION */}
                <div className='bg-white md:w-[360px] sm:w-[300px] s:w-[260px]  h-[470px] shadow-lg rounded-t-2xl'>
                    {/* image */}
                    <img src={recommandation} alt='conseil' className='md:w-[360px] s:w-[260px] sm:w-[300px] rounded-2xl'/>
                    {/* icone cercle */}
                    <div className='bg-[#fe7f2d] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                        <img src={iconeRecommandation} alt='iconeconseil' className='w-18 h-8'/>
                    </div>
                   <div className='relative bottom-8 left-5 flex flex-col space-y-3'>
                        {/* description */}
                        <div className='text-xl uppercase font-semibold text-[#233D4D]'>RECOMMANDATION</div>
                        <div className='md:w-[300px] sm:w-[260px] s:w-[230px]'>
                            Proin vel cursus nunc, vitae faucibus diam. Nam sapien neque, aliquet 
                            ac est et, finibus placerat dolor. Phasellus pharetra sed libero eget 
                            fermentum. Morbi tincidunt
                        </div>
                        <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                            {/* Voir plus */}
                            <Link to='/accueil_afrique_immigration/Recommandation'>
                                <button className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                    <div>En savoir plus</div>
                                    <IoIosArrowDropright />
                                </button>
                            </Link>
                            <Link to='/PrendreRendezVous'>
                                <button className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 '>
                                    Prendre rendez-vous
                                </button>
                            </Link>
                        </div>
                   </div>
                </div>
                {/* ASSISTANCE */}
                <div className='bg-white md:w-[360px] sm:w-[300px] s:w-[260px]  h-[460px] shadow-lg rounded-t-2xl'>
                    {/* image */}
                    <img src={Assistance} alt='conseil' className='md:w-[360px] s:w-[260px] sm:w-[300px] rounded-2xl'/>
                    {/* icone cercle */}
                    <div className='bg-[#233D4D] rounded-full w-14 h-14 flex items-center justify-center relative bottom-8 md:left-72 s:left-48 sm:left-56'>
                        <img src={iconeAssistance} alt='iconeconseil' className='w-18 h-8'/>
                    </div>
                   <div className='relative bottom-8 left-5 flex flex-col space-y-3'>
                        {/* description */}
                        <div className='text-xl uppercase font-semibold text-[#233D4D]'>ASSISTANCE</div>
                        <div className='md:w-[300px] sm:w-[260px] s:w-[230px]'>
                            Proin vel cursus nunc, vitae faucibus diam. Nam sapien neque, aliquet 
                            ac est et, finibus placerat dolor. Phasellus pharetra sed libero eget 
                            fermentum. Morbi tincidunt
                        </div>
                        <div className='flex s:flex-col md:flex-row md:items-center s:space-y-3 md:space-y-0 md:space-x-4'>
                            {/* Voir plus */}
                            <Link to='/accueil_afrique_immigration/Assistance'>
                                <button className='flex items-center space-x-2 text-[#fe7f2d] text-lg transform active:scale-75 transition-transform '>
                                    <div>En savoir plus</div>
                                    <IoIosArrowDropright />
                                </button>
                            </Link>
                            <Link to='/PrendreRendezVous'>
                                <button className='bg-[#233D4D] text-white h-8 w-[170px] rounded hover:bg-white hover:text-[#233D4D] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700'>
                                    Prendre rendez-vous
                                </button>
                            </Link>
                        </div>
                   </div>
                </div>
            </div>
        </div>

        <div className='bg-white py-14 flex flex-col space-y-4 items-center'>
            <div className='flex flex-row items-center space-x-2 '>
                <IoPin className='text-2xl text-[#fe7f2d]' />
                <div className='text-2xl font-semibold text-[#233D4D]'>Votre destination</div>
            </div>
            <div className='text-lg xs:w-[700px] text-center px-2'>
                Vivamus pretium turpis eget elit condimentum tincidunt. 
                Cras aliquam tincidunt est, in varius lorem laoreet in. 
                Sed quis ex rutrum, vehicula enim vitae, auctor urna. 
            </div>
            <div className='pt-10 place-content-center place-items-center grid md:grid-cols-4 gap-4 s:grid-cols-1 xs:grid-cols-2'>
               {/* BENIN */}
               <div className='example flex flex-col items-center'>
                    <img src={benin} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">BENIN</div>
                    </div>
                    <img src={drapeauBenin} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                </div>
                {/* COTE D IVOIRE */}
                <Link to='/accueil_afrique_immigration/Destination/VoirPlus'>
                    <div className='example flex flex-col items-center'>
                        <img src={CI} alt='CI' className='sm:w-[290px] s:w-[260px]'/>
                        <div className="fadedbox">
                            <div className="title text">Côte d'ivoire</div>
                        </div>
                        <img src={drapeauCI} alt='drapeauCI' className='w-[60px] relative bottom-7'/>
                    </div>
                </Link>
                {/* ZAMBIE */}
                <div className='example flex flex-col items-center'>
                    <img src={zambie} alt='zambie' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">ZAMBIE</div>
                    </div>
                    <img src={drapeauzambie} alt='drapeauzambie' className='w-[60px] relative bottom-7'/>
                </div>
                {/* NIGERIA */}
                <div className='example flex flex-col items-center'>
                    <img src={nigeria} alt='benin' className='sm:w-[290px] s:w-[260px]'/>
                    <div className="fadedbox">
                        <div className="title text">NIGERIA</div>
                    </div>
                    <img src={drapeaunigeria} alt='drapeaubenin' className='w-[60px] relative bottom-7'/>
                </div>
            </div>

            <Link to='/accueil_afrique_immigration/Destination'>
                <div className='flex flex-col items-center text-[#233D4D] space-y-3 text-xl'>
                    <button>Tout les pays</button>
                    <HiOutlineChevronDoubleDown />
                </div>
            </Link>
        </div>

        <div className='flex md:flex-row s:flex-col s:space-y-10 md:space-y-0 s:items-center md:justify-center md:space-x-[200px] py-14'>

            <div className='flex flex-col space-y-6'>
               <div className='text-[#233D4D] font-semibold s:text-2xl sm:text-3xl  xs:text-4xl  s:w-[260px]  sm:w-[300px] xs:w-[400px]'>Questions fréquemment posées</div>
               <div className='xs:w-[400px] s:w-[260px]  sm:w-[300px] text-lg text-[#4E4E4E]'>
                At the heart of our commitment to providing exceptional immigration solutions stands our trusted
                </div>
                <img src={filleImg} alt='fille' className='xs:w-[400px] s:w-[260px]  sm:w-[300px]'/>
            </div>

            <div className='xs:w-[500px] s:w-[260px]  sm:w-[300px]'>
                <Faq/>
            </div>
        </div>
        <Footer/>
        </>
          )}
    </div>
  )
}

export default Home