import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { MdOutlinePool } from "react-icons/md";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { GoArrowLeft } from "react-icons/go";
import SidebarTop from '../../SidebarTop';
import { IoMdPin } from "react-icons/io";

import {
    Card,
    CardHeader,
    CardBody,
    Typography
} from "@material-tailwind/react";
import { LiaCocktailSolid } from "react-icons/lia"
import { WiSnowflakeCold } from "react-icons/wi";
import { LiaUmbrellaBeachSolid } from "react-icons/lia";
import { MdOutlineWifi } from "react-icons/md";
import { SlScreenDesktop } from "react-icons/sl";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import { IoIosEyeOff } from "react-icons/io";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import img1 from "../../../../ASSETS/tourisme/Image/voiture2.jpg";
import img2 from "../../../../ASSETS/tourisme/Image/voiture3.jpg";
import img3 from "../../../../ASSETS/tourisme/Image/Voiture.jpg";
import Carousel from '../Carousel';


function VoirPlus() {

    const slides = [img1,img2,img3];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);


    return (
        <div className='flex flex-row bg-[#eeee]'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
            ) : (
                <>

                    <div className='w-screen  barre_scroll h-screen'>
                        {/* navbar top orange */}
                        <SidebarTop />
                        {/* navbar top gris*/}
                        <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
                            <Breadcrumbs>
                                <Link to="" className="opacity-60 md:text-base s:text-xs">
                                    Mes publications
                                </Link>
                                <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants" className="opacity-60 md:text-base s:text-xs">
                                    Restaurant
                                </Link>
                                <Link to="" className='md:text-base s:text-xs'>
                                   Voir
                                </Link>
                            </Breadcrumbs>
                        </div>

                        <Link to="/Dashboard/Publications/Restaurant/Voir_des_restaurants">
                            <div className='flex flex-row items-center space-x-2 relative xs:top-8 s:top-4 s:left-5 sm:left-12 w-[280px]'>
                                <GoArrowLeft className='text-[#fe7f2d]  text-lg' />
                                <p>Retour à la page précedente</p>
                            </div>
                        </Link>
                       
                        {/* container */}

                         <div className='flex justify-center md:p-10 s:mt-4 s:p-4'>

                            <Card className="h-full xs:w-[700px] s:w-[260px] sm:w-[340px]">
                                <CardHeader floated={false} shadow={false} className="rounded-none pl-4 flex items-center justify-between ">
                                    <Typography className='sm:text-lg s:text-base font-semibold text-[#fe7f2d]' color="blue-gray">
                                        Nom de du restaurant
                                    </Typography>
                                    <Tooltip  content="Action sur la publication">
                                    <IconButton variant="text">
                                        <Menu>
                                        <MenuHandler>
                                            <IconButton variant="text">
                                            <RxDotsVertical className='text-lg' />
                                            </IconButton>
                                        </MenuHandler>
                                        <MenuList className="flex flex-col gap-2">

                                            {/* modifier */}
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <FiEdit className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Modifier
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                            {/* Desactiver */}
                                            <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <IoIosEyeOff className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                                <Typography variant="small" color="gray" className="font-semibold">
                                                Désactiver
                                                </Typography>
                                            </div>
                                            </MenuItem>
                                        </MenuList>
                                        </Menu>
                                    </IconButton>
                                </Tooltip>

                                </CardHeader>
                                <CardBody className=" xs:px-8 s:px-6 pb-20">
                                   <div className='flex justify-center'>
                                        <div className="md:w-[500px]  xs:w-[400px] sm:w-[300px] s:w-[260px] ">
                                            <Carousel slides={slides}  />
                                        </div>
                                   </div>
                                    <div className='flex xs:flex-row s:flex-col xs:justify-between py-3'>
                                        <p className='font-semibold text-[#233D4D]  xs:text-lg s:text-sm '>Restaurant La Rose Noire</p>
                                        <div className='flex items-center space-x-2'>
                                        <p className=' font-thin text-gray-400 s:text-sm xs:text-base'>À partir de </p>
                                        <p className=' font-medium text-[#233D4D] xs:text-xl s:text-sm'> 10.000 XOF</p> 
                                        <p className=' font-thin text-gray-400 s:text-sm xs:text-base'>les plats</p></div>
                                    </div>
                                    <div className='flex sm:flex-row s:flex-col justify-between pt-2'>
                                        <div className='flex space-x-2 items-center '>
                                        <IoMdPin className=' text-[#fe7f2d] ' />
                                        <p className='s:text-sm xs:text-base'>Cocody, Abidjan Côte d'ivoire</p>
                                        </div>
                                        <div className='bg-[#56b256] h-8 w-[100px] text-white rounded font-thin justify-center items-center flex'>
                                            Ouvert
                                        </div>
                                    </div>

                                    <div className='pt-6 grid xs:grid-cols-3 s:grid-cols-1 sm:grid-cols-2  gap-y-4'>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlineWifi className='text-xl text-[#fe7f2d] ' />
                                            <p>Wifi : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <MdOutlinePool className='text-xl text-[#fe7f2d]' />
                                            <p>Piscine : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <SlScreenDesktop className='text-xl text-[#fe7f2d]' />
                                            <p>TV : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <LiaCocktailSolid className='text-xl text-[#fe7f2d]' />
                                            <p>Mini Bar : Non</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <LiaUmbrellaBeachSolid className='text-xl text-[#fe7f2d]' />
                                            <p>Terrasse : Oui</p>
                                        </div>
                                        <div className='flex items-center space-x-2'>
                                            <WiSnowflakeCold className='text-xl text-[#fe7f2d]' />
                                            <p>Climatiseur : Non</p>
                                        </div>
                                      
                                        
                                         
                                    </div>

                                    <div className='pt-6'>
                                        <p className='text-lg  font-medium text-[#4e4e4e]'>Description</p>
                                        <p className='xs:w-[600px] s:w-[210px] sm:w-[300px] pt-3'> 
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae 
                                            pulvinar tellus, a consectetur enim. Suspendisse non metus tempus, 
                                            cursus ante semper, viverra leo. Praesent eget urna et erat volutpat 
                                            finibus. Curabitur ut convallis ex, pretium mattis orci. Ut nec lorem 
                                            vitae turpis posuere fringilla. Nunc maximus vitae libero in porttitor. 
                                            Nullam interdum diam sit amet ipsum sagittis dignissim. Vestibulum feugiat 
                                            cursus mi, et consectetur risus sollicitudin eu. Sed porttitor turpis ut erat 
                                            aliquam, congue auctor nibh congue. Praesent et feugiat massa. Pellentesque 
                                            habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                        </p>
                                    </div>
                                </CardBody>
                               
                            </Card>

                        </div>
                       
                    </div>
                </>
            )}
        </div>
    )
}

export default VoirPlus