import React from 'react'
import BackImage from '../BackImage'
import Footer from '../../../COMPONENTS/Footer/Footer'
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import img1 from "../../../../ASSETS/tourisme/Image/voiture2.jpg";
import img2 from "../../../../ASSETS/tourisme/Image/voiture3.jpg";
import img3 from "../../../../ASSETS/tourisme/Image/Voiture.jpg";
import Carousel from '../../LVoiture/Carousel';
import { CiHeart } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { SlBasket } from "react-icons/sl";
import Commentaire from './Commentaire';
import Suggestion from '../Suggestion'
import BarniereLvoiture from '../BarniereLvoiture';
import {Link} from 'react-router-dom'

function VoirPlusVoiture() {

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };
    

    const slides = [img1,img2,img3];
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div>
         {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
            ) : (
        <>
            <BackImage/>

                {/* Container1 */}
                <div className='flex xs:flex-row md:space-x-20 xs:space-x-8 s:flex-col-reverse  justify-center xs:items-start s:items-center my-10'>
                    {/* Caroussel */}
                    <div className="relative flex flex-col ">
                        <div className="md:w-[500px] xs:w-[400px] sm:w-[300px] s:w-[260px] ">
                            <Carousel slides={slides} />
                        </div>
                        {/* Bouton ajout au panier et commander */}
                        <div className='flex md:flex-row s:flex-col s:space-y-4 md:space-y-0 mt-10 justify-between'>

                            <button className='bg-[#FE7F2D] px-5 w-[200px]  text-white h-10 rounded flex flex-row space-x-2 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#FE7F2D] '>
                                <SlBasket />
                                <p> Ajouter au panier</p>
                            </button>

                            <Link to='/Reservation_Vehicule'>
                                <button className='bg-[#FE7F2D] px-5 w-[200px] text-white h-10 rounded flex flex-row space-x-2 items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#FE7F2D] '>
                                    <SlBasket />
                                    <p> Reserver</p>
                                </button>
                            </Link>
                        </div>
                        {/* Commentaire */}
                        <div className='mt-10'>
                            <Commentaire/>  
                        </div>
                    </div>

                    {/* description */}
                    <div className='flex flex-col space-y-2'>
                        <div>Abidjan</div>
                        <div className='text-xl font-semibold text-[#FE7F2D]'>Nom du vehicule</div>
                        <div className='flex items-center space-x-2 '>
                           <CiHeart className='text-4xl' />
                            <div>104 j'aimes</div>
                        </div>
                        <div className='md:w-[500px] xs:w-[300px] s:w-[260px] sm:w-[320px]'>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                sed do eiusmod tempor incididunt ut labore et dolore magna 
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                Duis aute irure dolor in reprehenderit in voluptate velit 
                                esse cillum dolore eu fugiat nulla pariatur.
                            </p>

                        {isShowMore && (
                          <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                                sed do eiusmod tempor incididunt ut labore et dolore magna 
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                                ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                                Duis aute irure dolor in reprehenderit in voluptate velit 
                                esse cillum dolore eu fugiat nulla pariatur.
                            </p>
                        )}
                        <button className='text-[#FE7F2D] flex flex-row space-x-3 items-center' onClick={toggleReadMoreLess}>
                        {isShowMore ? "Lire moins" : "Lire plus"}
                        <IoIosArrowRoundForward className="text-lg " />
                        </button>
                        </div>
                         <div className='flex flex-row items-center space-x-4'>
                            {/* essence */}
                            <div className='flex space-x-3 items-center'>
                              <svg id="essence" xmlns="http://www.w3.org/2000/svg" width="11.337" height="13.604" viewBox="0 0 11.337 13.604">
                                <path id="Tracé_45" data-name="Tracé 45" d="M53.92,1.784,52.786.65a.283.283,0,0,0-.4.4l.933.933-.933.933a.283.283,0,0,0-.083.2v.85A1.135,1.135,0,0,0,53.436,5.1V9.92a.283.283,0,1,1-.567,0V9.353a.851.851,0,0,0-.85-.85h-.283V1.134A1.135,1.135,0,0,0,50.6,0H44.933A1.135,1.135,0,0,0,43.8,1.134v10.2a1.135,1.135,0,0,0-1.134,1.134v.85a.283.283,0,0,0,.283.283h9.636a.283.283,0,0,0,.283-.283v-.85a1.135,1.135,0,0,0-1.134-1.134V9.07h.283a.284.284,0,0,1,.283.283V9.92a.85.85,0,1,0,1.7,0V1.984a.283.283,0,0,0-.083-.2ZM50.6,4.823a.283.283,0,0,1-.283.283h-5.1a.283.283,0,0,1-.283-.283V1.417a.283.283,0,0,1,.283-.283h5.1a.283.283,0,0,1,.283.283Z" transform="translate(-42.666)" fill="#4e4e4e" />
                              </svg>
                              <p>Essence</p>
                            </div>
                            {/* place */}
                            <div className='flex space-x-3 items-center'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="13.426" height="13.426" viewBox="0 0 13.426 13.426">
                                <g id="user" transform="translate(0 0)">
                                  <path id="Tracé_46" data-name="Tracé 46" d="M3.635,3.078A3.078,3.078,0,1,1,6.713,6.156,3.081,3.081,0,0,1,3.635,3.078ZM6.713,6.651A6.733,6.733,0,0,1,13.426,12.7a30.493,30.493,0,0,1-6.713.726A30.491,30.491,0,0,1,0,12.7,6.733,6.733,0,0,1,6.713,6.651Z" fill="#4e4e4e" fillRule="evenodd" />
                                </g>
                              </svg>
                              <p>5</p>
                            </div>
                             {/* climatiseur */}
                            <div className='flex space-x-3 items-center'>
                              <svg id="clim" xmlns="http://www.w3.org/2000/svg" width="19.217" height="21.439" viewBox="0 0 19.217 21.439">
                              <path id="Tracé_47" data-name="Tracé 47" d="M33.656,9.512v2.415l1.925.962,1.925-.962V9.512L35.58,8.549Zm1.283-2.076.642-.321.642.321V5.852L38.6,3.474l-.907-.907L36.222,4.038V2l1.1-1.1L36.41,0l-.83.83L34.751,0l-.907.907,1.1,1.1V4.038L33.468,2.566l-.907.907,2.378,2.378Zm-8.692-.594L27.76,6.51l1.713,1.1-2.032.445.274,1.254L31,8.587l1.372.879V8.719l.681-.34-1.36-.871-.719-3.286L29.72,4.5l.445,2.032-1.713-1.1-.331-1.513-1.253.274.251,1.146-1.146.251ZM38.788,8.719v.745l1.368-.888,3.29.7.267-1.255-2.035-.433,1.707-1.108L44.9,6.8l.267-1.255L44.02,5.3l.244-1.147-1.255-.267L42.687,5.4,40.98,6.511l.433-2.035-1.255-.267-.7,3.29-1.353.878Zm6.4,7.132L44.914,14.6l-1.513.331-1.713-1.1,2.032-.445-.274-1.254-3.286.719-1.372-.879v.747l-.681.34,1.36.871.719,3.286,1.254-.274L41,14.911l1.713,1.1.331,1.513,1.254-.274L44.043,16.1ZM36.222,14l-.642.321L34.939,14v1.583L32.56,17.965l.907.907L34.939,17.4v2.035l-1.1,1.1.907.907.83-.83.83.83.907-.907-1.1-1.1V17.4l1.471,1.471.907-.907-2.379-2.378Zm-3.85-1.283v-.745L31,12.863l-3.29-.7-.267,1.255,2.035.433-1.707,1.108-1.515-.322-.267,1.255,1.147.244L26.9,17.283l1.255.267.322-1.515,1.707-1.108-.433,2.035L31,17.229l.7-3.29,1.353-.878Z" transform="translate(-25.972)" fill="#4e4e4e"/>
                              </svg>
                              <p>Climatiseur</p>
                            </div>
                        </div>
                        <hr className='sm:pb-1 lg:pb-0'/>
                        {/* prix */}
                        <div className='flex items-center space-x-2  py-4 '>
                            <GiMoneyStack className='text-xl' />
                            <p className='sm:text-xs md:text-xl'> Prix : 120.000 FCFA / jour</p>
                        </div>
                    </div>
                </div>

                <Suggestion/>

                <BarniereLvoiture/>


            <Footer/>

        </>
          )}
    </div>
  )
}

export default VoirPlusVoiture