import React from 'react'
import Header from '../../COMPONNENTS/Header/Header'
import immobilier from '../../../ASSETS/CaImmigration/images/Bien_immobilier_barniere.png'
import Footer from '../../COMPONNENTS/Footer/Footer'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import BienImmobilier1 from '../../../ASSETS/CaImmigration/images/Bien1.png'
import BienImmobilier2 from '../../../ASSETS/CaImmigration/images/bien2.png'
import BienImmobilier3 from '../../../ASSETS/CaImmigration/images/bien3.png'
import lieu from '../../../ASSETS/CaImmigration/icones/pin.png'
import homme from '../../../ASSETS/CaImmigration/images/homme_bien.png'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard'
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'

function BienImmobilier() {
    let [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
            <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${immobilier})` }}>
               {/* Navbar */}
                <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                                    <div>
                                    <img className='w-6' src={home} alt='' />
                                    </div>
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                                    <ul className='z-10  '>
                                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaVisiteur'> Visa visiteur </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" ,color:"#FCCA46"}} className='flex space-x-2 items-center  s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>
              <div className='text_home flex justify-end md:pl-10 s:pl-5  s:pt-20 md:pt-0 s:mt-20 xs:mt-40 lg:mt-44'>
                  <div className='flex pt-20  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg    font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>ACHAT DE BIEN IMMOBILIER</p>
                  </div>
              </div>

          </div>

          {/* option */}
         <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* Bloc */}
          <div className='py-20 border-t-2'>
            {/* texte */}
           <p style={{color:"#4e4e4e"}} className='text-3xl font-bold text-center'>Achat de bien immobilier au Canada</p>
           <div className='flex justify-center mt-8'>
              <p className='md:w-[800px] xs:w-[700px] s:w-[260px] sm:w-[330px] text-center text-lg'>
                Découvrez votre chez-vous idéal avec notre sélection exclusive de biens immobiliers. Parcourez des propriétés exceptionnelles, trouvez votre lieu de rêve et faites de chaque maison une histoire unique
              </p>
           </div>

           {/* Bien immobilier ligne1 */}
           <div className='grid md:grid-cols-4 s:grid-cols-1 xs:grid-cols-2 gap-12 px-8 mt-20 place-items-center'>
            {/* Bien 1 */}
            <div className="max-w-sm bg-white border w-[300px] h-[500px] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img className="rounded-t-lg w-[300px] h-[220px] " src={BienImmobilier1} alt="bienimmobilier" />
                <div  className=' p-3 space-y-4 '>
                    <p style={{color:"#FE7F2D"}} className='text-xl font-semibold'>Villa haut stading</p>
                    <p className='w-[220px] text-justify'>suere solPorta nibh venenatis crasmetus aliquam eleifend mi</p>
                    <div className='flex items-center space-x-3'>
                        <img className='w-6' src={lieu} alt='icone'/>
                        <p>Quebec, Canada</p>
                    </div>
                    <p style={{color:"#233D4D"}} className='text-end font-bold text-xl'>106.000 $CAD</p>

                    <Link to='VoirBienImmobilier'>
                    <button style={{backgroundColor:"#FE7F2D"}} className='text-white   px-4 h-10 font-medium transform active:scale-75 transition-transform '>Voir le bien</button>
                    </Link>
                </div>
            </div>

            {/* Bien 2 */}
            <div className="max-w-sm bg-white border w-[300px] h-[500px] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img className="rounded-t-lg w-[300px] h-[220px]" src={BienImmobilier2} alt="bienimmobilier" />
                <div className=' p-3 space-y-4 '>
                    <p style={{color:"#FE7F2D"}} className='text-xl font-semibold'>Villa haut stading</p>
                    <p className='w-[220px] text-justify'>suere solPorta nibh venenatis crasmetus aliquam eleifend mi</p>
                    <div className='flex items-center space-x-3'>
                        <img className='w-6' src={lieu} alt='icone'/>
                        <p>Quebec, Canada</p>
                    </div>
                    <p style={{color:"#233D4D"}} className='text-end font-bold text-xl'>106.000 $CAD</p>
                    <button style={{backgroundColor:"#FE7F2D"}} className='text-white   px-4 h-10 font-medium transform active:scale-75 transition-transform '>Voir le bien</button>
                </div>
            </div>

            {/* Bien 3 */}
            <div className="max-w-sm bg-white border w-[300px] h-[500px] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img className="rounded-t-lg w-[300px] h-[220px]" src={BienImmobilier3} alt="bienimmobilier" />
                    <div className="p-5 space-y-4">
                        <p style={{color:"#FE7F2D"}} className='text-xl font-semibold'>Villa haut stading</p>
                        <p className='w-[220px] text-justify'>suere solPorta nibh venenatis crasmetus aliquam eleifend mi</p>
                        <div className='flex items-center space-x-3'>
                            <img className='w-6' src={lieu} alt='icone'/>
                            <p>Quebec, Canada</p>
                        </div>
                        <p style={{color:"#233D4D"}} className='text-end font-bold text-xl'>106.000 $CAD</p>
                        <button style={{backgroundColor:"#FE7F2D"}} className='text-white   px-4 h-10 font-medium transform active:scale-75 transition-transform '>Voir le bien</button>
                    </div>
               
            </div>

            
            {/* Bien 1 */}
            <div className="max-w-sm bg-white border w-[300px] h-[500px] border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img className="rounded-t-lg w-[300px] h-[220px]" src={BienImmobilier1} alt="bienimmobilier" />
                <div  className=' p-3 space-y-4 '>
                    <p style={{color:"#FE7F2D"}} className='text-xl font-semibold'>Villa haut stading</p>
                    <p className='w-[220px] text-justify'>suere solPorta nibh venenatis crasmetus aliquam eleifend mi</p>
                    <div className='flex items-center space-x-3'>
                        <img className='w-6' src={lieu} alt='icone'/>
                        <p>Quebec, Canada</p>
                    </div>
                    <p style={{color:"#233D4D"}} className='text-end font-bold text-xl'>106.000 $CAD</p>

                    <Link to='VoirBienImmobilier'>
                    <button style={{backgroundColor:"#FE7F2D"}} className='text-white   px-4 h-10 font-medium transform active:scale-75 transition-transform '>Voir le bien</button>
                    </Link>
                </div>
            </div>

           </div>

           {/* boutton voir plus */}
           <div className='flex justify-center'>
            <button style={{backgroundColor:"#233D4D"}} className=' mt-20 text-lg w-56 text-white h-14 transform active:scale-75 transition-transform'>Voir tous les biens</button>
           </div>

           

           <div style={{backgroundColor:"#4e4e4e"}} className=' pt-5 flex xs:flex-row s:flex-col-reverse md:space-x-32 items-center mt-20 '>
            <div>
                <img className='w-[450px]' src={homme} alt='homme'/>
            </div>
            <div className='space-y-8 pb-5 '>
                <p className='md:text-3xl xs:text-2xl s:text-xl font-bold text-white s:w-[260px] sm:w-[300px] xs:w-[400px] md:w-[500px]'>Obtenez votre maison plus facilement avec Mon Bon Sejour</p>
                <p className='text-justify xs:w-[360px] md:w-[450px] s:w-[260px] sm:w-[300px] text-xl text-white'>Explorez une gamme exceptionnelle de biens immobiliers sur notre site. De maisons élégantes à des appartements modernes, trouvez la propriété parfaite qui correspond à vos besoins. Avec notre équipe dévouée, chaque étape de votre parcours immobilier est facilitée.</p>
                <Link to="/PrendreRendezVous"><button className=' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-150  border-2 border-white text-white xs:w-36 xs:h-12 s:w-28 s:h-10 s:text-sm xs:text-base font-medium hover:bg-white hover:text-orange-400 mt-10'>Prendre RDV</button></Link>
            </div>
           </div>

          </div>
        
          <Footer />
              </>
          )}
    </div>
  )
}

export default BienImmobilier