
import React, { useState } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import {  useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import Navbar from './Navbar'
import test from '../../../ASSETS/CaImmigration/images/eligibilite_barniere.png'
import { ToastContainer, toast } from "react-toastify"
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
import TestEl from "../../../API/immigration/eligibilite/Test"

const MultiStepForm = () => {

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
      });

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const [selectedAnte, setSelectedAnte] = useState({
        types: [],
        response: [],
    });

    const [selectedComp, setSelectedComp] = useState({
        types: [],
        response: [],
    });

    const [selectedDoc, setSelectedDoc] = useState({
        types: [],
        response: [],
    });

    const handleChangeAnte = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { types } = selectedAnte;

        //console.log(`${value} is ${checked}`);
 
        // Case 1 : The user checks the box
        if (checked) {
            setSelectedAnte({
                types: [...types, value],
                response: [...types, value],
            });
        }
 
        // Case 2  : The user unchecks the box
        else {
            setSelectedAnte({
                types: types.filter(
                    (e) => e !== value
                ),
                response: types.filter(
                    (e) => e !== value
                ),
            });
        }
    };

    const handleChangeComp = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { types } = selectedComp;
 
        //console.log(`${value} is ${checked}`);
 
        // Case 1 : The user checks the box
        if (checked) {
            setSelectedComp({
                types: [...types, value],
                response: [...types, value],
            });
        }
 
        // Case 2  : The user unchecks the box
        else {
            setSelectedComp({
                types: types.filter(
                    (e) => e !== value
                ),
                response: types.filter(
                    (e) => e !== value
                ),
            });
        }
    };

    const handleChangeDoc = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { types } = selectedDoc;
 
        //console.log(`${value} is ${checked}`);
 
        // Case 1 : The user checks the box
        if (checked) {
            setSelectedDoc({
                types: [...types, value],
                response: [...types, value],
            });
        }
 
        // Case 2  : The user unchecks the box
        else {
            setSelectedDoc({
                types: types.filter(
                    (e) => e !== value
                ),
                response: types.filter(
                    (e) => e !== value
                ),
            });
        }
    };

    const [files, setFiles] = React.useState([]);

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const packFiles = (files)=> {
        const data = new FormData();

        [...files].forEach((file, i) => {
            data.append(`file-${i}`, file, file.name)
        })
        return data
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlefile = e => {
        this.setSelectedFile1({ ...this.selectedFile1, [e.target.name]: e.target.files[0] });
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(formData);
        var fd1 = new FormData();
        var fd2 = new FormData();
        var fd = new FormData();

        // if (files.length) {
        //     const data = packFiles(files)

        //       setLoading(true)
        //   TestEl.submitForm(files)
        //       .then((res) => {
        //         if(res.data.status === 201){
        //           toast.success(res.data.message);
        //           console.log(res);
        //         }else{
        //           toast.error(res.data.message);
        //           console.log(res);
        //         }
        //       })
        //       .catch((err) => {
        //           toast.error('Erreur merci de reessayer !');
        //           console.log(err);
        //       })
        //       .finally(() => {
        //         setLoading(false);
        //       })
        // }
        // if(selectedFile1){
        //     for(let i=0; i<selectedFile1.length; i++){
        //         fd1.append(`DocAntecedent${i+1}`, selectedFile1[i]);
        //     }
        // }
        if(selectedFile2){
            for(let i=0; i<selectedFile2.length; i++){
                fd2.append(`ExtraitNaissance${i+1}`, selectedFile2[i]);
            }
        }
        if(selectedPhoto){
                fd.append(`photo`, selectedPhoto);
        }
        var templateParams = {
            nom: formData.name,
            pnom: formData.prenom,
            sexe: formData.sexe,
            age: formData.age,
            nationalite: formData.Nationalite,
            country: formData.PaysResidence,
            city: formData.ville,
            mail: formData.email,
            phone: formData.NumeroPortable,
            type_visa: formData.TypeVisa,
            conjoint: formData.conjoint,
            nb_enfant: formData.NombreEnfant,
            niveau_etude: formData.NiveauEtude,
            domaine_etude: formData.discipline,
            situation: formData.TravaillezVous,
            nb_experience: formData.NombreExperience,
            antecedent: selectedAnte.response.toString(),
            passport: formData.passport,
            competence_langue: selectedComp.response.toString(),
            etat_civile: selectedDoc.response.toString(),
            casier_judiciaire: formData.casierJudiciaire,
            customers: data !== null ? data.userId : "",
            doc_civile: fd2,
            doc_antecedent: selectedFile1,
            photo: fd,
          };
          console.log(templateParams);
          setLoading(true)
          TestEl.submitForm(templateParams)
              .then((res) => {
                if(res.data.status === 201){
                  toast.success(res.data.message);
                  console.log(res);
                }else{
                  toast.error(res.data.message);
                  console.log(res);
                }
              })
              .catch((err) => {
                  toast.error('Erreur merci de reessayer !');
                  console.log(err);
              })
              .finally(() => {
                setStep(1);
                setLoading(false);
              })
    };

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{ backgroundColor: "#f7f7f7" }} className='flex flex-col items-center'>
            {loading ? (
                // Afficher le spinner tant que loading est true
                <Loading />
                        ) : (
                <>
                            
                <Header />

                <div>
        <ToastContainer />
      </div>

                {/* back image */}
                <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${test})` }}>
                <Navbar />
                <div className='text_home flex justify-end pr-10 md:pl-20 s:pl-5  s:pt-20 md:pt-0 mt-20'>
                    <div className='flex pt-20 text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                        <p>TESTER VOTRE ÉLIGIBILITÉ AU VISA</p>
                    </div>

                </div>

                </div>

                {/* option */}
                <OptionCard />

                {/* en tete */}
                <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
                <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
                <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
                </div>
                <Form onSubmit={handleSubmit}>
                <ProgressBar now={(step / 3) * 100} />
                {step === 1 && (
                    <Form.Group style={{ backgroundColor: "#f3f3f3" }} className=' md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center xs:px-28 s:px-5' controlId="formStep1">

                        <p style={{ color: "#FE7F2D" }} className='text-2xl font-semibold'>Teste d'égilibilité au visa et permis detudes</p>
                        <p className='xs:w-[600px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez repondre a toutes les questions, a la fin du questionnaire vous aurrez le resultat qui déterminera votre éligibilité</p>

                        <p className='text-2xl  font-semibold'>Informations personnelles</p>
                        {/* Nom */}
                        <Form.Label className='text-lg font-semibold'>Nom <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />

                        {/* Prenom */}
                        <Form.Label className='text-lg font-semibold'>Prénom <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleInputChange}
                        />
                        {/* Sexe */}
                        <Form.Label className='text-lg font-semibold'>Sexe <span className='text-red-600'>*</span></Form.Label>
                        <div className='flex items-center space-x-4'>
                            {/* feminin */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='femme'
                                    name="sexe"
                                    value="femme"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>F</Form.Label>
                            </div>
                            {/* Masculin */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='homme'
                                    name="sexe"
                                    value="homme"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>M</Form.Label>
                            </div>
                            {/* Autres */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='autre'
                                    name="sexe"
                                    value="autre"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Autre</Form.Label>
                            </div>
                        </div>

                        {/* Age */}
                        <Form.Label className='text-lg font-semibold'>Age <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="number"
                            name="age"
                            value={formData.age}
                            onChange={handleInputChange}
                        />
                        {/* Nationalité */}
                        <Form.Label className='text-lg font-semibold'>Nationalité <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="Nationalite"
                            value={formData.Nationalite}
                            onChange={handleInputChange}
                        />
                        {/* Pays de residence */}
                        <Form.Label className='text-lg font-semibold'>Pays de residence <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="PaysResidence"
                            value={formData.PaysResidence}
                            onChange={handleInputChange}
                        />
                        {/* Ville de residence */}
                        <Form.Label className='text-lg font-semibold'>Ville de residence <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="ville"
                            value={formData.ville}
                            onChange={handleInputChange}
                        />
                        {/* Email */}
                        <Form.Label className='text-lg font-semibold'>Email <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="email"
                            name="email"
                            value={formData.mail}
                            onChange={handleInputChange}
                        />
                        {/* Numero de portable*/}
                        <Form.Label className='text-lg font-semibold'>Numero de portable <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="tel"
                            name="NumeroPortable"
                            value={formData.NumeroPortable}
                            onChange={handleInputChange}
                        />

                    </Form.Group>
                )}
                {step === 2 && (
                    <Form.Group style={{ backgroundColor: "#f3f3f3" }} className=' md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center xs:px-28 s:px-5' controlId="formStep2">

                        <p style={{ color: "#FE7F2D" }} className='text-2xl font-semibold'>Teste d'égilibilité au visa et permis detudes</p>
                        <p className='xs:w-[600px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez repondre a toutes les questions, a la fin du questionnaire vous aurrez le resultat qui déterminera votre éligibilité</p>
                        <p className='text-2xl font-semibold'>Autres informations</p>

                        {/* Type de visa */}
                        <Form.Label className='text-lg font-semibold'>Type de visa <span className='text-red-600'>*</span></Form.Label>
                        <div className='grid xs:grid-cols-5 s:grid-cols-3 gap-4 '>
                            {/* Visiteur */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='visiteur'
                                    name="TypeVisa"
                                    value="visiteur"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Visiteur</Form.Label>
                            </div>
                            {/* Etudiant */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='etudiant'
                                    name="TypeVisa"
                                    value="etudiant"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Etudiant</Form.Label>
                            </div>
                            {/* Travail */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='Travail'
                                    name="TypeVisa"
                                    value="Travail"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Travail</Form.Label>
                            </div>
                            {/* Familiale */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='Familiale'
                                    name="TypeVisa"
                                    value="Familiale"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Familiale</Form.Label>
                            </div>
                            {/* Transit */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='Transit'
                                    name="TypeVisa"
                                    value="Transit"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Transit</Form.Label>
                            </div>
                            {/* Touriste */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='Touriste'
                                    name="TypeVisa"
                                    value="Touriste"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Touriste</Form.Label>
                            </div>
                        </div>
                        {/* conjoint */}
                        <Form.Label className='text-lg font-semibold'>Avez-vous un conjoint(e) ou un époux (se) qui vous accompagne au Canada <span className='text-red-600'>*</span></Form.Label>
                        <div className='flex items-center space-x-4'>
                            {/* OUI */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='oui'
                                    name="conjoint"
                                    value="oui"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Oui</Form.Label>
                            </div>
                            {/*NON*/}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='non'
                                    name="conjoint"
                                    value="non"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Non</Form.Label>
                            </div>
                        </div>
                        {/* nombre d'enfant*/}
                        <Form.Label className='text-lg font-semibold'>Nombre d'enfant <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="number"
                            name="NombreEnfant"
                            value={formData.NombreEnfant}
                            onChange={handleInputChange}
                        />
                        {/* Niveau d'etude*/}
                        <Form.Label className='text-lg font-semibold'>Niveau d'etude <span className='text-red-600'>*</span></Form.Label>
                        <select style={{ backgroundColor: "white" }} name="NiveauEtude" onChange={handleInputChange} className=' px-4 border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'>
                            <option >Sélectionner le niveau d'étude</option>
                            <option value="Bepc">Bepc</option>
                            <option value="Bac">Baccalauréat</option>
                            <option value="Licence">Licence</option>
                            <option value="Master">Master</option>
                            <option value="Doctorat">Doctorat</option>
                        </select>
                        {/* discipline */}
                        <Form.Label className='text-lg font-semibold'>Discipline <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="text"
                            name="discipline"
                            value={formData.discipline}
                            onChange={handleInputChange}
                        />
                        {/* Travaillez-vous*/}
                        <Form.Label className='text-lg font-semibold'>Travaillez-vous actuellement ? <span className='text-red-600'>*</span></Form.Label>
                        <div className='flex items-center space-x-4'>
                            {/* OUI */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='oui'
                                    name="TravaillezVous"
                                    value="oui"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Oui</Form.Label>
                            </div>
                            {/*NON*/}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='non'
                                    name="TravaillezVous"
                                    value="non"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Non</Form.Label>
                            </div>
                        </div>
                        {/* nombre d'experience*/}
                        <Form.Label className='text-lg font-semibold'>Nombre d'experience <span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'
                            type="number"
                            name="NombreExperience"
                            value={formData.NombreExperience}
                            onChange={handleInputChange}
                        />


                    </Form.Group>
                )}
                {step === 3 && (
                    <Form.Group style={{ backgroundColor: "#f3f3f3" }} className=' md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-8 justify-center xs:px-28 s:px-5' controlId="formStep3">
                        <p style={{ color: "#FE7F2D" }} className='text-2xl font-semibold'>Teste d'égilibilité au visa et permis detudes</p>
                        <p className='xs:w-[600px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez repondre a toutes les questions, a la fin du questionnaire vous aurrez le resultat qui déterminera votre éligibilité</p>
                        <p className='text-2xl font-semibold'>Autres informations</p>

                        {/* Antecedant choix */}
                        <Form.Label className='text-lg font-semibold'>Antécédants des dix dernières années <span className='text-red-600'>*</span></Form.Label>
                        <div className='grid  grid-cols-3 gap-4 '>
                            {/* Relevés d’emploi */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='ReleveEmploi'
                                    name="antecedant"
                                    value="ReleveEmploi"
                                    onChange={handleChangeAnte}
                                />
                                <Form.Label className='pl-3 text-lg'>Relevés d'emploi</Form.Label>
                            </div>
                            {/* Diplomes */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='diplomes'
                                    name="antecedant"
                                    value="Diplomes"
                                    onChange={handleChangeAnte}
                                />
                                <Form.Label className='pl-3 text-lg'>Diplomes</Form.Label>
                            </div>
                            {/* Bulletins de salaire */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='BulletinSalaire'
                                    name="antecedant"
                                    value="BulletinSalaire"
                                    onChange={handleChangeAnte}
                                />
                                <Form.Label className='pl-3 text-lg'>Bulletins de salaire</Form.Label>
                            </div>

                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            {/* Attestation de fréquentation */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='AttestationFrequentation'
                                    name="antecedant"
                                    value="AttestationFrequentation"
                                    onChange={handleChangeAnte}
                                />
                                <Form.Label className='pl-3 text-lg'>Attestation de fréquentation</Form.Label>
                            </div>
                            {/* Autres */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='Autres'
                                    name="antecedant"
                                    value="Autres"
                                    onChange={handleChangeAnte}
                                />
                                <Form.Label className='pl-3 text-lg'>Autres</Form.Label>
                            </div>
                        </div>
                        {/* download fichier antecedant */}
                        <Form.Label className='text-lg font-semibold'>Veuillez télécharger le  fichier en format PDF<span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14 pt-2'
                            type="file"
                            accept=".pdf"
                            onChange={(e)=> setFiles(e.target.files)}
                        />
                        {/*Passeport */}
                        <Form.Label className='text-lg font-semibold'>Passport valide ?<span className='text-red-600'>*</span></Form.Label>
                        <div className='flex items-center space-x-4'>
                            {/* OUI */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='oui'
                                    name="passport"
                                    value="oui"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Oui</Form.Label>
                            </div>
                            {/*NON*/}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='non'
                                    name="passport"
                                    value="non"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Non</Form.Label>
                            </div>
                        </div>

                        {/* Preuve de compétence linguistique */}
                        <Form.Label className='text-lg font-semibold'>Preuve de compétence linguistique <span className='text-red-600'>*</span></Form.Label>
                        <div className='grid  grid-cols-3 gap-4 '>
                            {/* TCF */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='tcf'
                                    name="test_langue"
                                    value="tcf"
                                    onChange={handleChangeComp}
                                />
                                <Form.Label className='pl-3 text-lg'>TCF</Form.Label>
                            </div>
                            {/* Bright */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='Bright'
                                    name="test_langue"
                                    value="Bright"
                                    onChange={handleChangeComp}
                                />
                                <Form.Label className='pl-3 text-lg'>Bright</Form.Label>
                            </div>
                            {/* TEF */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='TEF'
                                    name="test_langue"
                                    value="TEF"
                                    onChange={handleChangeComp}
                                />
                                <Form.Label className='pl-3 text-lg'>TEF</Form.Label>
                            </div>
                            {/* CELPIP */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='CELPIP'
                                    name="test_langue"
                                    value="CELPIP"
                                    onChange={handleChangeComp}
                                />
                                <Form.Label className='pl-3 text-lg'>CELPIP</Form.Label>
                            </div>
                            {/* IELTS */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='IELTS'
                                    name="test_langue"
                                    value="IELTS"
                                    onChange={handleChangeComp}
                                />
                                <Form.Label className='pl-3 text-lg'>IELTS</Form.Label>
                            </div>

                        </div>
                        {/* DOCUMENTS D’IDENTITÉ ET ETAT CIVIL choix */}
                        <Form.Label className='text-lg font-semibold'>Documents d'identité et etat civil<span className='text-red-600'>*</span></Form.Label>
                        <div className='grid  grid-cols-2 gap-4 '>
                            {/* Extrait de naissance */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='ExtraitNaissance'
                                    name="documentidentite"
                                    value="ExtraitNaissance"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Extrait de naissance</Form.Label>
                            </div>
                            {/* Certificat de mariage */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='CertificatMariage'
                                    name="documentidentite"
                                    value="CertificatMariage"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Certificat de mariage</Form.Label>
                            </div>
                            {/* Certificat de divorce */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='CertificatDivorce'
                                    name="documentidentite"
                                    value="CertificatDivorce"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Certificat de divorce</Form.Label>
                            </div>
                            {/*Carte nationale d’identité */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='CNI'
                                    name="documentidentite"
                                    value="CNI"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Carte nationale d’identité</Form.Label>
                            </div>
                            {/*Relevé de compte conjoint */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='ReleveCompteConjoint'
                                    name="documentidentite"
                                    value="ReleveCompteConjoint"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Relevé de compte conjoint</Form.Label>
                            </div>
                            {/*Facture de services publics */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="checkbox"
                                    id='FactureServicesPublics'
                                    name="documentidentite"
                                    value="FactureServicesPublics"
                                    onChange={handleChangeDoc}
                                />
                                <Form.Label className='pl-3 text-lg'>Facture de services publics</Form.Label>
                            </div>

                        </div>
                        {/* download fichier document */}
                        <Form.Label className='text-lg font-semibold'>Veuillez télécharger le document en format PDF<span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14 pt-2'
                            type="file"
                            accept=".pdf"
                            name="EtatCivile"
                            onChange={(e) => {setSelectedFile2(e.target.files)}}
                        />
                        {/*Casier judiciaire*/}
                        <Form.Label className='text-lg font-semibold'>Casier judiciaire ? <span className='text-red-600'>*</span></Form.Label>
                        <div className='flex items-center space-x-4'>
                            {/* OUI */}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='oui'
                                    name="casierJudiciaire"
                                    value="oui"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Oui</Form.Label>
                            </div>
                            {/*NON*/}
                            <div className='flex items-center'>
                                <Form.Control
                                    type="radio"
                                    id='non'
                                    name="casierJudiciaire"
                                    value="non"
                                    onChange={handleInputChange}
                                />
                                <Form.Label className='pl-3 text-lg'>Non</Form.Label>
                            </div>
                        </div>
                        {/*photo */}
                        <Form.Label className='text-lg font-semibold'>Veuillez télécharger votre photo<span className='text-red-600'>*</span></Form.Label>
                        <Form.Control
                            style={{ backgroundColor: "white" }} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14 pt-2'
                            type="file"
                            accept=".jpg"
                            name="photo"
                            onChange={(e) => { setSelectedPhoto(e.target.files)} }
                        />
                    </Form.Group>
                )}
                <div className="d-flex justify-content-between space-x-10 my-10">
                    {step > 1 && (
                        <Button style={{ backgroundColor: "#233D4D", color: "white" }} className='w-40 h-12 text-lg rounded transform active:scale-75 transition-transform ' variant="secondary" onClick={handlePrevious}>
                            Précédent
                        </Button>
                    )}
                    {step < 3 && (
                        <Button style={{ backgroundColor: "#FCCA46", color: "white" }} className='w-40 h-12 text-lg rounded transform active:scale-75 transition-transform ' variant="primary" onClick={handleNext}>
                        Suivant
                    </Button>
                    )}
                    {step === 3 && (
                        <Button style={{ backgroundColor: "#FCCA46", color: "white" }} className='w-40 h-12 text-lg rounded transform active:scale-75 transition-transform ' variant="primary" type="submit">
                        Envoyer
                    </Button>
                    )}
                    
                </div>
                </Form>
            <Footer />
            </>
             )}
        </div>
    );
};

export default MultiStepForm;
