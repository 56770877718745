import * as React from 'react';
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Footer from '../../COMPONNENTS/Footer/Footer'
import Header from '../../COMPONNENTS/Header/Header'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import home from '../../../ASSETS/CaImmigration/icones/home.png'
import visaN from '../../../ASSETS/CaImmigration/icones/visa.png'
import CitoyenCA from '../../../ASSETS/CaImmigration/icones/people.png'
import immobilierN from '../../../ASSETS/CaImmigration/icones/keys.png'
import Rpermanent from '../../../ASSETS/CaImmigration/icones/green-card.png'
import IonIcon from '@reacticons/ionicons'
import { Link } from 'react-router-dom'
import Achat from '../../../ASSETS/CaImmigration/images/Bien_immobilier_barniere.png'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OptionCard from '../../COMPONNENTS/OptionCard/OptionCard';
import down from '../../../ASSETS/CaImmigration/icones/down-arrow.png'

function AcheterUnBien() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 1000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

    // Dialog
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  return (
       <div style={{ backgroundColor: "#f7f7f7" }}>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading />
          ) : (
              <>
          <Header />

          {/* back image */}
          <div className='back_image bg-cover z-0 flex-col bg-center bg-no-repeat h-screen w-screen relative ' style={{ backgroundImage: `url(${Achat})` }}>

                 {/* navbar */}
               <div className='navbar_animation s:relative s:bottom-2 sm:bottom-5 md:bottom-0  bg-transparent flex md:flex-row sm:flex-col s:flex-col w-screen h-24   md:items-center justify-between md:text-lg '>
                        <div className='flex sm:flex-row s:flex-row sm:py-5 s:py-2 items-center space-x-[600px]    justify-between'>

                            <Link to='/'>
                            <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                                <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                            </div>
                            </Link>
                            <div onClick={() => setOpen(!open)} className=' lg:hidden text-end relative   s:text-3xl pr-4 s:text-white cursor-pointer'>
                            <IonIcon name={open ? 'close' : 'menu'}></IonIcon>
                            </div>
                        </div>
                        {/* element_menu */}
                        <div className={`el mb-8 flex md:relative s:absolute s:z-10 s:py-5  md:flex-row md:space-x-5  s:flex-col sm:px-10 s:px-5 md:bg-transparent sm:space-x-0 sm:pl-5 sm:space-3 transition-all duration-500 ease-in ${open ? 's:left-0 s:top-32 md:top-auto md:left-auto' : 's:left-[-490px]  md:left-auto'} `}>

                            <div className={`flex w-full md:flex-row md:items-end  md:space-x-6 lg:space-x-16  s:flex-col s:space-x-0 s:text-base md:text-base  s:space-y-5 `}>
                            
                                {/* Accueil */}
                                <Link to='/homeCaimmigration'>
                                <div style={{ fontSize: "16px" }} className='flex items-center space-x-3 lg:pb-1  font-semibold text-white '>
                                    
                                    <img className='w-6' src={home} alt='Log' />
                                    <div> Accueil</div>
                                </div>
                                </Link>

                            {/* Visa */}
                                <div style={{ fontSize: "16px",  }} className='relative md:top-[18px]  flex space-x-2 lg:pb-1 items-center md:text-white s:text-white font-semibold cursor-pointer text-white '>
                                <div className='relative bottom-1'>
                                    <img className='w-4' src={visaN} alt='' />
                                </div>
                                <div className='navbar1 '>
                                    <ul>
                                    <li> <Link to='/visa'> <p className='flex space-x-2 items-center'><span> Visa & Permis</span> <img src={down} className='w-4 h-4' alt='fleche'/></p>
                                    <ul className='z-10  '>
                                        <li className='hover:bg-yellow-400  hover:text-white'> <Link to='/visa/visaEtude'> Permis d'etudes </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTravail'> Permis de travail </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTouriste'> Visa touriste </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaTransit'> Visa transit </Link></li>
                                        <li className='hover:bg-yellow-400 hover:text-white'> <Link to='/visa/visaFamiliale'> Visa familiale </Link></li>
                                    </ul>
                                    </Link>
                                    </li>
                                    </ul>
                                </div>
                                </div>

                            {/* Résidence permanente */}
                            <Link to='/ResidencePermante'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-2 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={Rpermanent} alt='' />
                                </div>
                                <div>Résidence permanente</div>
                            </div>
                            </Link>

                            {/*Citoyenneté Canadienne */}
                            <Link to='/CitoyenneteCa'>
                            <div style={{ fontSize: "16px" }} className='flex space-x-3 items-center s:text-white font-semibold hover:text-yellow-400 cursor-pointer'>
                                <div>
                                <img className='w-6' src={CitoyenCA} alt='' />
                                </div>
                                <div> Citoyenneté Canadienne</div>
                            </div>
                            </Link>

                            {/* Achat de biens immobilers */}
                            <Link to='/BienImmobilier'>
                                <div style={{ fontSize: "16px" ,color:"#FCCA46"}} className='flex space-x-2 items-center  s:text-white font-semibold hover:text-yellow-400  cursor-pointer'>
                                <div>
                                    <img className='w-6' src={immobilierN} alt='' />
                                </div>
                                <div>Achat de biens immobilers</div>
                                </div>
                            </Link>
                            </div>
                        </div>
                </div>

              <div className='text_home flex justify-end pr-10 md:pl-20 s:pl-5  s:pt-20 md:pt-0 mt-20'>
                  <div className='flex pt-20 text-center  text-white md:text-5xl xs:text-3xl sm:text-2xl s:text-lg  font-semibold xs:w-[470px] md:w-[770px] '>
                      <p>ACHAT DE BIEN IMMOBIIER</p>
                  </div>
                
              </div>

          </div>

          {/* option */}
            <OptionCard/>

          {/* en tete */}
          <div className='flex flex-col justify-center items-center space-y-6 mt-10 pb-12'>
              <div style={{ color: "##4e4e4e" }} className='xs:text-2xl s:text-lg text-center font-bold'>Nous vous orientons dans le monde de l'immigration</div>
              <div style={{ color: "##4e4e4e" }} className='xs:w-[560px] sm:w-[340px]  s:[200px] text-center text-lg'>Nous possédons une expertise approfondie concernant les systèmes complexes, les réglementations et les procédures d'immigration de plusieurs pays.</div>
          </div>

          {/* formulaire */}
          <div className='py-20 bg-white flex flex-col space-y-10 items-center justify-center '>
            <p style={{color:"#FE7F2D"}} className='text-2xl font-semibold'>Acheter un bien immobilier</p>
            <p className='xs:w-[600px] s:w-[240px] sm:w-[300px] text-center text-lg'>Veuillez remplir le formulaire pour procéder a l'achat du bien </p>

            <form style={{backgroundColor:"#f3f3f3"}} className=' md:w-[800px] xs:w-[700px] s:w-[270px] sm:w-[350px] h-full py-10 shadow-2xl flex flex-col space-y-6 justify-center xs:px-28 s:px-5'>
               
               {/* raison d'achat */}
               <label className='text-lg font-semibold'>Je veux acheter le bien pour  <span className='text-red-600'>*</span> :</label>
                <div className='flex flex-row items-center space-x-6 '>
                    <div >
                        <input type='radio' id='vivre' value="vivre" name='raisonAchat' />
                        <label className='pl-3 text-lg'> Y vivre moi même</label>
                    </div>
                    <div>
                        <input type='radio' id='location' value="location" name='raisonAchat' />
                        <label className='pl-3 text-lg'>Mettre en location</label>
                    </div>
                </div>

                {/* nom */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Nom <span className='text-red-600'>*</span></label>
                    <input name='name' type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* prenom */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Prénom <span className='text-red-600'>*</span></label>
                    <input name='firstName' type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* pays de residence */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Pays de résidence <span className='text-red-600'>*</span></label>
                    <input name='Paysresidence' type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                 {/*Pays de Nationalité */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Pays de nationalité <span className='text-red-600'>*</span></label>
                    <input name='PaysNationalite' type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>


                {/* Profession */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Profession <span className='text-red-600'>*</span></label>
                    <input name='profession' type='text' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14' required/>
                </div>

                {/* email */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Adresse e-mail <span className='text-red-600'>*</span></label>
                    <input name='mail' type='email' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'/>
                </div>

                {/* numero de portable */}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Numero de portable <span className='text-red-600'>*</span></label>
                    <input name='numero' type='tel' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'/>
                </div>

                 {/* salaire*/}
                <div className='flex flex-col space-y-3 '>
                    <label className='text-lg font-semibold'>Salaire <span className='text-red-600'>*</span></label>
                    <input name='salire' type='number' style={{backgroundColor:"white"}} className='border-none xs:w-[600px] s:w-[240px] sm:w-[300px] h-14'/>
                </div>

                {/* boutton navigation */}
                 <div>
                    <Button style={{backgroundColor:"#FCCA46", color:"white"}} className='w-40 h-12 text-lg rounded transform active:scale-75 transition-transform '  onClick={handleClickOpen}>
                        Envoyer
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {"Demande d'achat d'un bien immobilier ?"}
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Vous allez soumettre une demande d'achat d'un bien immobilier a Mon Bon Sejour. Connfirmer votre demande
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Annuler</Button>
                        <Button onClick={handleClose} autoFocus>
                           Confirmer
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </form>
          </div>
        
        
          <Footer />
              </>
          )}
      </div>
  )
}

export default AcheterUnBien