import React from 'react'
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Loading from '../../COMPONENTS/Loading/Loading'
import { FaRegBuilding } from "react-icons/fa";
import profil_photo_entreprise from '../../../ASSETS/tourisme/Image/Dashbord_image/pp.jpg';
import SidebarTop from '../SidebarTop';
import { CiMapPin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoTiktok } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";
import { FaPhoneAlt } from "react-icons/fa";




function Profil() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>

          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop />
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs >
                <Link to="" className="opacity-60">
                Mon compte
                </Link>
                <Link to="" className="">
                 Profil
                </Link>
              </Breadcrumbs>
            </div>
            {/* container formulaire */}
            <div className='flex md:flex-row s:flex-col md:space-x-10 s:space-y-6 md:space-y-0 justify-center p-10 '>
              {/* box 1 */}
              <div className='flex flex-col space-y-2 bg-white md:[340px] xs:w-[540px] s:w-[260px] sm:w-[310px]  p-5 rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <img src={profil_photo_entreprise} className="rounded-full w-24 h-24 border-2 border-[#FCCA46]" alt='photo_profil'/>
                <p className='text-lg font-semibold text-[#4e4e4e]'>Nom de l'entreprise</p>
                <p className='text-sm font-thin text-[]#4e4e4e'>Informations@gmail.com</p>
                <div className='md:w-[300px] xs:w-[500px] s:w-[220px] sm:w-[270px]'>
                  Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Habitant 
                  morbi tristique senectus et netus et malesuada fames ac. Felis eget 
                  nunc lobortis mattis aliquam. Habitant morbi tristique senectus et netus 
                  et malesuada fames ac. Quis viverra nibh cras pulvinar mattis nunc. Magna e
                </div>
              </div>


              <div className='bg-white p-5 xs:w-[700px] s:w-[260px] sm:w-[320px] rounded-md shadow border-t-8 border-[#fe7f2d]'>
                <div className='flex flex-row space-x-8  text-lg font-light'>
                  <p className=' border-b-2 border-[#FCCA46] cursor-pointer hover:border-none'>Profile</p>
                  <Link to="/Dashboard/Profil/Modifier">
                    <p className='hover:border-b-2 border-[#FCCA46] cursor-pointer '>Modifier</p>
                  </Link>
                </div>
                <hr className='my-8'/>
                <div className=' flex flex-col space-y-5'>
                  {/* Nom de l'entreprise */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <FaRegBuilding className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Nom de la structure :</p>
                    </div>
                    <p className=' text-lg font-thin'>Mon bon sejour</p>
                  </div>
                  {/* Adresse de la structure */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <CiMapPin className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Adresse de la structure :</p>
                    </div>
                    <p className='text-lg font-thin'>Cocody, Abidjan Côte d'ivoire</p>
                  </div>
                  {/* Facebook */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <FaFacebookF className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full ' />
                      <p className=' font-medium '>Facebook :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div>
                  {/* Instagram */}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <SlSocialInstagram className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Instagram :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div>
                  {/* Tiktok*/}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <IoLogoTiktok className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Titok :</p>
                    </div>
                    <p className='text-lg font-thin'>Monbonsejour</p>
                  </div>
                   {/* Site web*/}
                  <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                    <div className='flex flex-row items-center space-x-2'>
                      <TbWorldWww className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                      <p className=' font-medium '>Site web :</p>
                    </div>
                    <p className='text-lg font-thin'>www.monbonsejour.com</p>
                  </div>
                    {/* Telephone*/}
                    <div className='flex xs:flex-row s:flex-col xs:space-x-2 s:space-y-3 xs:space-y-0 xs:items-center'>
                      <div className='flex flex-row items-center space-x-2'>
                        <FaPhoneAlt className='text-white bg-[#233D4D] w-8 h-8 p-2 rounded-full '  />
                        <p className=' font-medium '>Telephone :</p>
                      </div>
                      <p className='text-lg font-thin'>+225 05 00 00 00 00</p>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Profil