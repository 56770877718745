import React from 'react'
import {Link} from 'react-router-dom'
function BtnLogin() {
  return (
    <div>
      {/* bouton */}
      <Link to='/Connexion'>
        <div className='s:p-5'>
          <button className='btn_signin flex md:flex-row md:space-x-4 items-center md:justify-end  md:h-14 md:px-4 s:p-2 hover:bg-orange-500 '>
          <div>
          <svg id="user" xmlns="http://www.w3.org/2000/svg" width="23.595" height="23.595" viewBox="0 0 33.595 33.595">
          <path id="Tracé_5" data-name="Tracé 5" d="M9.1,7.7a7.7,7.7,0,1,1,7.7,7.7A7.71,7.71,0,0,1,9.1,7.7Zm7.7,8.942A16.849,16.849,0,0,1,33.6,31.778,76.3,76.3,0,0,1,16.8,33.6,76.3,76.3,0,0,1,0,31.778,16.847,16.847,0,0,1,16.8,16.644Z" fill="#fff" fillRule="evenodd"/>
          </svg>
          </div>

          <div className='text-white text-base w-28 '>
          Se connecter
          </div>
          </button>
        </div> 
      </Link>
    </div>
  )
}

export default BtnLogin
