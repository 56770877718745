import React from 'react'
import { useState, useEffect } from 'react';
import Loading from '../../../TOURISME/COMPONENTS/Loading/Loading'
import Header from '../../COMPONNENT/Header/Header';
import backimage from '../../../ASSETS/AfImmigration/imgConseil.png'
import {Link} from 'react-router-dom'
import logo1 from '../../../ASSETS/tourisme/Image/LOGO.png'
import BoutonLogin from '../../../TOURISME/COMPONENTS/BoutonLogin/BtnLogin'
import Footer from '../../COMPONNENT/Footer/Footer';
import { IoIosArrowDropright } from "react-icons/io";
import BarniereService from '../../COMPONNENT/BarniereService/BarniereService';



function Conseil() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
            setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes

        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
    }, []);

  return (
    <div className='bg-[#F7F7F7]'>
          {loading ? (
              // Afficher le spinner tant que loading est true
              <Loading/>
          ) : (
        <>
        <Header/>
        <div style={{ backgroundImage:`url(${backimage})` }} className='h-screen w-screen bg-cover bg-center bg-no-repeat'>
            <div className='flex s:flex-col sm:flex-row sm:justify-between'>
                <Link to='/accueil_afrique_immigration'>
                    <div style={{ borderBottomRightRadius: "100px" }} className=' sm:pl-5 s:pl-2 bg-white w-40 pb-4  pr-4   '>
                        <img src={logo1} className='h-20  s:pl-0 pt-6 ' alt='logo' />
                    </div>
                </Link>
                <BoutonLogin/>
            </div>
          <div className='relative top-[200px] xs:left-20 s:left-6'>
            <div className=' xs:text-6xl uppercase  s:text-xl sm:text-4xl font-semibold text-white xs:w-[600px]'>
               Conseil
            </div>
            <div className='h-2 w-20 bg-[#FCCA46] mt-4'></div>
          </div>

        </div>

        <div className='py-14 bg-white xs:px-10 s:px-3'>

            <div className='relative ' >
                <div className=' text-3xl text-[#4E4E4E] font-semibold'>Options de services</div>
                <div className='h-[4px] w-9 bg-[#fe7f2d] mt-2'></div>
            </div>

           <div className='pt-10 grid s:grid-cols-1 gap-y-10 xs:grid-cols-2'>

                <div className='flex flex-row space-x-5'>
                    <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>1</div>
                    <div className='flex flex-col space-y-4'>
                        <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>Recommandation de pays le mieux adapté</div>
                        <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                            We understand the importance of approaching each work integrally and believe in the 
                            power of simple.Ultrices eros in cursus turpis. Tortor dignissim convallis aenean et. 
                            Ut diam quam nulla porttitor massa id. Bibendum arcu vitae elementum curabitur. 
                        </div>

                        <Link to='/PrendreRendezVous'>
                            <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                <button>Renseigner le formulaire</button>
                                <IoIosArrowDropright />
                            </div>
                        </Link>
                    </div>
                    
                </div>

                <div className='flex flex-row space-x-5'>
                    <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>2</div>
                    <div className='flex flex-col space-y-4'>
                        <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>Recommandation de business pour votre entreprise</div>
                        <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                            We understand the importance of approaching each work integrally and believe in the 
                            power of simple.Ultrices eros in cursus turpis. Tortor dignissim convallis aenean et. 
                            Ut diam quam nulla porttitor massa id. Bibendum arcu vitae elementum curabitur. 
                        </div>

                        <Link to='/PrendreRendezVous'>
                            <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                <button>Renseigner le formulaire</button>
                                <IoIosArrowDropright />
                            </div>
                        </Link>
                    </div>
                    
                </div>

                <div className='flex flex-row space-x-5'>
                    <div className='w-8 h-8 bg-[#233D4D] rounded-full text-center text-xl text-white'>3</div>
                    <div className='flex flex-col space-y-4'>
                        <div className='text-xl font-medium text-[#fe7f2d] md:w-[480px] s:w-[220px] sm:w-[300px]'>Recommandation de partenaire d'affaire</div>
                        <div className='md:w-[480px] s:w-[220px] sm:w-[300px]'>
                            We understand the importance of approaching each work integrally and believe in the 
                            power of simple.Ultrices eros in cursus turpis. Tortor dignissim convallis aenean et. 
                            Ut diam quam nulla porttitor massa id. Bibendum arcu vitae elementum curabitur. 
                        </div>

                        <Link to='/PrendreRendezVous'>
                            <div className='flex flex-row items-center space-x-2 sm:text-lg text-[#233D4D] font-semibold'>
                                <button>Renseigner le formulaire</button>
                                <IoIosArrowDropright />
                            </div>
                        </Link>
                    </div>
                    
                </div>

                
           </div>
        </div>

        <BarniereService/>
        <Footer/>
        </>
          )}
    </div>
  )
}

export default Conseil