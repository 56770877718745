import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
 

function InputHote() {

  const [open, setOpen] = React.useState(false);
 
  const handleOpen = () => setOpen(!open);

  return (
    <>
    <Button onClick={handleOpen} variant="text" className=" s:w-[200px]  md:w-52 h-14 bg-white px-5 rounded-md outline-none ">Hotes</Button>
    <Dialog open={open} className="w-[400px]" handler={handleOpen}>
      <div className="flex items-center justify-between">
        <DialogHeader className="flex flex-col items-start">
          
          <Typography className="mb-1" variant="h5">
           Sélectionnez les hotes
          </Typography>
        </DialogHeader>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="mr-3 h-5 w-5"
          onClick={handleOpen}
        >
          <path
            fillRule="evenodd"
            d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <DialogBody>
        <div className="grid gap-6">
          <p className="font-semibold">Nombre Adultes </p>
          <input type="number" className="rounded"/>
        </div>
        <div className="grid gap-6 mt-3">
          <p className="font-semibold">Nombre Enfants </p>
          <input type="number" className="rounded"/>
        </div>
      </DialogBody>
      <DialogFooter className="space-x-2">
        <Button variant="text" className="bg-[#233D4D] text-white" onClick={handleOpen}>
          Annuler
        </Button>
        <Button variant="gradient"  className="bg-[#fe7f2d]" onClick={handleOpen}>
          Valider
        </Button>
      </DialogFooter>
    </Dialog>
  </>
  )
}

export default InputHote