import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import user from '../../../ASSETS/CaImmigration/icones/user.png'
import logout from '../../../ASSETS/CaImmigration/icones/power-off.png'
import user1 from '../../../ASSETS/CaImmigration/icones/user1.png'
import info from '../../../ASSETS/CaImmigration/icones/info.png'
import { ToastContainer, toast } from "react-toastify"
import { getClockNumberUtilityClass } from '@mui/x-date-pickers/TimeClock/clockNumberClasses';


type Anchor = 'left';

export default function DropdownLogin() {
    const [state, setState] = React.useState({
        left: false,
    });

    const navigate = useNavigate();

    function logOutUser() {
        localStorage.removeItem("data");
        toast.success("Deconnexion avec succès !");
        navigate("/homeCaimmigration");
    };

    const [data, setData] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("data");
        const initialValue = saved !== null ? JSON.parse(saved) : null;
        return initialValue;
      });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box 
            sx={{ width: 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
               <a href={`https://az-companies.com/fr/personnal/profile?token=${data.token}`} target="_blank" rel="noreferrer">
                <div className='hover:bg-slate-100 p-4 cursor-pointer flex items-center space-x-2'>
                     <img className='w-6' src={user} alt='user'/>
                    <p>{data.userName}</p>
                </div>
                </a>
                <Link to="/CV">
                <div className='hover:bg-slate-100 p-4 cursor-pointer flex items-center space-x-2'>
                    <img className='w-6' src={info} alt='cout' />
                    <p>Cv et lettre de motivation Canadien</p>
                </div>
                </Link>
                <Link to="/PrendreRendezVous">
                <div className='hover:bg-slate-100 p-4 cursor-pointer flex items-center space-x-2'>
                    <img className='w-6' src={info} alt='cout' />
                    <p>Assistance a votre arrivée au Canada</p>
                </div>
                </Link>
                
               
            </List>
            <Divider />
            <List>
                <div onClick={logOutUser} className='hover:bg-slate-100 p-4 cursor-pointer flex items-center space-x-2'>
                    <img className='w-6' src={logout} alt='logout' />
                    <p>Deconnexion</p>
                </div>
            </List>
           
        </Box>
    );

    return (
        <div>
            <Button  style={{color:"white", textTransform:"none"}} onClick={toggleDrawer('left', true)}>
                <img className='w-4' src={user1} alt='compte'/>
                <p className='pl-2'>Mon compte</p>
            </Button>
            <Drawer
                anchor="left"
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </div>
    );
}
