import React from 'react'
import { Typography } from "@material-tailwind/react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from 'react-router-dom'
import { IoIosEyeOff } from "react-icons/io";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,

} from "@material-tailwind/react";
import { useState, useEffect } from 'react';
import Loading from '../../../COMPONENTS/Loading/Loading'
import { IoAddSharp } from "react-icons/io5";
import {
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { FiEdit } from "react-icons/fi";
import { RxDotsVertical } from "react-icons/rx";
import {
  Card,
  CardHeader,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { IoIosEye } from "react-icons/io";
import SidebarTop from '../../SidebarTop';






const TABLE_HEAD = ["Nom du véhicule", "Type du vehicule", "Nombre de place","État", "Statut", "Action",];

const TABLE_ROWS = [
  {
    img: "https://images.pexels.com/photos/170811/pexels-photo-170811.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Nom du véhicule",
    tarif: "70.000",
    TypeVehicule: "essence",
    NombrePlace: "5",
    etat:"Activé",
    statut: "Disponible",
  },
  {
    img: "https://images.pexels.com/photos/116675/pexels-photo-116675.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Residence studio",
    tarif: "30.000",
    TypeVehicule: "electrique",
    NombrePlace: "8",
    etat: "Desactivé",
    statut: "Réservé",
  },
  {
    img: "https://images.pexels.com/photos/977003/pexels-photo-977003.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Nom du véhicule",
    tarif: "55.000",
    TypeVehicule: "essence",
    NombrePlace: "4",
    etat: "Activé",
    statut: "Réservé",
  },
  {
    img: "https://images.pexels.com/photos/1007410/pexels-photo-1007410.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Nom du véhicule",
    tarif: "20.000",
    TypeVehicule: "electrique",
    NombrePlace: "2",
    etat: "Desactivé",
    statut: "Disponible",
  },
  {
    img: "https://images.pexels.com/photos/1638459/pexels-photo-1638459.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    name: "Nom du véhicule",
    tarif: "50.000",
    TypeVehicule: "essence",
    NombrePlace: "10",
    etat: "Activé",
    statut: "Disponible",
  },
];



function VoirLVehicule() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
      setLoading(false);
    }, 2000); // 5000 millisecondes = 5 secondes

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);


  return (
    <div className='flex flex-row bg-[#eeee]'>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
          <div className='w-screen  barre_scroll h-screen'>
            {/* sidebar top  */}
            <SidebarTop/>
            {/* navbar top gris*/}
            <div className='bg-[#f7f7f7] sm:h-10 s:h-14 mt-[50px]'>
              <Breadcrumbs>
                <Link to="" className="opacity-60 md:text-base s:text-xs">
                  Mes publications
                </Link>
                <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules" className="opacity-60 md:text-base s:text-xs">
                  Location de vehicule
                </Link>
                <Link to="" className='md:text-base s:text-xs'>
                  Voir
                </Link>
              </Breadcrumbs>
            </div>

            {/* boutton ajouter une residence */}
            <div className='p-6  w-[230px]' >
                <Link to="/Dashboard/Publications/Location_Vehicule/Ajouter_les_vehicules">
                <button className='flex flex-row items-center justify-center h-10  space-x-2 bg-[#FCCA46] text-white md:text-base s:text-sm md:w-[230px] s:w-[190px] transform active:scale-75 transition-transform'>
                  <IoAddSharp />
                  <p>Ajouter un vehicule</p>
                </button>
              </Link>
            </div>
            {/* container formulaire */}
            <div className='flex justify-center md:p-10 s:p-4'>

              <Card className="h-full w-full border-t-8 border-[#fe7f2d]">
                <CardHeader floated={false} shadow={false} className="rounded-none">
                  <Typography className='md:text-xl s:text-lg font-semibold' color="blue-gray">
                    Liste des TypeVehicules publiées
                  </Typography>
                </CardHeader>
                <CardBody className="overflow-scroll px-0">
                  <table className="mt-4 w-full min-w-max overflow-scroll  table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head, index) => (
                          <th
                            key={head}
                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                            >
                              {head}{" "}
                              {index !== TABLE_HEAD.length - 1 && (
                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                              )}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {TABLE_ROWS.map(
                        ({ img, name, tarif, TypeVehicule, NombrePlace, etat, statut }, index) => {
                          const isLast = index === TABLE_ROWS.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";

                          return (
                            <tr key={name} className='hover:bg-slate-100'>

                              <td className={classes}>
                                <div className="flex items-center space-x-2 ">
                                  <Avatar src={img} alt={name} className='w-[180px] h-[100px]' />
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {name}
                                    </Typography>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal opacity-70"
                                    >
                                      {tarif} XOF / Journée
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {TypeVehicule}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {NombrePlace} Sièges passagers
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {etat} 
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {statut}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Tooltip content="Action sur la publication">
                                  <IconButton variant="text">
                                    <Menu>
                                      <MenuHandler>
                                        <IconButton>
                                          <RxDotsVertical />
                                        </IconButton>
                                      </MenuHandler>
                                      <MenuList className="flex flex-col gap-2">

                                        {/* modifier */}
                                        <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules/Modifier">
                                          <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <FiEdit className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                              <Typography variant="small" color="gray" className="font-semibold">
                                                Modifier
                                              </Typography>
                                            </div>
                                          </MenuItem>
                                        </Link>
                                        {/* Voir  */}
                                        <Link to="/Dashboard/Publications/Location_Vehicule/Voir_les_vehicules/Voir_Plus">
                                          <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                            <IoIosEye className='text-lg' />
                                            <div className="flex flex-col gap-1">
                                              <Typography variant="small" color="gray" className="font-semibold">
                                                Voir
                                              </Typography>
                                            </div>
                                          </MenuItem>
                                        </Link>
                                        {/* Desactiver */}
                                        <MenuItem className="flex items-center gap-4 py-2 pl-2 pr-8 hover:bg-gray-100">
                                          <IoIosEyeOff className='text-lg' />
                                          <div className="flex flex-col gap-1">
                                            <Typography variant="small" color="gray" className="font-semibold">
                                              Désactiver
                                            </Typography>
                                          </div>
                                        </MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </CardBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    Page 1 of 10
                  </Typography>
                  <div className="flex gap-2">
                    <Button variant="outlined" size="sm">
                      Previous
                    </Button>
                    <Button variant="outlined" size="sm">
                      Next
                    </Button>
                  </div>
                </CardFooter>
              </Card>

            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default VoirLVehicule